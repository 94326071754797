import { createAction } from "@reduxjs/toolkit";
import {
  SWTypes,
  TaskAlignments,
  INoticeComponent,
  StepComponentTypes,
  ITextInputComponent,
  INumberInputComponent,
  IDateInputComponent,
  IDateTimeInputComponent,
  IPhotoInputComponent,
  ISignatureInputComponent,
  IYesNoInputComponent,
  IImageComponent,
  ISelectInputComponent,
  IMultiSelectInputComponent,
  ISW,
  IImageBlobData,
  IUnlockSWCurrentEditor,
  IMicrosoftStreamVideoComponent,
  ILinkComponent,
  IStep,
  ITableComponent,
  ITableComponentCell,
  IRefDocTableComponent,
  TLMSWIType,
  IPPEComponent,
  IVideoComponent,
  IRichTextInputComponent,
  IQualityControlPointComponent,
  IVideoInputComponent,
  ISWRevisionHistory,
  IAudioInputComponent,
  ITIMEImageComponent,
  ImageType,
  RCTypes,
  StepTypes,
  DataClassificationTypes,
  TemplateTypes,
  ContentTypes,
  ISiteLocation,
  IFormulaInputComponent,
  IPassFailInputComponent,
  IConnectedSWI,
  IAddConnectedSWIPayload,
  IDeleteConnectedSWIPayload,
  IConnectedSWIsUploadError,
  StepComponentType, 
  IPasteComponents
} from "interfaces/sw/SWInterfaces";
import {
  ManageSWTabs,
  IStepBooleanPayload,
  IStepStringPayload,
  ISWAttachment,
  IStepComponentImageRequest,
  IStepSortOrderPayload,
  IStepVisibility,
  ISWCommentData,
  ISWStepCommentData,
  INoticeImageRequest,
  INoticeAttachment,
  IVideoAttachment,
  IVideoRequest,
  IExecutionPreviewLocation,
  IAudioRequest,
  IAudioAttachment,
  ITableComponentImage,
  ITableCellImagesRequest,
  IRefDocTableCellImagesRequest,
  IStepComponentTIMEImageRequest,
  ITimeImageData,
  ITimeImageAttachment,
  IGetTimeMediaFilesMappingData,
  INoticeTimeImageAttachment,
  ITableTimeImageAttachment,
  INoticeVisibility,
  IServiceLevelPayload,
  IUsagePayload,
  IOOSPayload,
  ISiteLocationPayload,
  ISWNotices,
  ISWStepNotices, 
  IPasteSWComponentToSWDraft
} from "./manageSWTypes";
import { IOperation } from "interfaces/operations/Operations";
import { IGemsRevisionMasterDataItem, IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { ApprovalLevels, HistoricalApprovalTypes } from "interfaces/approvals/approvalInterfaces";
import { IOperationTreeNode, MetaDataNames } from "store/masterData/masterDataTypes";
import { Item } from "react-nestable";

import {
  ConfidentialAccessRoles,
  IConfidentialSWUser,
} from "interfaces/user/UserInterfaces";

export interface IFetchRCStepsPayload {
  swGuid: string;
  swType: SWTypes;
  rcGuid: string;
  rcVersion: number;
  isRCTask: boolean;
  stepGuid?: string;
  source: string;
  ignoreIncompatibility: boolean;
}

export const uncheckAllOtherApplyToAll = createAction<{ stepGuid: string }>("manageSW/uncheckAllOtherApplyToAll");
export const reset = createAction("manageSW/reset");
export const save = createAction<{ isVersionChange: boolean }>("manageSW/save");
export const backgroundUpdate = createAction("manageSW/backgroundUpdate");
export const finishBackgroundUpdate = createAction("manageSW/finishBackgroundUpdate");
export const load = createAction<{ guid: string, version: number }>("manageSW/load");
export const setLoadOperation = createAction<IOperation | undefined>("manageSW/setLoadOperation");
export const setCreateOperation = createAction<IOperation | undefined>("manageSW/setCreateOperation");
export const setFileUploadOperation = createAction<IOperation | undefined>("manageSW/setFileUploadOperation");
export const setUpdateOperation = createAction<IOperation | undefined>("manageSW/setUpdateOperation");
export const setBackgroundUpdateOperation = createAction<IOperation | undefined>("manageSW/setBackgroundUpdateOperation");
export const finishCreating = createAction<{ swGuid: string }>("manageSW/finishCreating");
export const finishUpdating = createAction<ISW>("manageSW/finishUpdating");
export const finishRCFromTLMSWICreating = createAction<{ swGuid: string }>("manageSW/finishRCFromTLMSWICreating");
export const finishRCFromTLMSWIUpdating = createAction<ISW>("manageSW/finishRCFromTLMSWIUpdating");
export const finishRCFromBlobCopying = createAction("manageSW/finishRCFromBlobCopying");
export const finishLoading = createAction<ISW>("manageSW/finishLoading");
export const finishUnlockSWCurrentEditor = createAction<IUnlockSWCurrentEditor>("manageSW/finishUnlockSWCurrentEditor");
export const setSubmitForReviewFlag = createAction<{ submitForReviewFlag: boolean }>("manageSW/setSubmitForReviewFlag");
export const setConfigParaMeterFlag = createAction<{
    keyName: "enableMeters" | "enableFormulaComponent" | "enablePhotoInputComponentMFG" | "enableTimeImageComponentMFG" | "enableNoticeComponentMFG" | "enableTableComponentMFG" | "enableSingleListComponentMFG" | "enableMultiListComponentMFG" | "disableMFGInputComponent" | "disableMFGContentComponent" | "enableConfiguredMFGPlantsInTLM"
    value : string
}>("manageSW/setConfigParaMeterFlag");


export const setStringAttribute = createAction<{
  attributeName: "title" | "description" | "introduction" | "maintenanceTaskIds" | "ceMarking" | "foreward" | "versionChanges" | "harc" | "showclsignatureblock",
  value:  string
}>("manageSW/setStringAttribute");

export const setLevelStringAttribute = createAction<{
  attributeName: "level",
  value:  string | undefined
}>("manageSW/setLevelStringAttribute");

export const setResuableFromTLMSWStringAttribute = createAction<{
  attributeName: "title" | "description" | "versionChanges",
  value: string
}>("manageSW/setResuableFromTLMSWStringAttribute");

export const setRCType = createAction<RCTypes>("manageSW/setRCType");
export const setType = createAction<SWTypes>("manageSW/setType");
export const setTLMSWIType = createAction<TLMSWIType>("manageSW/setTLMSWIType");
export const setContentType = createAction<ContentTypes>("manageSW/setContentType");
export const setDataClassification = createAction<DataClassificationTypes>("manageSW/setDataClassification");
export const setTCC = createAction<boolean>("manageSW/setTCC");
export const setTemplateType = createAction<TemplateTypes>("manageSW/setTemplateType")
export const setTaskAlignment = createAction<TaskAlignments>("manageSW/setTaskAlignment");
export const setLanguage = createAction<{ masterDataItem?: IMasterDataItem | undefined, countrySelected: boolean, geoUnitSelected: boolean }>("manageSW/setLanguage");
export const setReusableMetaDataLanguage = createAction<{ masterDataItem?: IMasterDataItem | undefined, countrySelected: boolean, geoUnitSelected: boolean }>("manageSW/setReusableMetaDataLanguage");
export const setEquivalentSW = createAction<IMasterDataItem | undefined>("manageSW/setEquivalentSW");
export const setOwningOrg = createAction<{ owningOrg: IMasterDataItem | undefined, swType: SWTypes }>("manageSW/setOwningOrg");
export const setOwningPlant = createAction<{ owningPlant: IMasterDataItem | undefined, swType: SWTypes }>("manageSW/setOwningPlant");
export const setReusableCompFromMFGSWIOwningPlant = createAction<{ owningPlant: IMasterDataItem | undefined, swType: SWTypes }>("manageSW/setReusableCompFromMFGSWIOwningPlant");
export const setReusableCompFromTLMSWIOwningOrg = createAction<IMasterDataItem | undefined>("manageSW/setReusableCompFromTLMSWIOwningOrg");
export const setReusableCompFromMFGSWIGemsRevision = createAction<IGemsRevisionMasterDataItem | undefined>("manageSW/setReusableCompFromMFGSWIGemsRevision");
export const setReusableCompFromTLMSWIRcType = createAction<RCTypes>("manageSW/setReusableCompFromTLMSWIRcType");
export const setActiveApprovalRequestId = createAction<number | undefined>("manageSW/setActiveApprovalRequestId");
export const setActiveApprovalLevel = createAction<ApprovalLevels | undefined>("manageSW/setActiveApprovalLevel");
export const setActiveApprovalUserName = createAction<string | undefined>("manageSW/setActiveApprovalUserName");
export const setActiveApprovalType = createAction<HistoricalApprovalTypes | undefined>("manageSW/setActiveApprovalType");
export const setHazardCategory = createAction<IMasterDataItem | undefined>("manageSW/setHazardCategory");
export const setNoticeLevelHazardCategory = createAction<{hazardCategory: IMasterDataItem | undefined, noticeGuid: string, stepGuid : string | undefined}>("manageSW/setNoticeLevelHazardCategory");
export const setVersionChanges = createAction<string>("manageSW/setVersionChanges");

export const addMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageSW/addMetaDataValue");

export const addReusableMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageSW/addReusableMetaDataValue");

export const removeMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageSW/removeMetaDataValue");

export const removeReusableMetaDataItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageSW/removeReusableMetaDataValue");

export const addPPE = createAction<string>("manageSW/addPPE");
export const removePPE = createAction<string>("manageSW/removePPE");
export const setCurrentTab = createAction<ManageSWTabs>("manageSW/setCurrentTab");

// Step Actions
export const addNewStep = createAction<{ parentStepGuid: string | null, swType: SWTypes, stepType?: StepTypes }>("manageSW/addNewStep");
export const duplicateStep = createAction<{ stepGuid: string }>("manageSW/duplicateStep");
export const deleteStep = createAction<{ stepGuid: string, isRCStep: boolean }>("manageSW/deleteStep");
export const setStepBooleanValue = createAction<IStepBooleanPayload>("manageSW/setStepBooleanValue");
export const setStepStringValue = createAction<IStepStringPayload>("manageSW/setStepStringValue");
export const setServiceLevel = createAction<IServiceLevelPayload>("manageSW/setServiceLevel");
export const setUsage = createAction<IUsagePayload>("manageSW/setUsage");
export const setSiteLocation = createAction<ISiteLocationPayload>("manageSW/setSiteLocation");
export const updateStepSortOrder = createAction<IStep[]>("manageSW/updateStepSortOrder");
export const updateReusableCompSteps = createAction<IStep[]>("manageSW/updateReusableCompSteps");
export const updateComponentSortOrder = createAction<IStepSortOrderPayload>("manageSW/updateComponentSortOrder");
export const updateComponentSortOrders = createAction<{
  stepGuid?: string,
  items: {
    guid: string,
    newIndex: number,
  }[],
}>("manageSW/updateComponentSortOrders");
export const updateRefDocSortOrders = createAction<{
  guid: string,
  newIndex: number,
}[]>("manageSW/updateRefDocSortOrders");
export const setStepVisibility = createAction<IStepVisibility>("manageSW/setStepVisibility");
export const expandAndScrollToStep = createAction<{
  stepGuid: string,
  ancestorGuids: string[],
}>("manageSW/expandAndScrollToStep");
export const finishScrollingToStep = createAction("manageSW/finishScrollingToStep");
export const setActiveStepGuid = createAction<string>("manageSW/setActiveStepGuid");
export const updateTaskStepsMetadata = createAction<{ metadataName: "serviceLevel", value: any}>("manageSW/updateStepMetadata");

// Reference doc related actions.
export const addNewReferenceDoc = createAction<{ type: string }>("manageSW/addNewReferenceDoc");
export const addNewReferenceDocTable = createAction("manageSW/addNewReferenceDocTable");
export const removeReferenceDoc = createAction<{ refDocGuid: string }>("manageSW/removeReferenceDoc");
export const setRefDocLabel = createAction<{ refDocGuid: string, label: string }>("manageSW/setRefDocLabel");
export const setRefDocFilename = createAction<{ refDocGuid: string, filename: string }>("manageSW/setRefDocFilename");
export const setRefDocTableData = createAction<{ refDocGuid: string, component: IRefDocTableComponent }>("manageSW/setRefDocTableData");
export const updateRefDocTableCell = createAction<{ refDocGuid: string, cell: ITableComponentCell }>("manageSW/updateRefDocTableCell");

// Publish Reusable Content
export const loadPublishRCData = createAction<number>("manageSW/loadPublishRCData");
export const updatePublishRCData = createAction<number>("manageSW/updatePublishRCData");
export const setPublishOperation = createAction<IOperation | undefined>("manageSW/setPublishOperation");
export const publishRC = createAction<{ swGuid: string, swVersion: number }>("manageSW/publishRC");

// Add Reusable Content
export const setSearchText = createAction<{ searchText: string, onlyTask: boolean, isSourceSystemTIME: boolean, rcTypeButton?: RCTypes, searchSource: string }>("manageSW/setSearchText");
export const fetchRCSteps = createAction<IFetchRCStepsPayload>("manageSW/FetchRCSteps");
export const setPendingCompatibilityFetchRCStepsAction = createAction<IFetchRCStepsPayload | undefined>("manageSW/setPendingCompatibilityFetchRCStepsAction");
export const fetchRCTimeSteps = createAction<{ swGuid: string, swType: string, rcGuid: string, rcVersion: number, isRCTask: boolean, stepGuid?: string, source: string, rcType: string }>("manageSW/FetchRCTimeSteps");
export const addRCSteps = createAction<{ rcSteps: IStep[], rcID: number, isRCTask: boolean, stepGuid?: string, rcTitle: string }>("manageSW/AddRCSteps");
export const addRCSubSteps = createAction<{ rcSteps: IStep[], rcID: number, rcTitle: string, rcType?: RCTypes, parentStepGuid?: string }>("manageSW/addRCSubSteps");
export const breakRCLink = createAction<{ swID: number, rcID: number }>("manageSW/breakRCLink");
export const updateRCLinkBreak = createAction<{ rcID: number }>("manageSW/updateRCLinkBreak");
export const removeRCContainerFromSteps = createAction("manageSW/removeRCContainerFromSteps");
export const addRCContainerInSteps = createAction("manageSW/addRCContainerInSteps");

// Step component related actions.
export const addNewComponent = createAction<{ stepGuid?: string, type: StepComponentTypes }>("manageSW/addNewComponent");
export const addRoutingOperationComponent = createAction<{ stepGuid: string, type: StepComponentTypes.RoutingOperation, operation: IOperationTreeNode }>("manageSW/addRoutingOperationComponent");
export const getStepComponentImageUrl = createAction<IStepComponentImageRequest>("manageSW/getStepComponentImageUrl");
export const getTableCellImageUrls = createAction<ITableCellImagesRequest>("manageSW/getTableCellImageUrls");
export const getRefDocTableCellImageUrls = createAction<IRefDocTableCellImagesRequest>("manageSW/getRefDocTableCellImageUrls");
export const removeComponent = createAction<{ stepGuid?: string, componentGuid: string }>("manageSW/removeComponent");
export const updateNotice = createAction<{ component: INoticeComponent, stepGuid?: string }>("manageSW/updateNotice");
export const updateTextInput = createAction<{ component: ITextInputComponent, stepGuid: string }>("manageSW/updateTextInput");
export const updateRichTextInput = createAction<{ component: IRichTextInputComponent, stepGuid: string }>("manageSW/updateRichTextInput");
export const updateNumberInput = createAction<{ component: INumberInputComponent, stepGuid: string }>("manageSW/updateNumberInput");
export const updateFormulaInput = createAction<{ component: IFormulaInputComponent, stepGuid: string }>("manageSW/updateFormulaInput");
export const updateDateInput = createAction<{ component: IDateInputComponent, stepGuid: string }>("manageSW/updateDateInput");
export const updateDateTimeInput = createAction<{ component: IDateTimeInputComponent, stepGuid: string }>("manageSW/updateDateTimeInput");
export const updatePhotoInput = createAction<{ component: IPhotoInputComponent, stepGuid: string }>("manageSW/updatePhotoInput");
export const updateVideoInput = createAction<{ component: IVideoInputComponent, stepGuid: string }>("manageSW/updateVideoInput");
export const updateAudioInput = createAction<{ component: IAudioInputComponent, stepGuid: string }>("manageSW/updateAudioInput");
export const updateSignatureInput = createAction<{ component: ISignatureInputComponent, stepGuid: string }>("manageSW/updateSignatureInput");
export const updateYesNoInput = createAction<{ component: IYesNoInputComponent, stepGuid: string }>("manageSW/updateYesNoInput");
export const updateQualityControlPoint = createAction<{ component: IQualityControlPointComponent, stepGuid: string }>("manageSW/updateQualityControlPoint");
export const updateImage = createAction<{ component: IImageComponent, stepGuid: string }>("manageSW/updateImage");
export const updateImageBlobData = createAction<{ blobData: IImageBlobData, stepGuid: string, componentGuid: string }>("manageSW/updateImageBlobData");
export const updateTableCellBlobData = createAction<{ stepGuid: string, componentGuid: string, fileName: string, rowIndex: number, colIndex: number, serverFileName?: string }>("manageSW/updateTableCellBlobData");
export const updateRefDocTableCellBlobData = createAction<{ refDocGuid: string, fileName: string, rowIndex: number, colIndex: number }>("manageSW/updateRefDocTableCellBlobData");
export const updateSelectInput = createAction<{ component: ISelectInputComponent, stepGuid: string }>("manageSW/updatupdateSelectInputeImage");
export const updateMultiSelectInput = createAction<{ component: IMultiSelectInputComponent, stepGuid: string }>("manageSW/updateMultiSelectInput");
export const updateMSSVideo = createAction<{ component: IMicrosoftStreamVideoComponent, stepGuid: string }>("manageSW/updateMSSVideo");
export const updateLink = createAction<{ component: ILinkComponent, stepGuid: string }>("manageSW/updateLink");
export const updatePPEComponent = createAction<{ component: IPPEComponent, stepGuid: string, applyAll?: boolean }>("manageSW/updatePPEComponent");
export const updateTable = createAction<{ component: ITableComponent, stepGuid: string }>("manageSW/updateTable");
export const setTableLabel = createAction<{ stepGuid: string, componenGuid: string, label: string }>("manageSW/setTableLabel");
export const updateTableCell = createAction<{ cell: ITableComponentCell, stepGuid: string, componentGuid: string }>("manageSW/updateTableCell");
export const uploadSWAttachment = createAction<ISWAttachment>("manageSW/uploadSWAttachment");
export const pasteSWComponentToSWDraft = createAction<IPasteSWComponentToSWDraft>("manageSW/pasteSWComponentToSWDraft");
export const unlockSWCurrentEditor = createAction<{ swGuid: string, owningOrgId?: string, owningPlantId?: string }>("manageSW/unlockSWCurrentEditorLock");
export const saveSWComment = createAction<string>("manageSW/saveSWComment");
export const saveSWStepComment = createAction<{ stepID: number, comment: string }>("manageSW/saveSWStepComment");
export const resolveStepComment = createAction<{ commentID: number, stepID: number }>("manageSW/resolveStepComment");
export const loadComments = createAction<{ swGuid: string, swVersion: number }>("manageSW/loadComments");
export const updateStepCommentsInfo = createAction<{ stepID: number, hasUnresolvedComments: boolean, totalStepComments: number, unResolvedStepComments: number }>("manageSW/updateStepCommentsInfo");
export const loadStepComments = createAction<{ stepID: number }>("manageSW/loadStepComments");
export const setComments = createAction<ISWCommentData>("manageSW/setComments");
export const setStepComments = createAction<ISWStepCommentData>("manageSW/setStepComments");
export const loadAllSWComments = createAction<{ swGuid: string, swVersion: number }>("manageSW/loadAllSWComments");
export const getNoticeImageUrl = createAction<INoticeImageRequest>("manageSW/getNoticeImageUrl");
export const updateNoticeImageBlobData = createAction<{ noticeGuid: string, stepGuid?: string, isDirty?: boolean, filename: string, blob: IImageBlobData, imageType: ImageType }>("manageSW/updateNoticeImageBlobData");
export const uploadNoticeImage = createAction<INoticeAttachment>("manageSW/uploadNoticeImage");
export const uploadVideo = createAction<IVideoAttachment>("manageSW/uploadVideo");
export const uploadAudio = createAction<IAudioAttachment>("manageSW/uploadAudio");
export const uploadTableImage = createAction<ITableComponentImage>("manageSW/uploadTableImage");
export const setTableImageFilename = createAction<{ stepGuid: string, componentGuid: string, serverFilename: string, rowIndex: number, colIndex: number }>("manageSW/setTableImageFilename");
export const setRefDocTableImageFilename = createAction<{ refDocGuid: string, serverFilename: string, rowIndex: number, colIndex: number }>("manageSW/setRefDocTableImageFilename");
export const getVideoUrl = createAction<IVideoRequest>("manageSW/getVideoUrl");
export const getAudioUrl = createAction<IAudioRequest>("manageSW/getAudioUrl");
export const updateVideoBlobData = createAction<{ stepGuid: string, componentGuid: string, isDirty?: boolean, filename: string, blob: IImageBlobData }>("manageSW/updateVideoBlobData");
export const updateAudioBlobData = createAction<{ stepGuid: string, componentGuid: string, isDirty?: boolean, filename: string, blob: IImageBlobData }>("manageSW/updateAudioBlobData");
export const updateVideo = createAction<{ component: IVideoComponent, stepGuid: string }>("manageSW/updateVideo");
export const resolveSWComment = createAction<{ commentId: number, swId: number, resolved: boolean }>("manageSW/resolveSWComment");
export const setCurrentExecutionPreviewStep = createAction<IExecutionPreviewLocation>("manageSW/setCurrentExecutionPreviewStep");
export const loadSWRevisionHistories = createAction<{ swGuid: string }>("manageSW/loadSWRevisionHistories");
export const setRevisionHistories = createAction<{ revisionHistories: ISWRevisionHistory[] }>("manageSW/setRevisionHistories");
export const setReusableCompSortable = createAction<Item[]>("manageSW/setReusableCompSortable");
export const addReusableCompItemChecked = createAction<Item>("manageSW/addReusableCompItemChecked");
export const removeReusableCompItemChecked = createAction<Item>("manageSW/removeReusableCompItemChecked");
export const saveReusableCompFromTLMSWI = createAction<RCTypes>("manageSW/saveReusableCompFromTLMSWI");
export const setReusableCompMode = createAction<SWTypes>("manageSW/setReusableCompMode");
export const setCreateReusableContentPopup = createAction<{isCreateRCPopup: boolean, swType: SWTypes}>("manageSW/setCreateReusableContentPopup");
export const setAllSiteLocations = createAction<ISiteLocation[]>("manageSW/setAllSiteLocations");
export const getAllSiteLocations = createAction("manageSW/getAllSiteLocations");
export const setOOS = createAction<IOOSPayload>("manageSW/setOOS");
export const updatePassFailInput = createAction<{ component: IPassFailInputComponent, stepGuid: string }>("manageSW/updatePassFailInputLabel");
export const loadSWNoticeSummary = createAction<{ swGuid: string, swVersion: number }>("manageSW/loadSWNoticeSummary");
export const setSWNotices = createAction<ISWNotices>("manageSW/setSWNotices");
export const setSWStepNotices = createAction<ISWStepNotices>("manageSW/setSWStepNotices");
//TIME image
export const updateTIMEImage = createAction<{ component: ITIMEImageComponent, stepGuid: string }>("manageSW/updateTIMEImage");
export const getStepComponentTIMEImageUrl = createAction<IStepComponentTIMEImageRequest>("manageSW/getStepComponentTIMEImageUrl");
export const searchTimeImageNumber = createAction<{ searchText: string }>("manageSW/searchImageNumber");
export const setTimeImageData = createAction<ITimeImageData>("manageSW/setTimeImageData");
export const uploadDownloadTimeImage = createAction<ITimeImageAttachment>("manageSW/uploadDownloadTimeImage");
export const getTimeMediaWhereUsedFiles = createAction<{ imageNumber: string }>("manageSW/getTimeMediaWhereUsedFiles");
export const setTimeMediaWhereUsedFiles = createAction<IGetTimeMediaFilesMappingData>("manageSW/setTimeMediaWhereUsedFiles");
export const cancelTimeMediaMappingModal = createAction("manageSW/cancelTimeMediaMappingModal");
export const downloadNoticeTimeImage = createAction<INoticeTimeImageAttachment>("manageSW/downloadNoticeTimeImage");
export const setTableTimeImageFilename = createAction<{ stepGuid: string, componentGuid: string, serverFilename: string, rowIndex: number, colIndex: number, value: string, altTagValue: string }>("manageSW/setTableTimeImageFilename");
export const downloadTableTimeImage = createAction<ITableTimeImageAttachment>("manageSW/downloadTableTimeImage");
export const disableRcContent = createAction<{ stepGuid: string, disabled: boolean }>("manageSW/disableRcContent");
export const setSWLock = createAction<{ islock: boolean }>("manageSW/setSWLock");
export const redirectToSearchFromTimeRC = createAction<boolean>("manageSW/redirectToSearchFromTimeRC");

//Rc Notice
export const setSearchRCNotice = createAction<{ searchText: string, IsSearchforRCNotice: boolean, rcType: RCTypes }>("manageSW/setSearchRCNotice");
export const setIsSearchRCNoticeOpen = createAction<boolean>("manageSW/setIsSearchRCNoticeOpen");
export const setRCNoticeStep = createAction<string>("manageSW/setRCNoticeStep");
export const addRcNoticeComponent = createAction<{ stepGuid?: string, type: StepComponentTypes }>("manageSW/addRcNoticeComponent");
export const fetchRCNotice = createAction<{ swGuid: string, rcID: number, rcGuid: string, rcVersion: number, stepGuid?: string, source: string, currentNoticeGuid: string, isRCComponent: boolean, }>("manageSW/fetchRCNotice");
export const addRCNotices = createAction<{ notice: INoticeComponent | null, stepGuid?: string, rcID?: number, rcTitle: string, currentNoticeGuid: string, isRCComponent: boolean, rcGuid: string, rcVersion: number }>("manageSW/addRCNotices");
export const updateRCNoticeLinkBreak = createAction<{ stepGuid?: string, componentGuid: string }>("manageSW/updateRCNoticeLinkBreak");
export const setNoticeVisibility = createAction<INoticeVisibility>("manageSW/setNoticeVisibility");
export const disableRcNoticeContent = createAction<{ stepGuid?: string, componentGuid: string, disabled: boolean }>("manageSW/disableRcNoticeContent");
export const setCreateRCNoticePopup = createAction<{ rcNoticePopupOpen: boolean, notice: INoticeComponent | null }>("manageSW/setCreateRCNoticePopup");
export const setGroup = createAction<IMasterDataItem | undefined>("manageSW/setGroup");
export const setGroupCounter = createAction<IMasterDataItem | undefined>("manageSW/setGroupCounter");

// Confidential TCC Management
export const loadConfidentialEnabledUsers = createAction<{ swId: number }>("manageSW/loadConfidentialEnabledUsers");
export const setConfidentialAccessChangeOperation = createAction<IOperation>("manageSW/setConfidentialAccessChangeOperation");
export const setConfidentialUsersLoadOperation = createAction<IOperation>("manageSW/setConfidentialUsersLoadOperation");
export const setConfidentialEnabledUserList = createAction<IConfidentialSWUser[]>("manageSW/setConfidentialEnabledUserList");
export const addConfidentialAccess = createAction<{ swId: number, userId: string, role: ConfidentialAccessRoles }>("manageSW/addConfidentialAccess");
export const removeConfidentialAccess = createAction<{ swId: number, userId: string, role: ConfidentialAccessRoles }>("manageSW/removeConfidentialAccess");

// Connected SWI related actions
export const addConnectedSWI = createAction<IAddConnectedSWIPayload>("manageSW/connectedSWI/addConnectedSWI");
export const setAddConnectedSWILoadOperation = createAction<IOperation>("manageSW/connectedSWI/setAddConnectedSWILoadOperation");
export const setConnectedSWIGroupCounter = createAction<IMasterDataItem | undefined>("manageSW/connectedSWI/setGroupCounter");
export const setConnectedSWIGroup = createAction<IMasterDataItem | undefined>("manageSW/connectedSWI/setGroup");
export const setConnectedSWIMaterial = createAction<IMasterDataItem | undefined>("manageSW/connectedSWI/setMaterial");
export const deleteConnectedSWI = createAction<IDeleteConnectedSWIPayload>("manageSW/connectedSWI/deleteConnectedSWI");
export const uploadConnectedSWIs = createAction<ISWAttachment>("manageSW/uploadConnectedSWIs");
export const setSetConnectedSWIs = createAction<IConnectedSWI[]>("manageSW/setAllChildMaterials");
export const setUploadConnectedSWIsErrors = createAction<IConnectedSWIsUploadError[]>("");
export const getConnectedSWIs = createAction<{ swGuid: string, swVersion: number }>("manageSW/getSWChildMaterials");
export const copyComponent = createAction<StepComponentType>("manageSW/copyStepComponent");
export const pasteComponent = createAction<IPasteComponents>("manageSW/pasteStepComponent");
export const loadConnectedSWIConfiguration = createAction("manageSW/loadConnectedSWIConfiguration");
export const setConnectedSWIEnabled = createAction<boolean>("manageSW/setConnectedSWIEnabled");
export const setNoticeLevelPotentialLoss = createAction<{ potentialLoss: IMasterDataItem, noticeGuid: string, stepGuid: string | undefined }>("manageSW/setNoticeLevelPotentialLoss");
export const removeNoticeLevelPotentialLoss = createAction<{ potentialLoss: IMasterDataItem | undefined, noticeGuid: string, stepGuid: string | undefined }>("manageSW/removeNoticeLevelPotentialLoss");
