import React, { ReactNode, useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import addIcon from "media/icons/dls/add.svg";
import "./SearchTechContentUser.scoped.scss";
import { useDispatch } from "react-redux";
import { getFilteredUsersList, setSearchLDAPManageTechContentUsersText, setSearchManageTechContentUsersText } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import useDebounce from "utilities/useDebounce";
import BlockSpinner from "components/common/BlockSpinner";
import { ISW, SWTypes } from "interfaces/sw/SWInterfaces";
import { IManageUserUser, IAADUser } from "interfaces/user/UserInterfaces";

interface ISearchTechContentUserProps {
    setSelectedUserFunc(email: string): void,
    optional: boolean,
    isFilteredList: boolean,
    isLDAPSearch: boolean,
    title?: ReactNode,
    sw?: ISW,
}

const SearchTechContentUser: React.FC<ISearchTechContentUserProps> = ({ setSelectedUserFunc, optional, isFilteredList, isLDAPSearch, title, sw }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState<IManageUserUser[]>([]);
    const [ldapUsers, setLDAPUsers] = useState<IAADUser[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>("");
    const [isCurrentInstance, setIsCurrentInstance] = useState(false); //when more than one instance of this component exists, returns if the current one is in use  
    const debouncedSearchTerm = useDebounce(searchTerm, 750);
    const manageUsersData = useSelector(store => store.userManagement.manageTechContentUsersData);
    const { getUsersOperation, userList, ldapUserList } = useSelector(store => store
        .userManagement
        .manageTechContentUsersData);
    const currentUser = useSelector(store => store.auth.currentUser.email);
    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [showWarning, setShowWarning] = useState<JSX.Element | undefined>();
    const [showWarningOptional, setShowWarningOptional] = useState<JSX.Element | undefined>();

    useEffect(() => {
        //setSelectedUser(""); - commented out to fix bug: 4527102
        setIsCurrentInstance(true);
        if (isLDAPSearch) {
            dispatch(setSearchLDAPManageTechContentUsersText(debouncedSearchTerm.trim()));
        }
        else {
            if (isFilteredList) {
                dispatch(getFilteredUsersList({ searchTerm: debouncedSearchTerm.trim() }));
            }
            else {
                dispatch(setSearchManageTechContentUsersText(debouncedSearchTerm.trim()));
            }
        }
    }, [debouncedSearchTerm, dispatch, isFilteredList]);

    useEffect(() => {
        if(isCurrentInstance)
        {
            setUsers(userList);
            setLDAPUsers(ldapUserList);
            setErrorMessage(getUsersOperation?.errorMessage);
            setIsCurrentInstance(false);
        }
    }, [getUsersOperation?.errorMessage, userList, ldapUserList]);

    let component: JSX.Element | undefined;
    let titleArea = title || (<label className="tech-label"> Technical Content Reviewer {optional === true ? "(Optional)" : ""}{selectedUser}</label>);

    if (isCurrentInstance && getUsersOperation?.isWorking) {
        component = (
            <BlockSpinner />
        );
    } else if (errorMessage) {
        component = (
            <>
                {titleArea}
                <br />
                <label className="error">
                    {errorMessage}
                </label>
            </>
        );
    } else if (!users.length && !ldapUsers.length) {
        component = (
            <>
                {titleArea}
                <br />
                <label>No users found.</label>
            </>
        );
    } else {
        component = (
            <>
                {titleArea}
                {!isLDAPSearch && users.filter(x => x.email !== currentUser).map(r =>
                    <div
                        className="user"
                        key={r.email}
                    >
                        <span>
                            {r.name} ({r.email})
                        </span>
                        <img
                            src={addIcon}
                            className="add-button icon-medium hover-gray-bg"
                            onClick={() => onUserSelect(r.email, r.name)}
                            alt="Add"
                        />
                    </div>
                )}
                {isLDAPSearch && ldapUsers.filter(x => x.email !== currentUser).map(r =>
                    <div
                        className="user"
                        key={r.email}
                    >
                        <span>
                            {r.name} ({r.email})
                        </span>
                        <img
                            src={addIcon}
                            className="add-button icon-medium hover-gray-bg"
                            onClick={() => onUserSelect(r.email, r.name)}
                            alt="Add"
                        />
                    </div>
                )}
            </>
        );
    }

    const onUserSelect = (email: string, name: string) => {
        if (sw?.tcc && (sw?.type === SWTypes.MFGCL || sw?.type === SWTypes.MFGSWI || sw?.type === SWTypes.MFGRC)) {
            let warningMsg: React.JSX.Element = <span>TCC sensitive content is about to be shared with the <b>{name}</b> TechContentApprover.</span>;
            if (isLDAPSearch) {
                setShowWarning(warningMsg);
            }
            else {
                setShowWarningOptional(warningMsg);
            }
        }
        setSelectedUserFunc(email);
        setSelectedUser("(" + name + ")");
    }

    const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowWarning(undefined);
        setShowWarningOptional(undefined);
        setSearchTerm(e.target.value);
    }

    return (
        <>
            <br />
            <div className="search-user-bar">
                <div className="input-holder">
                    <input
                        type="text"
                        id="search-user"
                        placeholder="Search by name or email..."
                        disabled={isCurrentInstance && manageUsersData.getUsersOperation?.isWorking}
                        value={searchTerm}
                        onChange={onSearchTermChange}
                    />
                    <img
                        src={searchIcon}
                        alt="Search"
                        className="icon-small"
                    />
                </div>
                <span className="msg">
                    {showWarning}
                    {showWarningOptional}
                </span>

            </div>
            <div className="results">
                {component}
            </div>
        </>
    );
}

export default SearchTechContentUser;