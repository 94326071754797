import React, { useState, useEffect } from "react";
import StepList from "./StepList";
import "./ManageSteps.scoped.scss";
import PPEEditor from "./ppe/PPEEditor";
import ManageNotices from "./notices/ManageNotices";
import useSelector from "store/useSelector";
import StepEditor from "./StepEditor";
import { useDispatch } from "react-redux";
import { addNewStep, removePPE, addPPE, deleteStep, setStepBooleanValue, setStepStringValue, addNewComponent, setStepVisibility, expandAndScrollToStep, finishScrollingToStep, fetchRCSteps, fetchRCTimeSteps, updateRCLinkBreak, setRCType, addRoutingOperationComponent, getAllSiteLocations, setPendingCompatibilityFetchRCStepsAction } from "store/manageSW/manageSWActions";
import Collapsible from "components/common/Collapsible";
import { StepStringMetaPropNames, StepComponentTypes, IStep, SWTypes, StepTypes, RCTypes, IRoutingOperationComponent } from "interfaces/sw/SWInterfaces";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import doesStepHaveMetadata from "utilities/sw/doesStepHaveMetadata";
import FlowLayout from "components/layout/FlowLayout";
import StepCommentList from "./StepCommentList";
import AddSWModal from "./AddSWModal";
import AddRCModal from "./AddRCModal";
import Banner, { BannerType } from "components/common/Banner";
import StepComponent from "./components/StepComponent";
import { clearFilter } from "store/swList/swListActions";
import { IOperationTreeNode } from "store/masterData/masterDataTypes";
import { showErrorToast } from "store/toast/toastActions";
import Modal from "components/common/Modal";

interface IManageStepsProps {
  isDisabled: boolean,
}

const ManageSteps: React.FC<IManageStepsProps> = ({ isDisabled }) => {
  const {
    SW,
    isDirty,
    stepVisibilities,
    noticeVisibilities,
    scrollToStepGuid,
    siteLocations,
    pendingCompatibilityFetchRCStepsAction
  } = useSelector(store => store.manageSW);

  const {
    ppe,
    steps,
    guid,
    version,
    type,
    notices,
    rcType,
    owningPlant,
    materials,
    group,
    groupCounter,
  } = SW;

  const dispatch = useDispatch();
  const [isStepWarningOpen, setIsStepWarningOpen] = useState(false);
  const [isSearchRCOpen, setIsSearchRCOpen] = useState(false);
  const [searchOnlyTask, setSearchOnlyTask] = useState(true);
  const [rcParentStep, setRCParentStep] = useState("");
  const [rcTypeButton, setRCTypeButton] = useState(RCTypes.SubStep);
  useEffect(() => {
    if (steps.some(x => x.isTask)) {
      setRCTypeButton(RCTypes.Task);
    }
    else if (steps.some(x => !x.isTask && x.stepType !== StepTypes.SubStep)) {
      setRCTypeButton(RCTypes.Step);
    }
    else if (steps.some(x => !x.isTask && x.stepType === StepTypes.SubStep)) {
      setRCTypeButton(RCTypes.SubStep);
    }
    if (siteLocations.length == 0) {
      dispatch(getAllSiteLocations())
    }
  }, [dispatch]);

  useEffect(() => {
    if (scrollToStepGuid) {
      dispatch(finishScrollingToStep());
    }
  }, [scrollToStepGuid, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPendingCompatibilityFetchRCStepsAction(undefined));
    }
  }, []);


  const onDeleteClick = (stepGuid: string, isRCStep: boolean) => {
    dispatch(deleteStep({
      stepGuid,
      isRCStep,
    }));
  }

  const onRCDelinkStepsClick = (rcID: number) => {
    dispatch(updateRCLinkBreak({
      rcID: rcID,
    }))
  }

  const onConditionalChange = (stepGuid: string,
    isConditional: boolean) => {
    dispatch(setStepBooleanValue({
      stepGuid,
      propName: "isConditional",
      value: isConditional,
    }));
  }

  const onClientReportChange = (stepGuid: string,
    clientReport: boolean) => {
    dispatch(setStepBooleanValue({
      stepGuid,
      propName: "clientReport",
      value: clientReport,
    }));
  }

  const onStepStringValueChange = (stepGuid: string, propName: StepStringMetaPropNames, value: string) => {
    dispatch(setStepStringValue({
      stepGuid,
      propName,
      value,
    }));
  }

  const onAddChildStep = (parentStep: IStep) => {
    if (type !== SWTypes.TLMSWI && doesStepHaveMetadata(parentStep)) {
      if (!window.confirm("Adding a child step will remove the existing metadata for this step. Continue?")) {
        return;
      }
    }

    var stepTypeBasedOnParent = StepTypes.Step;
    if (parentStep.isTask) {
      stepTypeBasedOnParent = StepTypes.Step;
    }
    else if (parentStep.stepType === StepTypes.Step 
      || parentStep.stepType === StepTypes.SubStep) {
      stepTypeBasedOnParent = StepTypes.SubStep;
    }

    stepsCountCalculate();

    dispatch(addNewStep({
      parentStepGuid: parentStep.guid,
      swType: type,
      stepType: type === SWTypes.TLMSWI 
      || type === SWTypes.ECL 
      || type === SWTypes.CL 
      || type === SWTypes.SWI 
      || type === SWTypes.TLMRC 
      || type === SWTypes.MFGRC 
      || type === SWTypes.MFGSWI ? stepTypeBasedOnParent : undefined,
    }));

    dispatch(setStepVisibility({
      stepGuid: parentStep.guid,
      isOpen: true,
    }));
  }

  const onAddNewComponent = (stepGuid: string, type: StepComponentTypes) => {
    dispatch(addNewComponent({
      stepGuid,
      type,
    }));
  }

  const onAddRoutingOperationComponent = (
    stepGuid: string,
    operation: IOperationTreeNode
  ) => {
    const usedOperations = steps
      .filter((step) => step.guid !== stepGuid)
      .flatMap((step) => step.components)
      .filter(
        (component) => component.type === StepComponentTypes.RoutingOperation
      )
      .map((c) => c as IRoutingOperationComponent)
      .filter((component) => 
        component.operation.operation == operation.operation && 
        component.operation.taskListNode == operation.taskListNode);

    if (usedOperations.length > 0) {
      dispatch(
        showErrorToast(
          "This Operation is already in use. Please select another Operation."
        )
      );
      return;
    }

    dispatch(
      addRoutingOperationComponent({
        stepGuid,
        operation,
        type: StepComponentTypes.RoutingOperation,
      })
    );

    onStepStringValueChange(
      stepGuid,
      "title",
      `${operation.operation} - ${operation.operationText}`
    );
  };

  const onSetStepVisibility = (stepGuid: string, isOpen: boolean) => {
    dispatch(setStepVisibility({
      stepGuid,
      isOpen,
    }));
  }

  const onStepListStepClick = (ancestorGuids: string[], stepGuid: string) => {
    dispatch(expandAndScrollToStep({
      stepGuid,
      ancestorGuids,
    }));
  }

  const stepsCountCalculate = () => {
    if (type === SWTypes.CL) {
      let stepsCount = 0;
      for (const step of steps) {
        stepsCount = stepsCount + step.children.length + 1;
      }
      if (stepsCount >= 9) {
        setIsStepWarningOpen(true);
      }
    } else if (type === SWTypes.LCL) {
      let stepsCount = 0;
      for (const step of steps) {
        stepsCount = stepsCount + step.children.length + 1;
      }
      if (stepsCount >= 30) {
        setIsStepWarningOpen(true);
      }
    }
  }

  const onAddStepClick = () => {
    stepsCountCalculate();
    dispatch(addNewStep({
      parentStepGuid: type === SWTypes.TLMSWI || type === SWTypes.MFGSWI ? 'task' : null,
      swType: type,
      stepType: StepTypes.Step,
    }))
  }

  const onAddRCType = (type: RCTypes) => {
    dispatch(setRCType(type));

    if (type === RCTypes.Task) {
      onAddRCTaskClick();
    } else if (type === RCTypes.Step) {
      onAddRCStepClick();
    } else if (type === RCTypes.SubStep) {
      onAddRCSubStepClick();
    }
  }

  const onAddRCTaskClick = () => {
    setRCTypeButton(RCTypes.Task);
    stepsCountCalculate();
    dispatch(addNewStep({
      parentStepGuid: 'task',
      swType: type,
      stepType: StepTypes.Task,
    }))
  }

  const onAddRCStepClick = () => {
    setRCTypeButton(RCTypes.Step);
    stepsCountCalculate();
    dispatch(addNewStep({
      parentStepGuid: null,
      swType: type,
      stepType: StepTypes.Step,
    }))
  }

  const onAddRCSubStepClick = () => {
    setRCTypeButton(RCTypes.SubStep);
    dispatch(addNewStep({
      parentStepGuid: 'rcSubStep',
      swType: type,
      stepType: StepTypes.SubStep,
    }));
  }

  const onSearchRCTaskClick = () => {
    setSearchOnlyTask(true);
    setIsSearchRCOpen(true);
    setRCTypeButton(RCTypes.Task);
  }

  const AddRCStepsToSw = (rcGuid: string, rcVersion: number, source: string) => {
    if(SW.guid === rcGuid)
    {
      dispatch(
        showErrorToast(
          "Adding the same Reusable Content to Parent Reusable Content is not allowed"
        )
      );
      return;
    }
    dispatch(fetchRCSteps({
      rcGuid: rcGuid,
      swType: type,
      rcVersion: rcVersion,
      isRCTask: searchOnlyTask,
      stepGuid: rcParentStep,
      swGuid: guid,
      source: source,
      ignoreIncompatibility: false,
    }));
  }

  const AddRCTimeStepsToSw = (rcGuid: string, rcVersion: number, source: string, rcType: string) => {
    dispatch(fetchRCTimeSteps({
      rcGuid: rcGuid,
      swType: type,
      rcVersion: rcVersion,
      isRCTask: searchOnlyTask,
      stepGuid: rcParentStep,
      swGuid: guid,
      source: source,
      rcType: rcTypeButton
    }));
  }

  const onSearchRCStepsClick = (step?: IStep) => {
    setIsSearchRCOpen(true);
    setSearchOnlyTask(false);
    setRCTypeButton(RCTypes.Step);
    if (step !== undefined && step !== null)
      setRCParentStep(step!.guid);
  }

  const onSearchRCSubStepsClick = (step?: IStep) => {
    setIsSearchRCOpen(true);
    setSearchOnlyTask(false);
    setRCTypeButton(RCTypes.SubStep);
    if (step !== undefined && step !== null)
      setRCParentStep(step.guid);
  }

  const disableRcTaskStep = () => {
    return;
  }

  const newNotice = () => {
    dispatch(addNewComponent({
      stepGuid: undefined,
      type: StepComponentTypes.Notice,
    }));
  }

  const resetRcFilters = () => {
    setIsSearchRCOpen(false);
    dispatch(clearFilter());
  }

  return (
    <>
      <FlowLayout
        footer={!isDisabled
          ? (
            <div className="footer">
              {type !== SWTypes.TLMRC &&
                type !== SWTypes.MFGRC &&
                <>
                  <button
                    className="primary-button"
                    onClick={onAddStepClick}
                  >
                    {`+ Add ${type === SWTypes.TLMSWI || type === SWTypes.MFGSWI ? "Task" : "Step"}`}
                  </button>
                  {(type === SWTypes.TLMSWI) &&
                    <button
                      className="primary-button"
                      onClick={onSearchRCTaskClick}
                    >
                      {`+ Add Reusable Tasks`}
                    </button>
                  }
                  {type === SWTypes.CL &&
                    <button
                      className="primary-button"
                      onClick={() => onSearchRCStepsClick()}
                    >
                      {`+ Add Reusable Steps`}
                    </button>
                  }
                </>
              }
              {(type === SWTypes.TLMRC ||
                type === SWTypes.MFGRC) &&
                <div>
                  {rcType === RCTypes.Task && type !== SWTypes.MFGRC && (
                    <>
                      <button
                        className="primary-button"
                        onClick={() => onAddRCTaskClick()}
                      >
                        {`+ Add Task`}
                      </button>
                      <button
                        className="primary-button"
                        onClick={onSearchRCTaskClick}
                      >
                        {`+ Add Reusable Tasks`}
                      </button>
                    </>
                  )}
                  {rcType === RCTypes.Step &&
                    <>
                      <button
                        className="primary-button"
                        onClick={() => onAddRCStepClick()}
                      >
                        {`+ Add Step`}
                      </button>
                      <button
                        className="primary-button"
                        onClick={() => onSearchRCStepsClick()}
                      >
                        {`+ Add Reusable Steps`}
                      </button>
                    </>
                  }
                  {rcType === RCTypes.SubStep &&
                    <>
                      <button
                        className="primary-button"
                        onClick={() => onAddRCSubStepClick()}
                      >
                        {`+ Add SubStep`}
                      </button>
                      <button
                        className="primary-button"
                        onClick={() => onSearchRCSubStepsClick()}
                      >
                        {`+ Add Reusable SubSteps`}
                      </button>
                    </>
                  }
                  {steps.length === 0 && notices.length === 0 &&
                    <div>
                      {type !== SWTypes.MFGRC && (
                        <button
                          className="primary-button"
                          onClick={() => onAddRCType(RCTypes.Task)}
                        >
                          {`+ Add Task`}
                        </button>
                      )}
                      <button
                        className="primary-button"
                        onClick={() => onAddRCType(RCTypes.Step)}
                      >
                        {`+ Add Step`}
                      </button>
                      <button
                        className="primary-button"
                        onClick={() => onAddRCType(RCTypes.SubStep)}
                      >
                        {`+ Add SubStep`}
                      </button>
                      <button
                        className="primary-button"
                        onClick={newNotice}
                        disabled={notices.length > 0}
                      >
                        {`+ Add Notice`}
                      </button>
                    </div>
                  }
                </div>
              }
            </div>
          ) : undefined
        }
      >
        {isStepWarningOpen
          && <AddSWModal
            onModalClick={() => setIsStepWarningOpen(false)}
            header="Steps Warning"
            message={`Checklists should contain ${type === SWTypes.CL ? "9" : "30"} or less steps and child steps.`} />
        }
        <div className="manage-steps">
          <div className="left-pane">
            {steps.length > 0 &&
              <StepList
                steps={steps}
                ancestors={[]}
                isDisabled={isDisabled}
                maxDepth={(type === SWTypes.MFGCL || ((type === SWTypes.TLMRC || type === SWTypes.MFGRC ) && rcType === RCTypes.SubStep)) ? 0 : 5}
                onStepClick={onStepListStepClick}
                onStepToggle={onSetStepVisibility}
              />
            }
            {steps.length === 0 &&
              ((type === SWTypes.TLMSWI ||
                type === SWTypes.MFGSWI)
                ?
                <i>Add Tasks using the Add Task button at the bottom of the screen</i>
                : (type !== SWTypes.TLMRC && type !== SWTypes.MFGRC) &&
                <i>Add steps using the Add Step button at the bottom of the screen</i>
              )
            }
            {steps.length === 0 &&
              notices.length === 0 &&
              type === SWTypes.TLMRC && (
                <i>
                  Add Tasks/Steps/SubSteps/Notice using the Add Task Button/Add
                  Step button/Add SubStep button/Add Notice button at the bottom
                  of the screen
                </i>
              )}
            {steps.length === 0 &&
              notices.length === 0 &&
              type === SWTypes.MFGRC && (
                <i>
                  Add Steps/SubSteps/Notice using the Add Step button/Add
                  SubStep button/Add Notice button at the bottom of the screen
                </i>
              )}
          </div>
          <div className="right-pane">
            {type !== SWTypes.TLMRC &&
              type !== SWTypes.MFGRC &&
              <div>
                <Collapsible
                  header="PPE"
                  swType={type}
                  isRCStep={false}
                  isStepDisabled={false}
                  disableRcTaskStep={disableRcTaskStep}
                >
                  <PPEEditor
                    ppe={ppe}
                    isDisabled={isDisabled || type === SWTypes.LCL}
                    onRemovePPE={(ppeId) => dispatch(removePPE(ppeId))}
                    onAddPPE={(ppeId) => dispatch(addPPE(ppeId))}
                  />
                </Collapsible>
                <Collapsible
                  header="Notices"
                  isRCStep={false}
                  isStepDisabled={false}
                  disableRcTaskStep={disableRcTaskStep}
                >
                  <ManageNotices
                    isDisabled={isDisabled || type === SWTypes.LCL}
                    noticeVisibilities={noticeVisibilities}
                    isDraftPage={true}
                    isDirty={!!isDirty}
                  />
                </Collapsible>
              </div>
            }
            {type === SWTypes.TLMRC &&
              steps.length === 0 &&
              notices.length === 0 && (
                <Banner type={BannerType.Info}>
                  No Tasks/Steps/SubSteps/Notice Added. Please Add
                  Task/Step/SubStep/Notice from below buttons
                </Banner>
              )}
            {type === SWTypes.MFGRC &&
              steps.length === 0 &&
              notices.length === 0 && (
                <Banner type={BannerType.Info}>
                  No Steps/SubSteps/Notice Added. Please Add
                  Step/SubStep/Notice from below buttons
                </Banner>
              )}
            <div className="step-editors">
              {(type === SWTypes.TLMRC ||
                type === SWTypes.MFGRC) &&
                notices
                  .slice()
                  .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
                  .map((notice, index) =>
                    <StepComponent
                      key={index}
                      component={notice}
                      showWrapper={false}
                      isDisabled={isDisabled}
                      imageDataDestination={ImageDataDestinations.ManageSW}
                      swGuid={guid}
                      swVersion={version}
                      swType={type}
                      sw={SW}
                    />
                  )
              }
              {steps
                .slice()
                .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
                .map((step, index) =>
                  <StepEditor
                    key={index}
                    step={step}
                    parentOrders={[]}
                    isInTask={false}
                    isDisabled={
                      step.isRCStep || isDisabled || step.masterStepId != null
                    }
                    isSWEditable={!isDisabled}
                    swGuid={guid}
                    swVersion={version}
                    swType={type}
                    steps={steps}
                    imageDataDestination={ImageDataDestinations.ManageSW}
                    onDeleteStep={onDeleteClick}
                    onConditionalChange={onConditionalChange}
                    onClientReportChange={onClientReportChange}
                    onStepStringValueChange={onStepStringValueChange}
                    onAddChildStep={onAddChildStep}
                    onAddNewComponent={onAddNewComponent}
                    onAddRoutingOperationComponent={
                      onAddRoutingOperationComponent
                    }
                    isVisibilityExternal={true}
                    stepVisibilities={stepVisibilities}
                    setStepVisibility={onSetStepVisibility}
                    scrollToStepGuid={scrollToStepGuid}
                    isDraftPage={true}
                    stepsCountCalculate={stepsCountCalculate}
                    isRCStep={step.isRCStep}
                    onSearchRCSteps={onSearchRCStepsClick}
                    onSearchRcSubSteps={onSearchRCSubStepsClick}
                    onRCDelinkStep={onRCDelinkStepsClick}
                    rcParentOrder={[]}
                    noticeVisibilities={noticeVisibilities}
                    rcType={
                      type === SWTypes.TLMRC || type === SWTypes.MFGRC
                        ? rcType
                        : undefined
                    }
                    plantCode={owningPlant?.code}
                    materialCode={materials[0]?.code}
                    group={group?.code}
                    groupCounter={
                      isNaN(parseInt(groupCounter?.code || "NaN"))
                        ? undefined
                        : parseInt(groupCounter?.code || "NaN")
                    }
                    sw={SW}
                  />
                )
              }
            </div>
          </div>
        </div>
        <StepCommentList
          isDraftPage={true}
        />
      </FlowLayout>
      {isSearchRCOpen && type !== SWTypes.CL &&
        <AddRCModal
          onModalClick={() => resetRcFilters()}
          header="Search Reusable Content to be added"
          onlyTask={searchOnlyTask}
          AddRCTimeStepsToSw={AddRCTimeStepsToSw}
          AddRCStepsToSw={AddRCStepsToSw}
          allowedSourceSystems={(type === SWTypes.TLMRC || type === SWTypes.MFGRC || type === SWTypes.MFGSWI) ? ["WR"] : ["WR", "TIME"]}
          defaultSourceSystem={"WR"}
          hideSourceSystems={type == SWTypes.MFGSWI ? true : false}
          rcTypeButton={rcTypeButton}
        />
      }
      {isSearchRCOpen && type === SWTypes.CL &&
        <AddRCModal
          onModalClick={() => setIsSearchRCOpen(false)}
          header="Search Time Reusable Content to be added"
          onlyTask={searchOnlyTask}
          AddRCTimeStepsToSw={AddRCTimeStepsToSw}
          AddRCStepsToSw={AddRCStepsToSw}
          allowedSourceSystems={["TIME"]}
          defaultSourceSystem={"TIME"}
          hideSourceSystems={false}
          rcTypeButton={rcTypeButton}
        />
      }

      {pendingCompatibilityFetchRCStepsAction && (
        <Modal isOpen={true}
          header="Reusable Content contains incompatible content">
          <p>
            The selected Reusable Content contains Components that are not
            compatible with the current Standard Work. Do you want to ignore the
            compatibility and proceed with adding the Components?
          </p>
          <div className="modal-buttons">
            <button
              className="primary-button"
              onClick={() => {
                dispatch(
                  fetchRCSteps({...pendingCompatibilityFetchRCStepsAction, ignoreIncompatibility: true})
                );
              }}
            >
              Continue & Add Reusable Content
            </button>
            <button
              className="secondary-button"
              onClick={() => {
                dispatch(setPendingCompatibilityFetchRCStepsAction(undefined));
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>)}
    </>
  );
}

export default ManageSteps;