import { ILinkComponent, IStep, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import React from "react";
import Notice from "./components/Notice";
import Image from "./components/Image";
import TIMEImage from "./components/TIMEImage";
import "./StepBody.scoped.scss";
import useSelector from "store/useSelector";
import DateInput from "./components/DateInput";
import NumberInput from "./components/NumberInput";
import TextInput from "./components/TextInput";
import SelectInput from "./components/SelectInput";
import MicrosoftStreamVideo from "./components/MicrosoftStreamVideo";
import MultiSelectInput from "./components/MultiSelectInput";
import YesNoInput from "./components/YesNoInput";
import Video from "./components/Video";
import Audio from "./components/Audio";
import RichTextParagraph from "./components/RichTextParagraph";
import PPEComponent from "./components/PPEComponent";
import TableComponent from "./components/TableComponent";
import SignatureInput from "./components/SignatureInput";
import PhotoInput from "./components/PhotoInput";
import VideoInput from "./components/VideoInput";
import AudioInput from "./components/AudioInput";
import ToggleButtonInput from "./controls/ToggleButtonInput";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import PassFailInput from "./components/PassFailInput";

interface IStepBodyProps {
  step: IStep,
}

const StepBody: React.FC<IStepBodyProps> = ({ step }) => {
  const {
    SW: {
      type,
      guid,
      version,
    }
  } = useSelector(store => store.manageSW);

  let rcStep: boolean = false;
  let rcSWGuid: string = "";
  let rcSWVersion: number = 0;
  if (step.isRC
    && step.isRCTask) {
    rcStep = true;
    rcSWGuid = step.isRC.swGuid;
    rcSWVersion = step.isRC.swVersion;
  }

  const onConditionalResponseChange = (response: boolean) => {

  }

  return (
    <div className="step-container">
      <div className="step">
        <div className="body">
          {step.components.map(comp => {
            let renderComp: JSX.Element | undefined;

            if (comp.type === StepComponentTypes.Notice) {
              renderComp = (
                <Notice
                  swType={type}
                  component={comp}
                  swGuid={guid}
                  swVersion={version}
                  stepGuid={step.guid}
                  imageDataDestination={ImageDataDestinations.ManageSW}
                />
              );
            }
            else if (comp.type === StepComponentTypes.Image) {
              renderComp = (
                <Image
                  component={comp}
                  stepGuid={step.guid}
                  swGuid={!rcStep ? guid : rcSWGuid}
                  swVersion={!rcStep ? version : rcSWVersion}
                />
              );
            }
            else if (comp.type === StepComponentTypes.TimeImage) {
              renderComp = (
                <TIMEImage
                  component={comp}
                  stepGuid={step.guid}
                  swGuid={!rcStep ? guid : rcSWGuid}
                  swVersion={!rcStep ? version : rcSWVersion}
                />
              );
            }
            else if (comp.type === StepComponentTypes.DateInput
              || comp.type === StepComponentTypes.DateTimeInput) {
              renderComp = (
                <DateInput
                  component={comp}
                />
              );
            } else if (comp.type === StepComponentTypes.NumberInput) {
              renderComp = (
                <NumberInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.TextInput) {
              renderComp = (
                <TextInput
                  component={comp}
                  isDisabled={false}
                />
              );
            }
            else if (comp.type === StepComponentTypes.PhotoInput) {
              renderComp = (
                <PhotoInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.VideoInput) {
              renderComp = (
                <VideoInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.AudioInput) {
              renderComp = (
                <AudioInput
                  component={comp}
                  isDisabled={false}
                />
              );
            }
            else if (comp.type === StepComponentTypes.SelectInput) {
              renderComp = (
                <SelectInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.MicrosoftStreamVideo) {
              renderComp = (
                <MicrosoftStreamVideo
                  component={comp}
                />
              );
            } else if (comp.type === StepComponentTypes.MultiSelectInput) {
              renderComp = (
                <MultiSelectInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.SignatureInput) {
              renderComp = (
                <SignatureInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.YesNoInput) {
              renderComp = (
                <YesNoInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.PassFailInput) {
              renderComp = (
                <PassFailInput
                  component={comp}
                  isDisabled={false}
                />
              );
            } else if (comp.type === StepComponentTypes.Link) {
              renderComp = (
                <a
                  href={(comp as ILinkComponent).url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {comp.label}
                </a>
              )
            } else if (
              comp.type === StepComponentTypes.Table ||
              comp.type === StepComponentTypes.EnhancedTable
            ) {
              renderComp = (
                <TableComponent
                  component={comp}
                  stepGuid={step.guid}
                  swGuid={guid}
                  swVersion={version}
                />
              )
            } else if (comp.type === StepComponentTypes.PPE) {
              renderComp = (
                <PPEComponent
                  component={comp}
                />
              )
            } else if (comp.type === StepComponentTypes.Video) {
              renderComp = (
                <Video
                  component={comp}
                  stepGuid={step.guid}
                  swGuid={guid}
                  swVersion={version}
                />
              )
            } else if (comp.type === StepComponentTypes.Audio) {
              renderComp = (
                <Audio
                  component={comp}
                  stepGuid={step.guid}
                  swGuid={guid}
                  swVersion={version} />
              )
            }
            else if (comp.type === StepComponentTypes.RichTextParagraph) {
              renderComp = (
                <RichTextParagraph
                  component={comp}
                />
              )
            }

            if (!renderComp) {
              return null;
            }

            return (
              <div
                className="step-component"
                key={comp.id}
              >
                {renderComp}
              </div>
            )
          })}

          {step.isConditional &&
            <div className="step-component">
              <ToggleButtonInput
                options={["Yes", "No"]}
                onAnswerChosen={value => onConditionalResponseChange(value === "Yes")}
                disabled={false}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default StepBody;