import React from "react";
import "./SwRepoRow.scoped.scss";
import { ISwRepoSummaryItem } from "store/ManageSwReports/ManageSwReportsTypes";
import formatDate from "utilities/formatDate";
import pdfIcon from "media/icons/dls/pdf.svg";
import { useDispatch } from "react-redux";
import { downloadSwRepoPdf } from "store/ManageSwReports/manageSwReportsActions";

interface ISWRowProps {
    sw: ISwRepoSummaryItem,
}

const SwRepoRow: React.FC<ISWRowProps> = ({ sw }) => {
    const dispatch = useDispatch();
    const PdfClick = () => {
        dispatch(downloadSwRepoPdf({
            uid: sw.UID,
            version: sw.version.toString(),
            sourceSystem: sw.sourceSystem,
            isArchived: sw.isArchived
        }))
    }

    return (
        <div className="sw-header linkRow hover-gray-bg" >
            <div className="cell sw-name">
                <div className="title-holder">
                    <div className="sw-title">
                        {sw.title}
                        <span className="description">
                            {sw.description}
                        </span>
                    </div>
                </div>
            </div>
            <div className="cell execution-count">
                <div className="swcount">
                    {sw.UID}
                </div>
            </div>
            <div className="cell execution-count">
                <div className="swcount">
                    {sw.version}
                </div>
            </div>
            <div className="cell status">
                {formatDate(sw.publishedDate, false)}
            </div>
            <div className="cell buttons">
                <img src={pdfIcon} className="icon-medium hover-image" alt="Download PDF" onClick={() => PdfClick()} />
            </div>
        </div>
    );
};

export default SwRepoRow;