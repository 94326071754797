import React, { useState } from "react";
import "./Toolbar.scoped.scss";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import { ISW, RCTypes, SWTypes, TLMSWIType, TemplateTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { ICurrentApproval } from "store/approvals/approvalsTypes";
import { ApprovalLevels, ApprovalResponseTypes, ApprovalStatuses, ApprovalTypes, Status } from "interfaces/approvals/approvalInterfaces";
import { getPDF } from "store/swList/swListActions";
import RejectionModal from "./RejectionModal";
import useSelector from "store/useSelector";
import { Roles } from "interfaces/user/UserInterfaces";
import ApprovalModal from "./ApprovalModal";
import { showErrorPopupMessage, submitResponse } from "store/approvals/approvalsActions";
import Banner, { BannerType } from "components/common/Banner";
import ApprovalErrorModal from "./ApprovalErrorModal";
import { PdfTypes } from "../../../interfaces/misc/miscInterfaces";

interface IToolbarProps {
  sw: ISW,
  currentApproval: ICurrentApproval,
}

const Toolbar: React.FC<IToolbarProps> = ({ currentApproval, sw }) => {
  const {
    auth: {
      currentUser: {
        roles,
        email,
      }
    },
    manageSW: {
      commentData,
    }
    } = useSelector(store => store);
    let setErrorPopupMessage = useSelector(store => store.approvals.setErrorPopupMessage);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const dispatch = useDispatch();
  const [isRejectionOpen, setIsRejectionOpen] = useState<boolean>(false);
  const [isApprovalOpen, setIsApprovalOpen] = useState<boolean>(false);
  const [isRevertOpen, setIsRevertOpen] = useState<boolean>(false);
  const {
    sw: {
      createdBy,
    },
    approval: {
      currentLevel,
      createdBy: approvalCreatedBy,
      techContetApproval,
      techCommApproval,
      smeApproval
    },
    approvalHistories 
    } = currentApproval;

  // If the user is a SysAdmin they can approve anything.
  // If they are an author and their email matches they can approve.
  // If they have the appropriate approval level, they can approve.
  // If they are an OrgAdmin and it's waiting for anything but COE, they can approve.
  let canRespond = roles.indexOf(Roles.SysAdmin) > -1
    || (currentLevel === ApprovalLevels.Author
      && (email.toLowerCase() === createdBy.toLowerCase()
        || email.toLowerCase() === approvalCreatedBy.toLowerCase()))
    || (currentLevel === ApprovalLevels.TechContent
      && roles.indexOf(Roles.ApproverTechContent) > -1)
    || (currentLevel === ApprovalLevels.COE
      && roles.indexOf(Roles.ApproverCOE) > -1)
    || (currentLevel === ApprovalLevels.Owner
      && roles.indexOf(Roles.ApproverOwner) > -1)
    || (roles.indexOf(Roles.OrgAdmin) > -1
      && currentLevel !== ApprovalLevels.COE)
    || (roles.indexOf(Roles.TechConRev) > -1
      && currentLevel === ApprovalLevels.TechConRev)
    || (roles.indexOf(Roles.TechComm) > -1
      && currentLevel === ApprovalLevels.TechComm)
    || (roles.indexOf(Roles.SMEApprover) > -1
      && currentLevel === ApprovalLevels.SME)
    || (currentLevel === ApprovalLevels.LocalTechConApp
      && roles.indexOf(Roles.ApproverTechContent) > -1)
    || roles.indexOf(Roles.ProxyOwner) > -1;

  const showRejectionCommentModal = () => {
    setIsRejectionOpen(true);
  }

  const showApprovalCommentModal = () => {
    setIsApprovalOpen(true);
  }

  const showRevertCommentModal = () => {
    setIsRevertOpen(true);
  }

  const onDownloadPDFClick = (pdfType: string) => {
    dispatch(
      getPDF({
        swId: sw.id,
        pdfType
      })
    );
  }

  const onSubmitRejection = (approvalId: number,
    comment: string,
    attachment: File | null) => {
    dispatch(submitResponse({
      approvalRequestId: approvalId,
      comment: comment,
      responseType: ApprovalResponseTypes.Rejected,
      attachment,
      tecConRev: null,
      secondTecConRev: null,
      smeRevUser: null,
      swType: sw.type,
      techContetApproval: null,
      techCommApproval: null,
      smeApproval: null,
    }));
  }

  const onSubmitApproval = (approvalId: number,
    comment: string,
    tecConRev: string,
    secondTecConRev: string | null,
    smeRevUser: string | null,
    responseType: ApprovalResponseTypes,
    techContetApproval: Status | null, 
    techCommApproval: Status | null, 
    smeApproval: Status | null) => {
    dispatch(submitResponse({
      approvalRequestId: approvalId,
      comment: comment,
      responseType: responseType,
      attachment: null,
      tecConRev,
      secondTecConRev,
      smeRevUser,
      swType: sw.type,
      techContetApproval,
      techCommApproval,
      smeApproval
    }));
      setIsSubmitted(true);
  }

  const renderCommentBanner: boolean = FRenderCommentBanner();

  function FRenderCommentBanner(): boolean {
    let stepComments = sw.unResolvedStepComments
      ? sw.unResolvedStepComments > 0
        ? true
        : false
      : false;

    let swComments = commentData.comments
      ? commentData.comments.some(x => x.resolved === false)
        ? true
        : false
      : false;

    return (stepComments || swComments);
    }

    const onCloseErrPopUp = (isSubmitted: boolean) => {
        setIsSubmitted(isSubmitted);
        dispatch(showErrorPopupMessage({ errMsg: "", affectedSws:[] }))
    }

  return (
    <div className="toolbar">
      <div className="sw-header">
        <SWTypeIcon
          type={sw.type}
        />
        <div className="sw-title">
          <span>{sw.title}</span>
          {sw.description &&
            <span className="sw-description">
              {sw.description}
            </span>
          }
          {currentApproval.approval.status === ApprovalStatuses.Active &&
            <span
              className="awaiting"
            >
              Awaiting: <span>{currentApproval.approval.currentLevel}</span>
            </span>
          }
        </div>
      </div>
      {renderCommentBanner &&
        <Banner
          type={BannerType.Error}
        >
          You have &nbsp;
          {(sw.unResolvedStepComments
            ? sw.unResolvedStepComments
            : 0) +
            (commentData.comments
              ? commentData.comments.filter(x => x.resolved === false).length
              : 0)
          }
          &nbsp;out of&nbsp;
          {
            (sw.totalStepComments
              ? sw.totalStepComments
              : 0) +
            (commentData.comments
              ? commentData.comments.length
              : 0)
          }
          &nbsp;comments left to resolve
        </Banner>
      }
      {
        <button
          className="secondary-button"
          onClick= {() => 
            sw.type === SWTypes.SWI ?  onDownloadPDFClick(PdfTypes.Field) : 
            sw.type === SWTypes.TLMSWI ? onDownloadPDFClick(PdfTypes.Full) : 
            sw.type === SWTypes.MFGSWI ? onDownloadPDFClick(PdfTypes.FullTraining) :
              onDownloadPDFClick(PdfTypes.Normal)}
         >
                  {sw.type === SWTypes.SWI ? "Download Field PDF" : sw.type === SWTypes.TLMSWI ? "Full PDF" : sw.type === SWTypes.MFGSWI ? "Full Training PDF" : "Download PDF"}
       </button>
      }
      {(sw.type === SWTypes.SWI) &&
        <button
          className="secondary-button"
          onClick= { () => onDownloadPDFClick(PdfTypes.FullTraining) } 
        >
          Download Full Training PDF
        </button>
      }
      {(sw.type === SWTypes.TLMSWI)  &&
        <button
          className="secondary-button"
          onClick={() => 
          {sw.templateType ===  TemplateTypes.Consolidated ? 
            onDownloadPDFClick(PdfTypes.Consolidated) : 
            onDownloadPDFClick(PdfTypes.Standard)}}
        >
          {sw.templateType ===  TemplateTypes.Consolidated ? "Consolidated PDF" : "Standard PDF"}
        </button>
      }
      {(sw.type === SWTypes.TLMSWI) &&
        <button
          className="secondary-button"
          onClick={() => onDownloadPDFClick(PdfTypes.Executable)}
        >
          Executable PDF
        </button>
      }
      {(sw.type === SWTypes.MFGSWI) &&
         <button
           className="secondary-button"
           onClick={() => onDownloadPDFClick(PdfTypes.Reference)}
         >
           Reference PDF
         </button>
      }
      {((sw.type === SWTypes.TLMRC 
            && sw.rcType !== RCTypes.Notice) 
          || sw.type === SWTypes.TLMSWI 
          || (sw.type === SWTypes.MFGRC 
            && sw.rcType !== RCTypes.Notice)) &&
        <button
          className="secondary-button"
          onClick={() => onDownloadPDFClick(PdfTypes.CompetencyCPA)}
        >
          Competency - CPA
        </button>
      }
      {(sw.type === SWTypes.CL ||
        sw.type === SWTypes.LCL ||
        sw.type === SWTypes.SWI ||
        sw.type === SWTypes.MFGCL ||
        sw.type === SWTypes.MFGSWI) &&
        <button
          className="secondary-button"
          onClick={() => onDownloadPDFClick(PdfTypes.CCQ)}
        >
          CCQ PDF
        </button>
      }
      {(sw.type === SWTypes.TLMSWI && 
        currentLevel === ApprovalLevels.Owner) &&
        <button
          className="primary-button"
          disabled={sw.unResolvedStepComments
            && sw.unResolvedStepComments > 0
            ? true
            : false}
          onClick={showRevertCommentModal}
        >
          Revert
        </button>
      }
      <button
        className="primary-button"
        disabled={sw.unResolvedStepComments
          && sw.unResolvedStepComments > 0
          ? true
          : false}
        onClick={showApprovalCommentModal}
      >
        Approve
      </button>
      <button
        className="secondary-button"
        disabled={!canRespond}
        onClick={showRejectionCommentModal}
      >
        Reject
      </button>
      {isRejectionOpen &&
        <RejectionModal
          onClose={() => setIsRejectionOpen(false)}
          onSubmit={onSubmitRejection}
          approvalId={currentApproval.approval.id}
          allowAttachment={true}
        />
      }
      {isApprovalOpen &&
        <ApprovalModal
          onClose={() => setIsApprovalOpen(false)}
          approvalId={currentApproval.approval.id}
          onSubmit={onSubmitApproval}
          sw={sw}
          approvalLevel={currentLevel}
          techContetApproval={techContetApproval}
          techCommApproval={techCommApproval}
          smeApproval={smeApproval}
          approvalHistories = {approvalHistories}
          isRevertModal = {false}
        />
      }
      {isRevertOpen &&
        <ApprovalModal
          onClose={() => setIsRevertOpen(false)}
          approvalId={currentApproval.approval.id}
          onSubmit={onSubmitApproval}
          sw={sw}
          approvalLevel={currentLevel}
          techContetApproval={techContetApproval}
          techCommApproval={techCommApproval}
          smeApproval={smeApproval}
          approvalHistories = {approvalHistories}
          isRevertModal = {true}
        />
      }
      {setErrorPopupMessage.affectedSws.length >0 && isSubmitted &&
          <ApprovalErrorModal
              onCloseErrPopUp={onCloseErrPopUp}
              header="Error Message: "
              message={setErrorPopupMessage}
          />
      }
    </div>
  );
}

export default Toolbar;