import React, { useEffect } from "react";
import useSelector from "store/useSelector";
import "./ManageSWComments.scoped.scss";
import FlowLayout from "components/layout/FlowLayout";
import SWComment from "./SWComment";
import SWStepComment from "./SWStepComment";
import { loadAllSWComments } from "store/manageSW/manageSWActions";
import { IStep, RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";

interface IManageSWCommentsProps {
  swGuid: string,
  swVersion: number,
}

const ManageSWComments: React.FC<IManageSWCommentsProps> = ({ swGuid, swVersion }) => {
  const {
    manageSW: {
      commentData,
      SW: {
        steps,
        type,
        rcType,
      },
      stepCommentData,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  let hasComments = hasStepComments(steps);

  useEffect(() => {
    dispatch(loadAllSWComments({ swGuid, swVersion }));
  }, [dispatch, swGuid, swVersion]);

  function hasStepComments(steps: IStep[]): boolean | undefined {
    if (steps.length && stepCommentData.comments?.length) {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].id && stepCommentData.comments.filter(x => x.stepId === steps[i].id).length) {
          return true;
        }
        else {
          if (steps[i].children.length > 0) {
            if (hasStepComments(steps[i].children)) {
              return true
            }
          }
        }
      }
    }
    return false;
  }

  function findCommentType(): string {
    if (type === SWTypes.TLMRC) {
      if (rcType === RCTypes.SubStep) {
        return RCTypes.SubStep.toString();
      }
      else if (rcType === RCTypes.Notice) {
        return RCTypes.Notice.toString();
      }
      else if (steps.some(x => x.isTask)) {
        return RCTypes.Task.toString();
      }
      return RCTypes.Step.toString();
    }
    else {
      return "Step";
    }
  }
  
  return (
    <FlowLayout>
      <div className="Manage-SW-Comment">
        <div>
          {commentData.comments?.length !== 0 &&
            <label className="title">{"SW Comments"}</label>}
        </div>
        <div>
          {commentData.comments
            ?.map((entry, ix) => (
              <SWComment
                key={ix.toString()}
                {...entry}
              />)
            )
          }
        </div>
      </div>
      <br />
      <div className="Manage-SW-Comment">
        <div>
          {(hasComments) &&
            <label className="title">
              {"SW "}{type === SWTypes.TLMSWI || type === SWTypes.MFGSWI ? "Task" : findCommentType()}{" Comments"}
            </label>
          }
        </div>
        <div className="comment">
          {steps
            .map((entry, ix) => (
              <SWStepComment
                key={ix.toString()}
                {...entry}
              />
            ))
          }
        </div>
      </div>
    </FlowLayout>
  );

}

export default ManageSWComments;