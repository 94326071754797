import { IOperation } from "interfaces/operations/Operations";

export interface IManageSwReportsState {
    currentTab: ManageSwReportsTabs,
    swList: ISwRepoSummaryItem[],
    loadOperation?: IOperation,
}

export enum ManageSwReportsTabs {
    qtracPdf,
    FullPdf
}

export interface ISwRepoSummaryItem {
    id: number,
    UID: string,
    version: number,
    title: string,
    description: string,
    sourceSystem: string,
    isNewestVersion: boolean,
    publishedDate: Date | undefined,
    isArchived:boolean
}