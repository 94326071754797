import { BaseEditorComponent } from "@handsontable/react";
import {
  ContentState,
  convertToRaw,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import { RefObject } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import TableTimeImageComponent from "../../TableTimeImageComponent";

import {
  ITableTimeImageAttachment,
  ITimeImageData,
} from "store/manageSW/manageSWTypes";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import { addImageNumberToLabel } from "utilities/fileUtilities";
import { downloadTableTimeImage } from "store/manageSW/manageSWActions";

interface EditorComponentProps {
  hotTableRef: RefObject<any>;
  handleImageUpload: (url: string, file: File) => void;
  disabled: boolean;
  timeImageData: ITimeImageData;
  swType: SWTypes;
  swGuid: string;
  swVersion: number;
  componentGuid: string;
  stepGuid: string;
}

interface EditorComponentState {
  open: boolean;
  richTextEditorState: EditorState;
  cellData:
    | {
        row: number | undefined;
        col: number | undefined;
        prop: string;
        td: HTMLTableColElement | undefined;
        originalValue: any;
        cellProperties: any;
      }
    | undefined;
}

const createEmptyEditorState = () => EditorState.createEmpty();

class CustomCellEditorComponent extends BaseEditorComponent<
  EditorComponentProps,
  EditorComponentState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      richTextEditorState: createEmptyEditorState(),
      cellData: undefined,
    };
  }

  state: EditorComponentState;

  setValue(value: string) {
    const contentBlock = htmlToDraft(value);

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );

    const editorState = EditorState.createWithContent(contentState);
    const editorState2 = EditorState.moveFocusToEnd(editorState);
    this.updateEditorState(editorState2);
  }

  getValue() {
    return draftToHtml(
      convertToRaw(this.state.richTextEditorState.getCurrentContent())
    );
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  prepare(
    row: any,
    col: any,
    prop: any,
    td: any,
    originalValue: any,
    cellProperties: any
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);
    this.setState({
      cellData: { row, col, prop, td, originalValue, cellProperties },
    });
  }

  updateEditorState = (richTextEditorState: EditorState) => {
    this.setState({ richTextEditorState });
  };

  handleEditorBlur = () => {
    this.props.hotTableRef.current?.hotInstance?.deselectCell();
    this.props.hotTableRef.current?.hotInstance?.selectCell(
      this.state.cellData?.row,
      this.state.cellData?.col
    );
  };

  render() {
    return (
      <div>
        <EditorComponentInner
          props={this.props}
          state={this.state}
          updateEditorState={this.updateEditorState}
          onBlur={this.handleEditorBlur}
        />
      </div>
    );
  }
}

const EditorComponentInner: FC<{
  props: EditorComponentProps;
  state: EditorComponentState;
  updateEditorState: (richTextEditorState: EditorState) => void;
  onBlur?: () => void;
}> = ({ props, state, updateEditorState, onBlur }) => {
  const dispatch = useDispatch();
  const outerDivRef = React.useRef<HTMLDivElement>(null);

  useEffect(
    function resizeEditor() {
      if (
        outerDivRef &&
        outerDivRef.current &&
        state.cellData?.td &&
        state.open
      ) {
        const rect = state.cellData.td.getBoundingClientRect();
        outerDivRef.current.style.display = "block";
        outerDivRef.current.style.left = rect.left + "px";
        outerDivRef.current.style.top = rect.top + "px";
        outerDivRef.current.style.width = "auto";
        outerDivRef.current.style.height = "auto";
      }
    },
    [state.cellData?.td, outerDivRef, outerDivRef.current, state.open]
  );

  const stopMousedownPropagation: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const stopKeydownPropagation = (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (e.key === "Escape") {
      onBlur?.();
    }
  };

  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (state.open) {
      outerDivRef.current?.focus();
      editorRef.current?.focusEditor();
    }
  }, [state.open]);

  const [backspaceMessageShown, setBackspaceMessageShown] = useState(false);
  const [deleteMessageShown, setDeleteMessageShown] = useState(false);

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ): DraftHandleValue => {
    if (command === "backspace") {
      if (
        !backspaceMessageShown &&
        editorState
          .getCurrentContent()
          .getBlocksAsArray()
          .some((b) => b.getType() === "atomic")
      ) {
        dispatch(
          showErrorToast(
            "You may need to hit backspace multiple times to delete an image."
          )
        );
        setBackspaceMessageShown(true);
      }
    }

    if (command === "delete") {
      if (
        deleteMessageShown &&
        editorState
          .getCurrentContent()
          .getBlocksAsArray()
          .some((b) => b.getType() === "atomic")
      ) {
        dispatch(
          showErrorToast(
            "You may need to hit delete multiple times to delete an image."
          )
        );
        setDeleteMessageShown(true);
      }
    }

    return "not-handled";
  };

  const countImages = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.querySelectorAll("img").length;
  };

  const uploadDownloadTimeImage = (data: ITimeImageData) => {
    let cellAsHtml = draftToHtml(
      convertToRaw(state.richTextEditorState.getCurrentContent())
    );

    if (countImages(cellAsHtml) > 0) {
      const alreadyImageErr = "There is already an image.";
      dispatch(showErrorToast(alreadyImageErr));
      return;
    }

    const alt = addImageNumberToLabel(data.label, data.imageNumber).replace(
      ".tiff",
      ".png"
    );

    let contentAsHTML = `${cellAsHtml}
      <img src="" alt="${alt}"/>
    <p/>
    `;

    let tableTimeImageAttachment: ITableTimeImageAttachment = {
      swGuid: props.swGuid,
      swVersion: props.swVersion,
      label: data.label,
      link: data.link,
      imageNumber: data.imageNumber,
      componentGuid: props.componentGuid,
      stepGuid: props.stepGuid,
      rowIndex: state.cellData?.row ?? -1,
      colIndex: state.cellData?.col ?? -1,
      value: contentAsHTML,
      altTagValue: alt,
    };

    dispatch(downloadTableTimeImage(tableTimeImageAttachment));
    onBlur?.();
  };

  return (
    state.open && (
      <div
        style={{
          display: "none",
          position: "absolute",
          left: 0,
          top: 0,
          background: "#fff",
          border: "1px solid #000",
          padding: "15px",
          zIndex: 999,
        }}
        ref={outerDivRef}
        onMouseDown={stopMousedownPropagation}
        onKeyDown={stopKeydownPropagation}
      >
        <Editor
          toolbarClassName="rte-toolbar-class-table"
          editorClassName="rte-editor-class-table"
          editorState={state.richTextEditorState}
          onEditorStateChange={updateEditorState}
          ref={editorRef}
          handleKeyCommand={handleKeyCommand}
          toolbar={{
            options: [
              "inline",
              "list",
              "image",
              "link",
              "textAlign",
              "fontSize",
              "fontFamily",
            ],
            inline: {
              inDropdown: true,
              options: ["bold", "italic", "underline"],
            },
            list: {
              inDropdown: true,
            },
            image: {
              alignmentEnabled: false,
              previewImage: true,
              uploadEnabled: true,
              urlEnabled: false,
              uploadCallback: async (file: File) => {
                const url = URL.createObjectURL(file);
                props.handleImageUpload(url, file);

                return {
                  data: {
                    link: url,
                  },
                };
              },
              inputAccept:
                "image/gif, image/jpeg, image/jpg, image/png, image/svg+xml",
              defaultSize: {
                height: "160px",
                width: "208px",
              },
            },
            fontSize: {
              options: [
                6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
              ],
            },
            fontFamily: {
              options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana",
              ],
            },
            textAlign: {
              inDropdown: true,
              options: ["left", "center", "right", "justify"],
            },
          }}
          toolbarCustomButtons={[
            <TableTimeImageComponent
              allowEdit={!props.disabled}
              uploadDownloadTimeImage={uploadDownloadTimeImage}
              timeImageData={props.timeImageData}
              swType={props.swType}
            />,
          ]}
        />
      </div>
    )
  );
};

export default CustomCellEditorComponent;
