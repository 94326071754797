import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from "./components/layout/Layout";
import GlobalComponents from 'components/common/GlobalComponents';
import TCCModal from 'components/common/TCCModal';
import ProcessZipModal from 'components/common/ProcessZipModal';
import ProcessTimeRCModal from 'components/common/ProcessTimeRCModal';
import { TooltipContainer, TooltipArea } from "components/common/Tooltip";
import { ApprovalTooltipArea, ApprovalTooltipContainer } from 'components/approvals/history/ApprovalTooltip';
import 'handsontable/dist/handsontable.full.min.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { registerAllModules } from 'handsontable/registry';

registerAllModules();

const App: React.FC = () => {
  return (
    <TooltipArea>
      <ApprovalTooltipArea>
        <BrowserRouter>
          <Layout />
          <TCCModal />
          <GlobalComponents />
          <ProcessZipModal />
          <ProcessTimeRCModal />
        </BrowserRouter>
        <TooltipContainer />
        <ApprovalTooltipContainer />
      </ApprovalTooltipArea>
    </TooltipArea>
  );
};

export default App;
