import React, { useEffect, useMemo, useRef, useState } from "react";
import { INumberInputComponent, SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateNumberInput } from "store/manageSW/manageSWActions";
import "./NumberInputEditor.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import { loadMeters, loadUoms } from "store/masterData/masterDataActions";
import useSelector from "store/useSelector";

interface INumberInputEditorProps {
  numberInput: INumberInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
  swType: SWTypes,
  stepLocation?: string,
}

const NumberInputEditor: React.FC<INumberInputEditorProps> = ({
  numberInput,
  allowEdit,
  stepGuid,
  labelText,
  swType,
  stepLocation,
}) => {
  const dispatch = useDispatch();
  const nonConformInstructionRef = useRef<HTMLInputElement>(null);
  const isTLMType = (swType === SWTypes.TLMSWI
    || swType === SWTypes.TLMRC);
  const {
    masterData: {
      uoms,
      meters,
    },
    manageSW: {
      SW: {
        epicEquipment,
      },
      enableMetersValue,
    },
  } = useSelector(store => store);

  const [lowerLimit, setLowerLimit] = useState("");
  const [expectedValue, setExpectedValue] = useState("");
  const [upperLimit, setUpperLimit] = useState("");
  const [disableUOM, setDisabledUOM] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const {
    lowerLimit: reduxLowerLimit,
    expectedValue: reduxExpectedValue,
    upperLimit: reduxUpperLimit,
  } = numberInput;

  useEffect(() => {
    setLowerLimit(reduxLowerLimit !== undefined
      ? reduxLowerLimit.toString()
      : "");
  }, [setLowerLimit, reduxLowerLimit]);

  useEffect(() => {
    setExpectedValue(reduxExpectedValue !== undefined
      ? reduxExpectedValue.toString()
      : "");
  }, [setExpectedValue, reduxExpectedValue]);

  useEffect(() => {
    setUpperLimit(reduxUpperLimit !== undefined
      ? reduxUpperLimit.toString()
      : "");
  }, [setUpperLimit, reduxUpperLimit]);

  useEffect(() => {
    dispatch(loadUoms({ onlyActive: true }));
  }, []);

  useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
  }, [epicEquipment]);

  const filterMeters = stepLocation == undefined || stepLocation == null || stepLocation === "" || stepLocation === "1:Global" ? meters.items : meters.items.filter(t => t.parentGuid === stepLocation);

  const onLowerLimitBlur = () => {
    setErrorMsg(undefined);

    let value = isNaN(parseFloat(lowerLimit))
      ? undefined
      : parseFloat(lowerLimit);

    CheckAllNumberInputEmpty();

    if (value !== undefined) {
      if (reduxExpectedValue !== undefined
        && value >= reduxExpectedValue) {
        setErrorMsg("Lower limit must be less than expected value.");
        setLowerLimit(reduxLowerLimit !== undefined
          ? reduxLowerLimit.toString()
          : "");
        return;
      }
      if (reduxUpperLimit !== undefined
        && value >= reduxUpperLimit) {
        setErrorMsg("Lower limit must be less than upper limit.");
        setLowerLimit(reduxLowerLimit !== undefined
          ? reduxLowerLimit.toString()
          : "");
        return;
      }
    }

    dispatch(updateNumberInput({
      stepGuid,
      component: {
        ...numberInput,
        lowerLimit: value,
      },
    }));
  }

  const onExpectedValueBlur = () => {
    setErrorMsg(undefined);
    let value = isNaN(parseFloat(expectedValue))
      ? undefined
      : parseFloat(expectedValue);

    CheckAllNumberInputEmpty();

    if (value !== undefined) {
      if (reduxLowerLimit !== undefined
        && value <= reduxLowerLimit) {
        setErrorMsg("Expected value must be greater than lower limit.");
        setExpectedValue(reduxExpectedValue !== undefined
          ? reduxExpectedValue.toString()
          : "");
        return;
      }
      if (reduxUpperLimit !== undefined
        && value >= reduxUpperLimit) {
        setErrorMsg("Expected value must be less than upper limit.");
        setExpectedValue(reduxExpectedValue !== undefined
          ? reduxExpectedValue.toString()
          : "");
        return;
      }
    }

    dispatch(updateNumberInput({
      stepGuid,
      component: {
        ...numberInput,
        expectedValue: value,
      },
    }));
  }

  const onUpperLimitBlur = () => {
    setErrorMsg(undefined);

    let value = isNaN(parseFloat(upperLimit))
      ? undefined
      : parseFloat(upperLimit);

    CheckAllNumberInputEmpty();

    if (value !== undefined) {
      if (reduxLowerLimit !== undefined
        && reduxLowerLimit >= value) {
        setErrorMsg("Upper limit must be greater than lower limit.");
        setUpperLimit(reduxUpperLimit !== undefined
          ? reduxUpperLimit.toString()
          : "");
        return;
      }
      if (reduxExpectedValue !== undefined
        && reduxExpectedValue >= value) {
        setErrorMsg("Upper limit must be greater than expected value");
        setUpperLimit(reduxUpperLimit !== undefined
          ? reduxUpperLimit.toString()
          : "");
        return;
      }
    }

    dispatch(updateNumberInput({
      stepGuid,
      component: {
        ...numberInput,
        upperLimit: value,
      },
    }));
  }

  const renderMeter = () => {
    if ((enableMetersValue) && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) {
      return (
        <select
          className="meter"
          onChange={(e) => {
            const meterItem = meters.items.find(
              (m) => m.guid === e.target.value
            ) ?? {
              guid: e.target.value,
              value: e.target.value,
              active: true,
            };

            const uomItem = uoms.items.find(
              (u) => u.value === meterItem?.value
            );

            return dispatch(
              updateNumberInput({
                stepGuid,
                component: {
                  ...  numberInput,  
                  meterID: meterItem.guid,
                  meterCode: meterItem.code,
                  uom: uomItem?.value ?? numberInput.uom,
                  uomCode: uomItem?.code ?? numberInput.uomCode,
                },
              })
            );
          }}
          value={numberInput.meterID ?? ""}
        >
          <option value="" key=""></option>
          {filterMeters.map((t) => (
            <option key={t.guid + "-" + t.code} value={t.guid}>
              {t.code}
            </option>
          ))}
        </select>
      );
    }
  }

  const readOnlyUom: boolean = useMemo(() => {
    const meterItem = filterMeters.find(
      (m) => m.guid === numberInput.meterID
    );

    const uomItem = uoms.items.find((u) => u.value === meterItem?.value);

    return !!uomItem;
  }, [uoms.items, filterMeters, numberInput.meterID]);
  
  const renderUom = () => {
    if ((swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) {
      return (
        <select
          className="uom"
          onChange={(e) => {
            const uomItem = uoms.items.find(
              (u) => u.value === e.target.value
            ) ?? {
              code: "",
              guid: "",
              value: e.target.value,
              active: true,
            };

            return dispatch(
              updateNumberInput({
                stepGuid,
                component: {
                  ...numberInput,
                  uom: uomItem.value,
                  uomCode: uomItem.code ?? "",
                },
              })
            );
          }}
          value={numberInput.uom ?? ""}
          disabled={!allowEdit || (enableMetersValue && readOnlyUom)}
        >
          <option value="" />
          {uoms.items.map((t) => (
            <option key={t.guid} value={t.value}>
              {t.value}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type="text"
          className="uom"
          defaultValue={numberInput.uom ?? ""}
          onBlur={(e) => dispatch(updateNumberInput({
            stepGuid,
            component: {
              ...numberInput,
              uom: e.target.value,
            },
          }))}
        />
      );
    }
  }

  const shouldConformInstructionDisabled = () => {
    return isTLMType
      && isNullOrEmpty(expectedValue)
      && isNullOrEmpty(lowerLimit)
      && isNullOrEmpty(upperLimit);
  }

  const isNullOrEmpty = (str: string) => str == null
                        || str === "";

  useEffect(() => {
    const handleEmptyNumberValues = () => {
      if (isNullOrEmpty(reduxLowerLimit?.toString() ?? "")
          && isNullOrEmpty(reduxExpectedValue?.toString() ?? "")
          && isNullOrEmpty(reduxUpperLimit?.toString() ?? "")) {
            dispatch(updateNumberInput({
            stepGuid,
            component: {
              ...numberInput,
              nonConform:nonConformInstructionRef.current?.value
            },
          }));
        }
      }
    handleEmptyNumberValues();
  }, [lowerLimit, expectedValue, upperLimit, numberInput, stepGuid, dispatch]);

  const CheckAllNumberInputEmpty = () => {
    if (shouldConformInstructionDisabled()) {
      const nonConformInstruction = nonConformInstructionRef.current;
      if (nonConformInstruction) {
          nonConformInstruction.value = "";
        }
    }
  }

  const blockInvalidChar = (e: { key: string; preventDefault: () => any; }) => ['e', 'E', '+'].includes(e.key) && e.preventDefault();

  const setNumberParser = (e: string, field: string) => {
    let value = e;
    let parts = value.toString().split('.');
    let leftPart = '';
    let rightPart = '';

    leftPart = parts[0];
    if (parts[0].length > 8) {
      leftPart = parts[0].substring(0, 8);
    }

    value = leftPart;

    if(parts.length > 1) {
      rightPart = parts[1];
      if (parts[1].length > 4) {
        rightPart = parts[1].substring(0, 4);
      }

      value += "." + rightPart;
    }

    switch(field) {
      case "lowerLimit":
        setLowerLimit(value);
        break;
      case "expectedValue":
        setExpectedValue(value);
        break;
      case "upperLimit":
        setUpperLimit(value);
        break;
      default:
    }
  }

  return (
    <div className="editor">
      <div className="row">
        <label className="mandatory block">{labelText}</label>
        {((enableMetersValue) && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC)) &&
          <label className="meter">Meter</label>}
        <label className="uom">UOM</label>
      </div>
      <div className="row">
        {allowEdit &&
          <>
            <input
              type="text"
              defaultValue={numberInput.label}
              onBlur={(e) => dispatch(updateNumberInput({
                stepGuid,
                component: {
                  ...numberInput,
                  label: e.target.value,
                },
              }))}
            />
            {renderMeter()}
            {renderUom()}
          </>
        }
        {!allowEdit &&
          <>
            <span>{numberInput.label}</span>
            {(swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC) && (enableMetersValue) &&
              <span className="meter">{numberInput.meterCode}</span>}
            <span className="uom">{numberInput.uom}</span>
          </>
        }
      </div>
      <fieldset>
        {errorMsg &&
          <Banner
            type={BannerType.Error}
          >
            {errorMsg}
          </Banner>
        }
        <legend>Conformance Options (optional)</legend>
        <div className="row">
          <label className="lower-limit">Lower Limit</label>
          <label className="expected-value">Expected Value</label>
          <label className="upper-limit">Upper Limit</label>
        </div>
        <div className="row">
          {allowEdit &&
            <>
              <input
                type="number"
                className="lower-limit"
                value={lowerLimit}
                onKeyDown={blockInvalidChar}
                onChange={(e) => setNumberParser(e.target.value, "lowerLimit")}
                onBlur={onLowerLimitBlur}
              />
              <input
                type="number"
                className="expected-value"
                value={expectedValue}
                onKeyDown={blockInvalidChar}
                onChange={(e) => setNumberParser(e.target.value, "expectedValue")}
                onBlur={onExpectedValueBlur}
              />
              <input
                type="number"
                className="upper-limit"
                value={upperLimit}
                onKeyDown={blockInvalidChar}
                onChange={(e) => setNumberParser(e.target.value, "upperLimit")}
                onBlur={onUpperLimitBlur}
              />
            </>
          }
          {!allowEdit &&
            <>
              <span className="lower-limit">{numberInput.lowerLimit}</span>
              <span className="expected-value">{numberInput.expectedValue}</span>
              <span className="upper-limit">{numberInput.upperLimit}</span>
            </>
          }
        </div>
        <div className="row">
          <label>Non-Conformance Instructions</label>
        </div>
        <div className="row">
          {allowEdit &&
            <input
              ref={nonConformInstructionRef}
              type="text"
              defaultValue={numberInput.nonConform || ""}
              disabled={shouldConformInstructionDisabled()}
              onBlur={(e) => dispatch(updateNumberInput({
                stepGuid,
                component: {
                  ...numberInput,
                  nonConform: e.target.value,
                },
              }))}
            />
          }
          {!allowEdit &&
            <span>{numberInput.nonConform}</span>
          }
        </div>
      </fieldset>
    </div>
  );
}

export default NumberInputEditor;