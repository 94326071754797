import React, { FC, useEffect, useRef, useState } from "react";
import { HotTable } from "@handsontable/react";
import { CellChange } from "handsontable/common";
import { ITableEditorProps } from "./TableEditor";
import { ITableComponentCell } from "interfaces/sw/SWInterfaces";
import { DetailedSettings as GetMergedCellSettings } from "handsontable/plugins/mergeCells";
import { DetailedSettings as ContextMenuSettings } from "handsontable/plugins/contextMenu";
import { CellRange } from "handsontable";
import CustomCellEditorComponent from "./CustomCellEditorComponent";
import './TableEditor.scoped.scss';
import { useDispatch } from "react-redux";
import {
  getTableCellImageUrls,
  setTableLabel,
  updateTable,
  uploadTableImage,
} from "store/manageSW/manageSWActions";
import {
  ImageDataDestinations,
  ITableComponentImage,
} from "store/manageSW/manageSWTypes";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";

const EnhancedTableEditor: FC<ITableEditorProps> = ({
  tableComponent,
  allowEdit,
  stepGuid,
  swGuid,
  swVersion,
  imageDataDestination,
  swType,
}) => {
  const dispatch = useDispatch();
  const { timeImageData } = useSelector(store => store.manageSW);
  const hotTableRef = useRef<any>(null);

  const setCells = (newCells: ITableComponentCell[]) => {
    dispatch(
      updateTable({
        component: { ...tableComponent, cells: newCells },
        stepGuid,
      })
    );
  };
  // url to File records
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});

  const handleImageUpload = (url: string, file: File) => {
    setImageFiles({ ...imageFiles, [url]: file });
  };

  useEffect(() => {
    if (!hotTableRef.current) {
      return;
    }

    const newColCount: number = hotTableRef.current.hotInstance.countCols();
    const newRowcount: number = hotTableRef.current.hotInstance.countRows();

    if (
      newColCount !== tableComponent.colCount ||
      newRowcount !== tableComponent.rowCount
    ) {
      dispatch(
        updateTable({
          component: {
            ...tableComponent,
            rowCount: newRowcount,
            colCount: newColCount,
          },
          stepGuid,
        })
      );
    }
  }, [
    hotTableRef.current,
    hotTableRef.current?.hotInstance.countCols(),
    hotTableRef.current?.hotInstance.countRows(),
  ]);

  useEffect(() => {
    for (const cell of tableComponent.cells) {
      const imgSrc = getImage(cell);

      if (imgSrc) {
        dispatch(
          getTableCellImageUrls({
            stepGuid,
            componentGuid: tableComponent.guid,
            rowIndex: cell.rowIndex,
            colIndex: cell.colIndex,
            swGuid,
            swVersion,
            filename: imgSrc,
            destination: imageDataDestination,
          })
        );
      }
    }
  }, [tableComponent.cells]);

  const getMergedCells = (): GetMergedCellSettings[] => {
    return tableComponent.cells
      .filter((x) => x.colSpan > 1 || x.rowSpan > 1)
      .map((x) => {
        return {
          colspan: x.colSpan,
          rowspan: x.rowSpan,
          row: x.rowIndex,
          col: x.colIndex,
        };
      });
  };

  useEffect(() => {
    if (!hotTableRef.current) {
      return;
    }

    const hot = hotTableRef.current?.hotInstance;

    tableComponent.cells.forEach((x) => {
      hot.setSourceDataAtCell(x.rowIndex, x.colIndex, x.value);
    });

    setTimeout(() => {
      hot.render();
    }, 100);
  }, [tableComponent.cells, hotTableRef.current]);

  const handleCellChanges = (changes: CellChange[] | null) => {
    if (!changes) {
      return;
    }

    let anyChanges = false;

    const newCells = tableComponent.cells.map((x) => ({ ...x }));

    const imageUploads: ITableComponentImage[] = [];

    changes.forEach(([row, col, oldValue, newValue]) => {
      if (oldValue !== newValue) {
        anyChanges = true;
      }

      let cell = newCells.find((x) => x.rowIndex === row && x.colIndex === col);

      if (cell) {
        cell.value = newValue;
      } else if (typeof row === "number" && typeof col === "number") {
        cell = {
          rowIndex: row,
          colIndex: col,
          value: newValue,
          colSpan: 1,
          rowSpan: 1,
        };
        newCells.push(cell);
      }

      if (!cell) {
        return;
      }

      const div = document.createElement("div");
      div.innerHTML = newValue;

      const imgTags = div.getElementsByTagName("img");
      // if more than one image tag, remove all but the first

      cell.value = newValue;
      for (let i = 1; i < imgTags.length; i++) {
        imgTags[i].remove();

        dispatch(showErrorToast("Only one image per cell is allowed"));
        cell.value = div.innerHTML;
      }

      //cell.value = div.innerHTML;

      const imgTag = div.getElementsByTagName("img")[0];

      if (imgTag) {
        const imgSrc = imgTag.getAttribute("src");

        if (imgSrc) {
          const file = imageFiles[imgSrc];

          if (file) {
            cell.imageFileName = file.name;

            imageUploads.push({
              swGuid,
              stepGuid,
              componentGuid: tableComponent.guid,
              rowIndex: row,
              colIndex: col as number,
              imageObject: {
                file,
                localName: file.name,
              },
              swVersion,
            });
          }
        }
      }
    });

    if (anyChanges) {
      setCells(newCells);

      imageUploads.forEach((x) => {
        dispatch(uploadTableImage(x));
      });
    }
  };

  const contextMenuSettings: ContextMenuSettings = {
    items: {
      row_above: {
        disabled: !allowEdit,
      },
      row_below: {
        disabled: !allowEdit,
      },
      col_left: {
        disabled: !allowEdit,
      },
      col_right: {
        disabled: !allowEdit,
      },
      remove_row: {
        disabled: !allowEdit,
      },
      remove_col: {
        disabled: !allowEdit,
      },
      cut: {
        disabled: !allowEdit,
      },
      copy: {
        disabled: !allowEdit,
      },
      mergeCells: {
        disabled: !allowEdit,
      },
    },
  };

  const afterMergeCells = ({ to, from, highlight }: CellRange) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));

    const highlightCell = newCells.find(
      (x) => x.rowIndex === highlight.row && x.colIndex === highlight.col
    );

    if (!highlightCell) {
      return;
    }

    newCells = newCells.filter(
      (x) =>
        x.rowIndex < from.row ||
        x.rowIndex > to.row ||
        x.colIndex < from.col ||
        x.colIndex > to.col
    );

    const expectedRowSpan = to.row - from.row + 1;
    const expectedColSpan = to.col - from.col + 1;

    if (
      highlightCell.rowSpan === expectedRowSpan &&
      highlightCell.colSpan === expectedColSpan
    ) {
      return;
    }

    highlightCell.rowSpan = expectedRowSpan;
    highlightCell.colSpan = expectedColSpan;
    highlightCell.rowIndex = from.row;
    highlightCell.colIndex = from.col;
    newCells.push(highlightCell);
    setCells(newCells);
  };

  const afterUnmergeCells = ({ from }: CellRange) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));
    const firstCell = newCells.find(
      (x) => x.rowIndex === from.row && x.colIndex === from.col
    );

    if (!firstCell) {
      return;
    }

    firstCell.rowSpan = 1;
    firstCell.colSpan = 1;
    setCells(newCells);
  };

  const afterCreateCol = (index: number, amount: number) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));

    newCells = newCells.map((x) => {
      if (x.colIndex >= index) {
        x.colIndex += amount;
      }
      return x;
    });

    setCells(newCells);
  };

  const afterCreateRow = (index: number, amount: number) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));

    newCells = newCells.map((x) => {
      if (x.rowIndex >= index) {
        x.rowIndex += amount;
      }
      return x;
    });

    setCells(newCells);
  };

  const afterRemoveCol = (index: number, amount: number) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));

    newCells = newCells.filter(
      (x) => x.colIndex < index || x.colIndex >= index + amount
    );

    newCells = newCells.map((x) => {
      if (x.colIndex > index) {
        x.colIndex -= amount;
      }
      return x;
    });

    setCells(newCells);
  };

  const afterRemoveRow = (index: number, amount: number) => {
    let newCells = tableComponent.cells.map((x) => ({ ...x }));

    newCells = newCells.filter(
      (x) => x.rowIndex < index || x.rowIndex >= index + amount
    );

    newCells = newCells.map((x) => {
      if (x.rowIndex > index) {
        x.rowIndex -= amount;
      }
      return x;
    });

    setCells(newCells);
  };

  let onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTableLabel({
      stepGuid,
      componenGuid: tableComponent.guid,
      label: e.target.value,
    }))
  }

  return (
    <div>
      <label className="controlLabel">
            Label
          </label>
          <input
            type="text"
            className="controlWide"
            value={tableComponent.label}
            onChange={(e) => onLabelChange(e)}
          />
      <HotTable
        ref={hotTableRef}
        colHeaders={() => ""}
        rowHeaders={() => ""}
        height="auto"
        width={"100%"}
        licenseKey="296a6-d038f-e81a3-f432c-ae730"
        renderer={"html"}
        afterChange={handleCellChanges}
        mergeCells={getMergedCells()}
        contextMenu={contextMenuSettings}
        afterMergeCells={afterMergeCells}
        afterUnmergeCells={afterUnmergeCells}
        afterCreateCol={afterCreateCol}
        afterCreateRow={afterCreateRow}
        afterRemoveCol={afterRemoveCol}
        afterRemoveRow={afterRemoveRow}
        minRows={1}
        minCols={1}
        maxRows={40}
        maxCols={10}
        manualColumnResize={allowEdit}
        manualRowResize={allowEdit}
        startCols={tableComponent.colCount}
        startRows={tableComponent.rowCount}
        readOnly={!allowEdit}
      >
        <CustomCellEditorComponent
          hot-editor={true}
          hotTableRef={hotTableRef}
          handleImageUpload={handleImageUpload}
          disabled={!allowEdit}
          timeImageData={timeImageData}
          swType={swType}
          swGuid={swGuid}
          swVersion={swVersion}
          componentGuid={tableComponent.guid}
          stepGuid={stepGuid}
        />
      </HotTable>
    </div>
  );
};

export default EnhancedTableEditor;

const getImage = (cell: ITableComponentCell): string | undefined => {
  if (
    cell.imageFileName &&
    cell.imageFileName.includes("blob:https://")
  ) {
    return undefined;
  }

  const div = document.createElement("div");
  div.innerHTML = cell.value;
  const imgTag = div.getElementsByTagName("img")[0];

  if (imgTag && imgTag.getAttribute("alt") !== "undefined") {
    return imgTag.getAttribute("alt") || undefined;
  }

  return undefined;
};
