import { IStep } from "interfaces/sw/SWInterfaces";
import React from "react";
import useSelector from "store/useSelector";
import formatDate from "utilities/formatDate";
import "./SWStepComment.scoped.scss";
import SWStepCommentStatusIndicator from "./SWStepCommentStatusIndicator";

const SWStepComment: React.FC<IStep> = (step) => {
  const {
    manageSW: {
      stepCommentData,
    },
  } = useSelector(store => store);
  const comments = stepCommentData.comments?.filter(x => x.stepId === step.id);
  let hasComments = comments === undefined ? false : comments.length > 0 ? true : false;
  let hasSubComments = hasStepComments(step.children);

  function hasStepComments(steps: IStep[]): boolean | undefined {
    for (let i = 0; i < steps.length; i++) {
      const comments = stepCommentData.comments?.filter(x => x.stepId === steps[i].id);
      if (comments !== undefined && comments.length > 0) {
        return true;
      }
      else {
        if (steps[i].children.length > 0) {
          if (hasStepComments(steps[i].children)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  return (
    <div className="SW-StepComment">
      {(hasComments || hasSubComments) &&
        <div>
          <ul>
            <li>
              {<label className="title">{step.title}</label>}
              <br />
              {(hasComments) &&
                comments?.map((stepComment, keyx) => (
                  <div key={keyx.toString()}>
                    {stepComment.comment.split(/(@\[.+?\])/g)
                      .map((part) => (
                        part.indexOf("@[") > -1
                          ? <b>{part.substring(2, part.lastIndexOf("]"))}</b>
                          : part
                      )) 
                    }
                    <span>{" - " + formatDate(stepComment.createdOn, true)}</span>
                    {
                      <SWStepCommentStatusIndicator
                        resolved={stepComment.resolved}
                        showText={true}
                      />
                    }
                    <br />
                  </div>
                )
                )}
              {(hasSubComments) &&
                step.children
                  .map((entry, ix) => (
                    <SWStepComment
                      key={ix.toString()}
                      {...entry}
                    />
                  ))
              }
            </li>
          </ul>
        </div>
      }
    </div>
  );
}

export default SWStepComment;