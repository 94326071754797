import React, { useState } from "react";
import { IRefDocTableComponent, IReferenceDoc } from "interfaces/sw/SWInterfaces";
import "./RefDocs.scoped.scss";
import downloadIcon from "media/icons/dls/download.svg";
import viewIcon from "media/icons/dls/viewer.svg";
import SWApi from "apis/sw/SWApi";
import { showErrorToast } from "store/toast/toastActions";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import ModalSpinner from "components/common/ModalSpinner";
import { useDispatch } from "react-redux";
import RefDocTableCell from "components/sw/manage/refDocs/RefDocTableCell";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

interface IRefDocsProps {
  refDocs: IReferenceDoc[],
  swGuid: string,
  version: number,
}

const RefDocs: React.FC<IRefDocsProps> = ({ refDocs, swGuid, version }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const downloadRefDoc = async (filename: string) => {
    try {
      setIsLoading(true);
      const blobUri = await SWApi.getSWBlobURL(swGuid, version, filename);
      const link = document.createElement("a");
      link.href = blobUri;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Clean up the object URL
      URL.revokeObjectURL(blobUri);
    } catch (err: any) {
      dispatch(showErrorToast(`Failed to download file: ${getResponseErrorMessage(err)}`));
    } finally {
      setIsLoading(false);
    }
  }

  const renderTable = (tableData: IRefDocTableComponent, refDocGuid: string) => {
    //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
    let cellArray = new Array(tableData.rowCount);
    for (var r = 0; r < cellArray.length; r++) {
      cellArray[r] = new Array(tableData.colCount);
      for (var c = 0; c < cellArray[r].length; c++) {
        let cIx = c;
        let rIx = r;
        
        let cell = tableData.cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
        if (cell !== undefined) {
          cellArray[rIx][cIx] = {colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false};
        } else {
          cellArray[rIx][cIx] = {colSpan: 1, rowSpan: 1, deleted: false};
        }
      }
    }

    // figure out which cells should be deleted to handle col spans and row spans
    for (var ra = 0; ra < tableData.rowCount; ra++) {
      for (var ca = 0; ca < tableData.colCount; ca++) {
        let cell = cellArray[ra][ca];
        if (cell.deleted) {
          continue;
        }

        if (cell.colSpan > 1 && cell.rowSpan > 1) {
          // disable columns and rows??
          for (var i = ca + 1; i < ca + cell.colSpan; i++) {
            let cellToDisable = cellArray[ra][i];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
          for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
            let cellToDisable = cellArray[j][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }

            for (var z = 1; z < cell.colSpan; z++) {
              let cellToDisable = cellArray[j][ca + z];
              if (cellToDisable) {
                cellToDisable.deleted = true;
                cellToDisable.rowSpan = 1;
                cellToDisable.colSpan = 1;
              }
            }
          }
        } else if (cell.colSpan > 1) {
          // disable columns
          for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
            let cellToDisable = cellArray[ra][ii];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        } else if (cell.rowSpan > 1) {
          // disable rows
          for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
            let cellToDisable = cellArray[jj][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      }
    }

    const colWidth = 100/ tableData.colCount;
    return (
      <table>
        <colgroup>
          {
            [...new Array(tableData.colCount)].map((_, cIx) => {
              return <col key={cIx} style={{width: colWidth + '%'}}/>
            })
          }
        </colgroup>
        <tbody>
        {[...new Array(tableData.rowCount)].map((_, ixr) => (
          <tr key={"r" + ixr}>
            {
              [...new Array(tableData.colCount)].map((_, ixc) => {
                let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                if (!cellFormatter.deleted) {
                  let cell = tableData.cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                  let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                  let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;
  
                  return (
                    <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                      <RefDocTableCell
                          rowKey={ixr}
                          colKey={ixc}
                          refDocGuid={refDocGuid}
                          tableComponent={tableData}
                          disabled={true}
                          swGuid={swGuid}
                          swVersion={version}
                          imageDataDestination={ImageDataDestinations.Approval}
                        />
                    </td>);
                } else {
                  return undefined;
                }
              })
            }
          </tr>
        ))}
        </tbody>
      </table>
    );
  }

  return (
    <>
      {refDocs
        .map(refDoc => {
          if (refDoc.type === 'File')
            return(
              <div
                className="row"
                key={refDoc.guid}
              >
                <label>{refDoc.label}</label>
                <span className="value">
                  {refDoc.refDocData}
                </span>
                <span className="downloadSpan">
                  <button
                    className="secondary-button download-button"
                    onClick={() => downloadRefDoc(refDoc.refDocData)}
                    title="Download"
                  >
                    <img
                      src={downloadIcon}
                      className="icon-small"
                      alt="Download"
                    />
                  </button>
                </span>
              </div>
            )
          else if (refDoc.type === 'Link')
            return (
              <div
                className="row"
                key={refDoc.guid}
              >
                <span>
                  <a
                    href={refDoc.refDocData}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {refDoc.label}
                  </a>
                </span>
              </div>
            )
          else if (refDoc.tableData)
            return (
              <div
                className="tableRow"
                key={refDoc.guid}
              >
                {renderTable(refDoc.tableData, refDoc.guid)}
              </div>
            )
          else
            return undefined;
        })
      }
      {isLoading &&
        <ModalSpinner />
      }
    </>
  )
}

export default RefDocs;