import React from "react";
import "./ApprovalRow.scoped.scss";
import { ApprovalTypes, IApprovalRequest, IBatchApprovalRequest, IRCUpdateApprovalRequest } from "interfaces/approvals/approvalInterfaces";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import formatDate from "utilities/formatDate";
import { SWTypes } from "interfaces/sw/SWInterfaces";

interface IApprovalRowProps {
  approval: (IApprovalRequest | IBatchApprovalRequest | IRCUpdateApprovalRequest),
}

const ApprovalRow: React.FC<IApprovalRowProps> = ({ approval }) => {
  let route = Routes.ViewApproval.replace(":id", approval.id.toString());
  if (approval.type === ApprovalTypes.Batch) {
    route = Routes.ViewBatchApproval.replace(":id", approval.id.toString());
  } else if (approval.type === ApprovalTypes.RCUpdate) {
    route = Routes.ViewRCUpdateApproval.replace(":id", approval.id.toString());
  }

  return (
    <Link
      className="approval row hover-gray-bg"
      to={route}
    >
      <div className="cell sw">
        <div className="sw-holder">
          {approval.type === ApprovalTypes.SW &&
            <>
              <SWTypeIcon
                type={approval.swType}
                isRCUpdate={approval.isRCUpdate}
              />
              <span>
                <span>{approval.swTitle}</span><br />
                {approval.swDescription && <span className="sw-desc">{approval.swDescription}</span>}
              </span>
            </>
          }
          {approval.type === ApprovalTypes.Batch &&
            <span>
              Batch update with {approval.swCount} Standard Work{approval.swCount !== 1 ? "s" : ""}
            </span>
          }
          {approval.type === ApprovalTypes.RCUpdate &&
            <>
              <SWTypeIcon
                type={approval.swType}
                isRCUpdate={true}
              />
              <span>
                {approval.rcId ?
                  <>RC update for content: {approval.rcName}</> : <>RC update for TIME Media ID: {approval.mediaId}</>
                }
              </span>
            </>
          }
        </div>
      </div>
      {approval.type === ApprovalTypes.RCUpdate &&
        <>
          <div className="cell status">
            {approval.numberPending} Approval{approval.numberPending !== 1 ? "s" : ""} Pending {approval.currentLevel}<br />
            {approval.swType === SWTypes.MFGRC ? "Plant" : "Org"}: {approval.owningOrgCode}{approval.swType !== SWTypes.MFGRC ? ", PC: " : ""}{approval.productCenters}<br />
            Last Updated: {formatDate(approval.modifiedOn, false)}<br />
            by {approval.modifiedBy}
          </div>
          <div className="cell org">
            {approval.owningOrgCode} - {approval.owningOrgName}
          </div>
        </>
      }
      {approval.type !== ApprovalTypes.RCUpdate &&
        <>
          <div className="cell status">
            Pending {approval.currentLevel} Approval,<br />
            Last Updated: {formatDate(approval.modifiedOn, false)}<br />
            by {approval.modifiedBy}
          </div>
          <div className="cell org">
            {
              approval.type === ApprovalTypes.SW &&
                (approval.swType === SWTypes.MFGSWI ||
                  approval.swType === SWTypes.MFGCL ||
                  approval.swType === SWTypes.MFGRC) ?
                `${approval.swOwningPlantCode} - ${approval.swOwningPlantName}` :
                `${approval.swOwningOrgCode} - ${approval.swOwningOrgName}`
            }
          </div>
        </>
      }
    </Link>);
}
export default ApprovalRow;