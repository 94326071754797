import React, { useEffect, useState } from "react";
import { NoticeTypes, INoticeComponent, SWTypes, ImageType, RCTypes, ISW } from "interfaces/sw/SWInterfaces";
import "./NoticeEditor.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import WarningIcon from "media/icons/dls/warning.svg";
import ErrorIcon from "media/icons/dls/error.svg";
import InfoIcon from "media/icons/dls/info.svg";
import { useDispatch } from "react-redux";
import { setNoticeVisibility, updateNotice } from "store/manageSW/manageSWActions";
import EditIcon from "media/icons/dls/edit.svg";
import Modal from "components/common/Modal";
import NoticeImageEditor from "./NoticeImageEditor";
import { ImageDataDestinations, INoticeVisibility } from "store/manageSW/manageSWTypes";
import NoticeTimeImageEditor from "./NoticeTimeImageEditor";
import RCNoticeEditor from "../notices/rcNotice/RCNoticeEditor";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./ComponentEditor.scss";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import { loadHazardCategories, loadPotentialLoss } from "store/masterData/masterDataActions";
import {
    setNoticeLevelHazardCategory,
    setNoticeLevelPotentialLoss,
    removeNoticeLevelPotentialLoss
} from "store/manageSW/manageSWActions";
import NoticeRichTextEditor, {
  RichTextNoticeSWTypes,
} from "./NoticeRichTextEditor";

import useSelector from "store/useSelector";
import getStringBetween from "utilities/stringUtilities";

interface INoticeProps {
  allowEdit?: boolean,
  notice: INoticeComponent,
  stepGuid?: string,
  swGuid: string,
  swVersion: number,
  swType: SWTypes,
  imageDataDestination: ImageDataDestinations,
  hideRCNoticeControls?: boolean,
  noticeVisibilities?: INoticeVisibility[],
  isDraftPage?: boolean,
  isVisibilityExternal?: boolean,
  sw?: ISW
}

const Notice: React.FC<INoticeProps> = ({
  allowEdit,
  notice,
  stepGuid,
  swGuid,
  swVersion,
  swType,
  imageDataDestination,
  hideRCNoticeControls,
  noticeVisibilities,
  isVisibilityExternal,
  isDraftPage,
  sw, }) => {
  const dispatch = useDispatch();
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [NoticeLabel, setNoticeLabel] = useState<string>("");
  const {
    rcType
  } = useSelector(store => store.manageSW.SW);

  const {
    masterData: { hazardCategories, potentialLosses },
  } = useSelector(store => store);

  useEffect(() => {
    dispatch(loadHazardCategories({ onlyActive: true }))
  }, [])
  useEffect(() => {
    if (notice.label) {
      setNoticeLabel(notice.label);
    }
  }, [notice.label]);

  const allowRichTextLinksNotices = RichTextNoticeSWTypes.includes(swType);
  const onTypeChange = (noticeType: NoticeTypes) => {
    if (noticeType !== notice.noticeType) {
      dispatch(updateNotice({
        stepGuid,
        component: {
          ...notice,
          noticeType,
        }
      }));
    }

    setIsTypeModalOpen(false);
  }

  const onLabelChange = (label: string) => {
    label = filterNonASCIIWhitespace(label);
    if (label !== notice.label) {
      dispatch(updateNotice({
        stepGuid,
        component: {
          ...notice,
          label,
        }
      }));
    }
  }

  const filterNonASCIIWhitespace = (text: string) => {
    const charArray = [];

    for (const char of text) {
      const isASCII = char.charCodeAt(0) <= 127;
      const isWhitespace = /\s/.test(char);

      if (isWhitespace && !isASCII) {
        charArray.push(" ");
      } else {
        charArray.push(char);
      }
    }

    return charArray.join("");
  };

  let noticeTextArr: (string | JSX.Element)[] = [];
  let label = notice.label;

  if (label) {
    const reg = /<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g;
    let match;
    let cursorIx = 0;

    while ((match = reg.exec(label))) {
      if (cursorIx !== match.index) {
        noticeTextArr.push(
          label.substr(cursorIx, match.index - cursorIx)
            .replace(/ /g, " ")
        );
      }

      noticeTextArr.push(
        <a href={match[1]}>{match[2].replace(/ /g, " ")}</a>
      );

      cursorIx = match.index + match[0].length;
    }

    if (cursorIx < label.length) {
      noticeTextArr.push(
        label.substr(cursorIx)
          .replace(/ /g, " ")
      );
    }
  }

  let noticeImage: JSX.Element | undefined = <NoticeImageEditor
    notice={notice}
    stepGuid={stepGuid}
    allowEdit={notice.image.imageType != ImageType.Time && allowEdit}
    swGuid={swGuid}
    swVersion={swVersion}
    imageDataDestination={imageDataDestination}
  />

  let noticeTimeImage: JSX.Element | undefined = <NoticeTimeImageEditor
    notice={notice}
    stepGuid={stepGuid}
    allowEdit={notice.image.imageType != ImageType.Normal && allowEdit}
    swGuid={swGuid}
    swVersion={swVersion}
    imageDataDestination={imageDataDestination}
  />

  let noticeComp: JSX.Element | undefined;
  let isTLMType = swType === SWTypes.TLMRC
    || swType === SWTypes.TLMSWI

  const renderComponentLevelHazardCategories = () => {
    return (
      <MasterDataInput
        label="Hazard Category"
        selectedItems={notice?.hazardCategory?.guid ? [notice?.hazardCategory] : []}
        masterDataSection={hazardCategories}
        loadItems={() => dispatch(loadHazardCategories({ onlyActive: true }))}
        onAddItem={(item) => dispatch(setNoticeLevelHazardCategory({ hazardCategory: item, noticeGuid: notice.guid, stepGuid: stepGuid }))}
        onRemoveItem={() => dispatch(setNoticeLevelHazardCategory({ hazardCategory: undefined, noticeGuid: notice.guid, stepGuid: stepGuid }))}
        itemFormatter={(item) => item.value}
        allowMultiple={false}
        isMandatory={
          sw?.type === SWTypes.TLMRC && sw?.notices.length > 0
            ? "submitOnly"
            : false
        }
        disabled={!allowEdit}
      />
    );
  }

  const renderHazardCategories = () => {
    return (
      <MasterDataInput
        label="Hazard Category"
        selectedItems={notice?.hazardCategory?.guid ? [notice?.hazardCategory] : []}
        masterDataSection={hazardCategories}
        loadItems={() => dispatch(loadHazardCategories({ onlyActive: true }))}
        onAddItem={(item) => dispatch(setNoticeLevelHazardCategory({ hazardCategory: item, noticeGuid: notice.guid, stepGuid: undefined }))}
        onRemoveItem={() => dispatch(setNoticeLevelHazardCategory({ hazardCategory: undefined, noticeGuid: notice.guid, stepGuid: undefined }))}
        itemFormatter={(item) => item.value}
        allowMultiple={false}
        isMandatory={
          sw?.type === SWTypes.TLMRC && sw?.notices.length > 0
            ? "submitOnly"
            : false
        }
        disabled={!allowEdit}
      />
    );
  };

    const renderPotentialLossData = (noticeCompStepGuid: string | undefined) => {
      return (
          <MasterDataInput
              label="Potential Loss"
              selectedItems={notice?.potentialLoss ? notice?.potentialLoss : []}
              masterDataSection={potentialLosses}
              loadItems={() => dispatch(loadPotentialLoss({ onlyActive: true }))}
              onAddItem={(item) => dispatch(setNoticeLevelPotentialLoss({ potentialLoss: item, noticeGuid: notice.guid, stepGuid: noticeCompStepGuid }))}
              onRemoveItem={(item) => dispatch(removeNoticeLevelPotentialLoss({ potentialLoss: item, noticeGuid: notice.guid, stepGuid: noticeCompStepGuid }))}
              itemFormatter={(item) => item.value}
              allowMultiple={true}
              isMandatory={false}
              disabled={!allowEdit}
          />
      );
  }

  if (rcType === RCTypes.Notice) {
    noticeComp = (
      <>
        {noticeImage}
        {(isTLMType)
          && noticeTimeImage}
      </>
    )
  }
  else {
    if (notice.image.imageType === ImageType.Both) {
      noticeComp = (
        <>
          {noticeImage}
          {isTLMType
            && noticeTimeImage}
        </>
      )
    }
    else if (notice.image.imageType === ImageType.Time) {
      noticeComp = (
        <>
          {isTLMType
            && noticeTimeImage}
        </>
      )
    }
    else if (notice.image.imageType === ImageType.Normal) {
      noticeComp = (
        <>
          {noticeImage}
        </>
      )
    }
  }

  const onSetNoticeVisibility = (noticeGuid: string, isOpen: boolean) => {
    dispatch(setNoticeVisibility({
      noticeGuid,
      isOpen,
    }));
  }
  const allowHazardCategory = () => {
    const disallowedTypes = [SWTypes.CL, SWTypes.SWI, SWTypes.ECL, SWTypes.LCL];
    return !disallowedTypes.includes(swType) && (allowEdit ? true : notice?.hazardCategory?.guid);
  };

  const allowPotentialLoss = () => {
      const isVisible = allowEdit ? true : (notice?.potentialLoss.length > 0) ? true : false;
      return isVisible;
  };
  return (
    <>
      {(notice.isRCComponent && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI || swType === SWTypes.MFGRC) && !hideRCNoticeControls) ?
        <RCNoticeEditor
          allowEdit={false}
          hideControls={!allowEdit!}
          notice={notice}
          stepGuid={stepGuid}
          swGuid={swGuid}
          swVersion={swVersion}
          imageDataDestination={imageDataDestination}
          swType={swType}
          isVisibilityExternal={isVisibilityExternal}
          noticeVisibilities={noticeVisibilities}
          setNoticeVisibility={onSetNoticeVisibility}
          isDraftPage={isDraftPage}
        />
        :
        <>
          <Banner type={getBannerType(notice.noticeType)}>
            <div className="inner">
              {allowEdit &&
                <>
                  <button
                    className={`type-button ${notice.noticeType}`}
                    onClick={() => setIsTypeModalOpen(true)}
                    title="Change Type"
                  >
                    {getIcon(notice.noticeType)}
                    <img
                      src={EditIcon}
                      className={`icon-small edit-button ${notice.noticeType}`}
                      alt="Change Type"
                    />
                  </button>
                  {allowRichTextLinksNotices ?
                    <NoticeRichTextEditor
                      html={notice.label}
                      type={notice.noticeType}
                      allowEdit={allowEdit}
                      onLabelChange={(lable: string) => onLabelChange(lable)} /> :
                    <textarea
                      onChange={e => setNoticeLabel(e.target.value)}
                      value={NoticeLabel}
                      className="text"
                      onBlur={(e) => onLabelChange(e.target.value)}
                    />
                  }
                </>
              }
              {!allowEdit &&
                <>
                  {getIcon(notice.noticeType)}
                  {allowRichTextLinksNotices ?
                    <NoticeRichTextEditor
                      html={notice.label}
                      type={notice.noticeType}
                      allowEdit={allowEdit}
                      onLabelChange={(lable: string) => onLabelChange(lable)} /> :
                    <div className="text">
                      {noticeTextArr.map((sect, ix) => (
                        <React.Fragment key={ix}>{getStringBetween((sect as string), "<p>", "</p>")}</React.Fragment>
                      ))}
                    </div>
                  }
                </>
              }
            </div>
            {swType == SWTypes.MFGCL &&
              <div className="inner">
                {stepGuid === undefined
                  && notice.noticeType !== NoticeTypes.Info &&
                  <>
                    <div className="notice-hazard-categories controls">
                      {renderHazardCategories()}
                    </div>
                  </>
                }
              </div>}
            {(swType === SWTypes.TLMSWI
              || swType === SWTypes.TLMRC
              || swType === SWTypes.MFGSWI
              || swType === SWTypes.MFGRC
              || swType === SWTypes.SWI
              || swType === SWTypes.CL
              || swType === SWTypes.ECL) &&
              <>
                <div className="inner">
                  {noticeComp}
                  {allowHazardCategory() && stepGuid === undefined
                    && notice.noticeType !== NoticeTypes.Info &&
                    <>
                      <div className="notice-hazard-categories controls">
                        {renderHazardCategories()}
                      </div>
                    </>
                  }
                  {allowHazardCategory() && stepGuid !== undefined
                    && notice.noticeType !== NoticeTypes.Info &&
                    <>
                      <div className="notice-hazard-categories controls">
                        {renderComponentLevelHazardCategories()}
                      </div>
                    </>
                  }
                  {allowPotentialLoss() && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC) &&
                      <div className="tlm-notice-potential-loss">
                          {renderPotentialLossData(stepGuid)}
                      </div>
                  }
                </div>
              </>
            }
          </Banner>
          <Modal
            isOpen={isTypeModalOpen}
            header="Set Notice Type"
            controls={
              <button
                className="secondary-button"
                onClick={() => setIsTypeModalOpen(false)}
              >
                Cancel
              </button>
            }
          >
            <p>Click one of the following notice types to choose it.</p>
            <div className="type-buttons">
              <button
                className={`type-button ${NoticeTypes.Info}`}
                onClick={() => onTypeChange(NoticeTypes.Info)}
                title="Provide information that supports the step"
              >
                {getIcon(NoticeTypes.Info)} Info
              </button>
              <button
                className={`type-button ${NoticeTypes.Caution}`}
                onClick={() => onTypeChange(NoticeTypes.Caution)}
                title="Identify risk of damage to equipment or assets"
              >
                {getIcon(NoticeTypes.Caution)} Caution
              </button>
              <button
                className={`type-button ${NoticeTypes.Warning}`}
                onClick={() => onTypeChange(NoticeTypes.Warning)}
                title="Identify risk of injury or death to personnel"
              >
                {getIcon(NoticeTypes.Warning)} Warning
              </button>
            </div>
          </Modal>
        </>
      }
    </>
  );
};

const getBannerType = (type: NoticeTypes): BannerType => {
  switch (type) {
    case NoticeTypes.Caution: return BannerType.Warn;
    case NoticeTypes.Warning: return BannerType.Error;
    default: return BannerType.Info;
  }
}

const getIcon = (type: NoticeTypes) => {
  if (type === NoticeTypes.Caution) {
    return (
      <img
        src={WarningIcon}
        className={`icon-large type-icon ${type}`}
        alt="Caution"
      />
    );
  } else if (type === NoticeTypes.Warning) {
    return (
      <img
        src={ErrorIcon}
        className={`icon-large type-icon ${type}`}
        alt="Warning"
      />
    );
  } else if (type === NoticeTypes.Info) {
    return (
      <img
        src={InfoIcon}
        className={`icon-large type-icon ${type}`}
        alt="Info"
      />
    );
  }
  return null;
}

export default Notice;