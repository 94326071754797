import {
  takeLatest,
  put,
  call,
  takeEvery,
  all,
  select,
} from "redux-saga/effects";
import {
  uploadSWAttachment,
  setFileUploadOperation,
  updateImage,
  getStepComponentImageUrl,
  getTableCellImageUrls,
  getStepComponentTIMEImageUrl,
  getNoticeImageUrl,
  save,
  setCreateOperation,
  finishCreating,
  load,
  setLoadOperation,
  setRefDocFilename,
  setUpdateOperation,
  finishUpdating,
  finishLoading,
  updateImageBlobData,
  finishUnlockSWCurrentEditor,
  unlockSWCurrentEditor,
  saveSWComment,
  loadComments,
  setComments,
  loadStepComments,
  setStepComments,
  saveSWStepComment,
  resolveStepComment,
  updateStepCommentsInfo,
  loadAllSWComments,
  updateNoticeImageBlobData,
  uploadNoticeImage,
  uploadVideo,
  getVideoUrl,
  updateVideoBlobData,
  resolveSWComment,
  publishRC,
  loadPublishRCData,
  updatePublishRCData,
  setPublishOperation,
  fetchRCSteps,
  fetchRCTimeSteps,
  addRCSteps,
  removeRCContainerFromSteps,
  addRCContainerInSteps,
  loadSWRevisionHistories,
  setRevisionHistories,
  getAudioUrl,
  updateAudioBlobData,
  uploadAudio,
  saveReusableCompFromTLMSWI,
  finishRCFromTLMSWICreating,
  finishRCFromTLMSWIUpdating,
  setCreateReusableContentPopup,
  finishRCFromBlobCopying,
  uploadTableImage,
  setTableImageFilename,
  updateTableCellBlobData,
  setRefDocTableImageFilename,
  updateRefDocTableCellBlobData,
  getRefDocTableCellImageUrls,
  updateTIMEImage,
  searchTimeImageNumber,
  setTimeImageData,
  uploadDownloadTimeImage,
  getTimeMediaWhereUsedFiles,
  setTimeMediaWhereUsedFiles,
  downloadNoticeTimeImage,
  downloadTableTimeImage,
  setTableTimeImageFilename,
  redirectToSearchFromTimeRC,
  fetchRCNotice,
  addRCNotices,
  addRCSubSteps,
  setSubmitForReviewFlag,
  setReusableCompFromTLMSWIRcType,
  setAllSiteLocations,
  getAllSiteLocations,
  loadConfidentialEnabledUsers,
  setConfidentialUsersLoadOperation,
  setConfidentialEnabledUserList,
  addConfidentialAccess,
  setConfidentialAccessChangeOperation,
  removeConfidentialAccess,
  setConfigParaMeterFlag,
  getConnectedSWIs,
  setSetConnectedSWIs,
  addConnectedSWI,
  setAddConnectedSWILoadOperation,
  deleteConnectedSWI,
  uploadConnectedSWIs,
  expandAndScrollToStep,
  setUploadConnectedSWIsErrors,
  backgroundUpdate,
  setBackgroundUpdateOperation,
  finishBackgroundUpdate,
  setPendingCompatibilityFetchRCStepsAction,
  loadSWNoticeSummary,
  setSWNotices,
  setSWStepNotices,
  loadConnectedSWIConfiguration,
  setConnectedSWIEnabled, 
  pasteComponent, 
  pasteSWComponentToSWDraft
} from "./manageSWActions";
import {
  updateApprovalAudioBlobData,
  updateApprovalNoticeImageBlobData,
  updateApprovalVideoBlobData,
  updateImageBlobData as updateApprovalImageBlobData,
  updateTableCellBlobData as updateApprovalTableCellBlobData,
  updateRefDocTableCellBlobData as updateApprovalRefDocTableCellBlobData
} from "store/approvals/approvalsActions";
import { Action } from "@reduxjs/toolkit";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import SWApi, { ICreateSWDraftParams } from "apis/sw/SWApi";
import { SWAttachmentTypes, ImageDataDestinations, ITimeImageData } from "./manageSWTypes";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import {
  IUploadSWAttachmentResponse,
  ISW,
  IUnlockSWCurrentEditor,
  IComment,
  IStepComment,
  IStepsCommentInfo,
  IAllSWComments,
  IGetRCMapping,
  ISWRevisionHistory,
  IGetRCSW,
  IGetTimeMediaFileMapping,
  INoticeComponent,
  StepComponentTypes,
  RCTypes,
  SWTypes,
  ISiteLocationsData,
  IConnectedSWIData,
  IUploadConnectedSWIsResponse,
  ISWNoticeSummary, 
  ICopiedSWAttachment
} from "interfaces/sw/SWInterfaces";
import { RootState } from "store/rootStore";
import { clearFilter, createRCDraft, setTimeRCRequestData } from "store/swList/swListActions";
import { Routes } from "components/routing/Routing";
import { addImageNumberToLabel, findNoticeImageType } from "utilities/fileUtilities";
import { ITimeRCRequestData } from "store/swList/swListTypes";
import { findStep } from "./manageSWHelpers";
import { IConfidentialSWUser } from "interfaces/user/UserInterfaces";
import { IConfigParameters } from "interfaces/masterData/masterDataInterfaces";
import MasterDataApi from "apis/masterData/MasterDataApi";
import { ResponseError } from "../../apis/responseError";
import { IOperation } from "interfaces/operations/Operations";

export default function* watchManageSWSagas() {
  yield all([
    watchUploadSWAttachment(),
    watchUploadChildMaterials(),
    watchUploadTableComponentImage(),
    watchGetStepComponentImageUrl(),
    watchGetTableCellImageUrls(),
    watchGetRefDocTableCellImageUrls(),
    watchGetStepComponentTIMEImageUrl(),
    watchSave(),
    watchBackgroundSave(),
    watchSaveReusableCompFromTLMSWI(),
    watchLoad(),
    watchUnlockSWCurrentEditor(),
    watchSaveSWComment(),
    watchSaveSWStepComment(),
    watchLoadComments(),
    watchLoadStepComments(),
    watchresolveStepComment(),
    watchLoadAllSWComments(),
    watchGetNoticeImageUrl(),
    watchUploadNoticeImage(),
    watchUploadVideo(),
    watchGetVideoUrl(),
    watchResolveSWComment(),
    watchPublishRC(),
    watchPublishRCData(),
    watchFetchRCSteps(),
    watchFetchRCTimeSteps(),
    watchLoadSWRevisionHistories(),
    watchUploadAudio(),
    watchGetAudioUrl(),
    watchCreateRCDraft(),
    watchSearchTimeImageNumber(),
    watchuploadDownloadImage(),
    watchGetTimeMediaWhereUsedFiles(),
    watchDownloadNoticeTimeImage(),
    watchDownloadTableTimeImage(),
    watchfetchRCNotice(),
    watchGetAllSiteLocations(),
    watchLoadConfidentialEnabledUsers(),
    watchAddTccUser(),
    watchRemoveTccUser(),
    watchGetConnectedSWIs(),
    watchAddConnectedSWI(),
    watchDeleteConnectedSWI(),
    watchLoadSWNoticeSummary(),
    watchLoadConnectedSWIConfiguration(),
    watchPasteSWComponentToSWDraftAsync()
  ]);
}
function* watchGetAllSiteLocations()
{
  yield takeLatest(getAllSiteLocations, getAllSiteLocationsAsync);
}
function* watchSaveSWComment() {
  yield takeLatest(saveSWComment, saveSWCommentAsync);
}

function* watchSearchTimeImageNumber() {
  yield takeLatest(searchTimeImageNumber, searchTimeImageNumberAsync);
}

function* watchDownloadTableTimeImage() {
  yield takeLatest(downloadTableTimeImage, downloadTableTimeImageaAsync);
}

function* watchuploadDownloadImage() {
  yield takeLatest(uploadDownloadTimeImage, uploadDownloadTimeImageAsync);
}

function* watchSaveSWStepComment() {
  yield takeLatest(saveSWStepComment, saveSWStepCommentAsync);
}

function* watchPublishRC() {
  yield takeLatest(publishRC, publishRCAsync);
}

function* watchFetchRCSteps() {
  yield takeLatest(fetchRCSteps, fetchRCStepsAsync);
}

function* watchFetchRCTimeSteps() {
  yield takeLatest(fetchRCTimeSteps, fetchRCTimeStepsAsync);
}

function* watchCreateRCDraft() {
  yield takeLatest(createRCDraft, createRCDraftAsync);
}

function* watchPublishRCData() {
  yield takeLatest(loadPublishRCData, loadPublishRCDataAsync)
}

function* watchresolveStepComment() {
  yield takeLatest(resolveStepComment, resolveStepCommentAsync);
}

function* watchLoadComments() {
  yield takeLatest(loadComments, loadCommentsAsync);
}

function* watchLoadStepComments() {
  yield takeLatest(loadStepComments, loadStepCommentsAsync);
}

function* watchLoadAllSWComments() {
  yield takeLatest(loadAllSWComments, loadAllSWCommentsAsync);
}

function* watchUploadSWAttachment() {
  yield takeLatest(uploadSWAttachment, uploadSWAttachmentAsync);
}

function* watchUploadChildMaterials() {
  yield takeLatest(uploadConnectedSWIs, uploadChildMaterialsAsync);
}

function* watchUploadTableComponentImage() {
  yield takeLatest(uploadTableImage, uploadTableComponentImageAsync)
}

function* watchGetNoticeImageUrl() {
  yield takeEvery(getNoticeImageUrl, getNoticeImageUrlAsync);
}

function* watchUploadNoticeImage() {
  yield takeLatest(uploadNoticeImage, uploadNoticeImageAsync);
}

function* watchGetVideoUrl() {
  yield takeEvery(getVideoUrl, getVideoUrlAsync);
}

function* watchGetAudioUrl() {
  yield takeEvery(getAudioUrl, getAudioUrlAsync);
}

function* watchUploadVideo() {
  yield takeLatest(uploadVideo, uploadVideoAsync);
}

function* watchUploadAudio() {
  yield takeLatest(uploadAudio, uploadAudioAsync);
}

function* watchResolveSWComment() {
  yield takeLatest(resolveSWComment, resolveSWCommentAsync);
}
function* watchLoadSWRevisionHistories() {
  yield takeLatest(loadSWRevisionHistories, loadSWRevisionHistoriesAsync);
}

function* watchGetTimeMediaWhereUsedFiles() {
  yield takeLatest(getTimeMediaWhereUsedFiles, getTimeMediaWhereUsedFilesAsync);
}

function* watchDownloadNoticeTimeImage() {
  yield takeLatest(downloadNoticeTimeImage, downloadNoticeTimeImageAsync);
}

function* watchfetchRCNotice() {
  yield takeLatest(fetchRCNotice, fetchRCNoticeAsync);
}

function* watchLoadConfidentialEnabledUsers() {
  yield takeLatest(loadConfidentialEnabledUsers, loadConfidentialEnabledUsersAsync);
}

function* watchAddTccUser() {
  yield takeLatest(addConfidentialAccess, addConfidentialAccessAsync);
}

function* watchRemoveTccUser() {
  yield takeLatest(removeConfidentialAccess, removeConfidentialAccessAsync);
}

function* watchLoadSWNoticeSummary() {
    yield takeLatest(loadSWNoticeSummary, loadSWNoticeSummaryAsync)
}

function* watchLoadConnectedSWIConfiguration() {
  yield takeLatest(loadConnectedSWIConfiguration, watchLoadConnectedSWIConfigurationAsync);
}

function* watchLoadConnectedSWIConfigurationAsync(action: Action) {
  if (!loadConnectedSWIConfiguration.match(action)) {
    return;
  }

  try {
    const config: IConfigParameters[] = yield call(MasterDataApi.getConfigParameters);
    const connectedSWIConfig = config.find(x => x.key === "EnableConnectedSWI");
    const connectedSWI = connectedSWIConfig?.value === "true";
    yield put(setConnectedSWIEnabled(connectedSWI));
  } catch (err: any) {
    console.error("Failed to load connected SWI configuration: ", err);
  }
}

function* loadSWNoticeSummaryAsync(action: Action) {
    if (!loadSWNoticeSummary.match(action)) {
        return;
    }

    yield put(setLoadOperation({
        isWorking: true
    }));

    try {
        const response: ISWNoticeSummary = yield call(SWApi.getSWNoticeSummary, action.payload.swGuid, action.payload.swVersion);

        yield put(setSWNotices({
            swNotices: response.swNotices
        }));
        yield put(setSWStepNotices({
            swStepNotices: response.swStepNotices
        }));
        yield put(setLoadOperation({
            isWorking: false
        }));
    }
    catch (err: any) {
        yield put(showErrorToast(getResponseErrorMessage(err)));
        yield put(setLoadOperation({
            isWorking: false,
            errorMessage: getResponseErrorMessage(err),
        }));
    }
}

function* searchTimeImageNumberAsync(action: Action) {
  if (!searchTimeImageNumber.match(action)) {
    return;
  }
  try {
    yield put(
      setUpdateOperation({
        isWorking: true,
      })
    );

    if (action.payload.searchText !== "") {
      const data: ITimeImageData = yield call(SWApi.getTimeImage, action.payload.searchText);
      if (data !== null) {
        yield put(
          setTimeImageData(data)
        );
      }
    }
    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
  }
  catch (err: any) {
    if (err instanceof ResponseError && err.isUnauthorized()) {
      yield put(
          showErrorToast(err.message)
      );
    } else {
      yield put(
          showErrorToast("No such image found")
      );
    }
    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
    return;
  }
}

function* uploadDownloadTimeImageAsync(action: Action) {
  if (!uploadDownloadTimeImage.match(action)) {
    return;
  }
  try {
    yield put(
      setUpdateOperation({
        isWorking: true,
      })
    );

    if (action.payload.link !== "") {
      const data = action.payload;
      const uploadResponse: IUploadSWAttachmentResponse = yield call(SWApi.uploadTimeImage,
        data.swGuid, data.swVersion, data.label, data.link, data.imageNumber.toString());
      if (data.type === SWAttachmentTypes.TIMEImageComponent
        && action.payload.timeImageComponent
        && action.payload.stepGuid
      ) {
        yield put(
          updateTIMEImage({
            component: {
              ...action.payload.timeImageComponent,
              filename: uploadResponse.filename,
              label: addImageNumberToLabel(data.label, data.imageNumber),
            },
            stepGuid: action.payload.stepGuid,
          })
        );
      }
    }

    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
  }
  catch (err: any) {
    yield put(
      showErrorToast("Failed to upload TIME image: " + getResponseErrorMessage(err))
    );
    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
    return;
  }
}

function* getTimeMediaWhereUsedFilesAsync(action: Action) {
  if (!getTimeMediaWhereUsedFiles.match(action)) {
    return;
  }

  yield put(
    setTimeMediaWhereUsedFiles({
      timeMediaFileMapping: [],
      loadTimeImagesOperation: {
        isWorking: true,
      }
    })
  );

  try {
    let response: IGetTimeMediaFileMapping[] = yield call(SWApi.getTimeMediaWhereUsed, action.payload.imageNumber);
    yield put(
      setTimeMediaWhereUsedFiles({
        timeMediaFileMapping: response,
        loadTimeImagesOperation: {
          isWorking: false,
          wasSuccessful: true,
        }
      })
    );
  } catch (err: any) {
    yield put(
      showErrorToast("Failed to get Where used data: " + getResponseErrorMessage(err))
    );
    yield put(
      setTimeMediaWhereUsedFiles({
        timeMediaFileMapping: [],
        loadTimeImagesOperation: {
          isWorking: false,
          wasSuccessful: false,
        }
      })
    );
  }
}

function* saveSWStepCommentAsync(action: Action) {
  if (!saveSWStepComment.match(action)) {
    return;
  }

  let isTask: boolean | undefined = false;
  const sw: ISW = yield select((store: RootState) => store.manageSW.SW);
  try {

    yield put(
      setUpdateOperation({
        isWorking: true,
      })
    );

    isTask = sw.steps.find(x => x.id === action.payload.stepID)?.isTask;
    const stepCommentsInfo: IStepsCommentInfo = yield call(SWApi.saveSWStepComment, action.payload.stepID, action.payload.comment, sw.id);

    // update the step info
    yield put(updateStepCommentsInfo({
      stepID: stepCommentsInfo.stepId,
      hasUnresolvedComments: stepCommentsInfo.hasUnresolvedComments,
      totalStepComments: stepCommentsInfo.totalStepComments,
      unResolvedStepComments: stepCommentsInfo.unResolvedStepComments,
    }));

    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
    yield put(showSuccessToast("Created " + (isTask ? "Task" :
      sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
        x.isTask === true)) ? "Task" :
        "Step") + " Comment"));


  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to save " + (isTask ? "task" :
          sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
            x.isTask === true)) ? "Task" :
            "step") + " comment: " + err)
      )
    );
    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );
    return;
  }
}

function* loadPublishRCDataAsync(action: Action) {
  if (!loadPublishRCData.match(action)) {
    return;
  }
  try {
    yield put(
      setLoadOperation({
        isWorking: true,
      })
    );
    let response: IGetRCMapping[] = yield call(SWApi.getRCWhereUsed, action.payload);
    yield put(updatePublishRCData(response.length));
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );

  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to Load RC Data: " + err)
      )
    );

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
  }

}

function* resolveStepCommentAsync(action: Action) {
  if (!resolveStepComment.match(action)) {
    return;
  }
  let isTask: boolean | undefined = false;
  try {

    yield put(
      setUpdateOperation({
        isWorking: true,
      })
    );
    const sw: ISW = yield select((store: RootState) => store.manageSW.SW);
    isTask = sw.steps.find(x => x.id === action.payload.stepID)?.isTask;
    const stepCommentsInfo: IStepsCommentInfo = yield call(SWApi.resolveStepComment, action.payload.commentID, action.payload.stepID, sw.id);

    yield put(updateStepCommentsInfo({
      stepID: stepCommentsInfo.stepId,
      hasUnresolvedComments: stepCommentsInfo.hasUnresolvedComments,
      totalStepComments: stepCommentsInfo.totalStepComments,
      unResolvedStepComments: stepCommentsInfo.unResolvedStepComments,
    }));

    yield put(showSuccessToast((isTask ? "Task" :
      sw.steps.filter(a => a.rcContainer).some(({ children }) => children.some(x => x.id === action.payload.stepID &&
        x.isTask === true)) ? "Task" :
        "Step") + " Comment Resolved"));

    const response: IStepComment[] = yield call(SWApi.getSWStepComments, action.payload.stepID);

    yield put(setStepComments({
      loadOperation: {
        isWorking: false,
        wasSuccessful: true,
      },
      comments: response,
      stepID: action.payload.stepID,
    }));

    yield put(
      setUpdateOperation({
        isWorking: false,
      })
    );

  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to resolve step comment: " + err)
      )
    );
    yield put(
      setUpdateOperation({
        isWorking: false,
        wasSuccessful: false,
      })
    );
    yield put(setStepComments({
      loadOperation: undefined,
      comments: [],
    }));
    return;
  }
}

function* saveSWCommentAsync(action: Action) {
  if (!saveSWComment.match(action)) {
    return;
  }

  const sw: ISW = yield select((store: RootState) => store.manageSW.SW);

  try {
    yield call(SWApi.saveSWComment, sw.guid, sw.version, action.payload);
    yield put(loadComments({ swGuid: sw.guid, swVersion: sw.version }));
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to save comment: " + err)
      )
    );
    return;
  }
}

function* loadStepCommentsAsync(action: Action) {
  if (!loadStepComments.match(action)) {
    return;
  }

  yield put(setStepComments({
    loadOperation: {
      isWorking: true,
    },
    comments: [],
  }));

  try {

    const response: IStepComment[] = yield call(SWApi.getSWStepComments, action.payload.stepID);

    yield put(setStepComments({
      loadOperation: {
        isWorking: false,
        wasSuccessful: true,
      },
      comments: response,
      stepID: action.payload.stepID,
    }));

  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setStepComments({
      loadOperation: undefined,
      comments: [],
    }));
  }
}

function* publishRCAsync(action: Action) {
  if (!publishRC.match(action)) {
    return;
  }

  yield put(
    setPublishOperation({
      isWorking: true,
    })
  );

  try {
    yield call(SWApi.publishRC, action.payload.swGuid, action.payload.swVersion);
    yield put(showSuccessToast("Your Reusable content is Published"));
    yield put(
      setPublishOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );

  } catch (err: any) {
    yield put(
      setPublishOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* loadAllSWCommentsAsync(action: Action) {
  if (!loadAllSWComments.match(action)) {
    return;
  }

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );

  try {
    const response: IAllSWComments = yield call(SWApi.getAllSWComments,
      action.payload.swGuid,
      action.payload.swVersion);

    yield put(setComments({
      comments: response.swComments,
    }));

    yield put(setStepComments({
      comments: response.swStepComments,
    }));

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );

  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* loadSWRevisionHistoriesAsync(action: Action) {
  if (!loadSWRevisionHistories.match(action)) {
    return;
  }

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );

  try {
    const swRevisionHistories: ISWRevisionHistory[] = yield call(SWApi.getSWRevisionHistories,
      action.payload.swGuid);

    yield put(setRevisionHistories({
      revisionHistories: swRevisionHistories
    }));

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );

  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* loadCommentsAsync(action: Action) {
  if (!loadComments.match(action)) {
    return;
  }

  yield put(setComments({
    loadOperation: {
      isWorking: true,
    },
    comments: []
  }));

  try {
    const response: IComment[] = yield call(SWApi.getSWComments, action.payload.swGuid, action.payload.swVersion);

    yield put(setComments({
      loadOperation: {
        isWorking: false,
        wasSuccessful: true,
      },
      comments: response,
    }));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setComments({
      loadOperation: undefined,
      comments: [],
    }));
  }
}

function* uploadTableComponentImageAsync(action: Action) {
  if (!uploadTableImage.match(action)) {
    return;
  }

  yield put(
    setFileUploadOperation({
      isWorking: true,
    })
  );

  let uploadResponse: IUploadSWAttachmentResponse;
  try {
    uploadResponse = yield call(
      SWApi.uploadSWAttachment,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.imageObject.file
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(showErrorToast("Failed to upload attachment: " + errorMessage));

    yield put(
      setFileUploadOperation({
        isWorking: false,
        errorMessage,
      })
    );

    return;
  }

  if (action.payload.stepGuid) {// this is for step component
    yield put(
      setTableImageFilename({
        stepGuid: action.payload.stepGuid,
        componentGuid: action.payload.componentGuid,
        serverFilename: uploadResponse.filename,
        rowIndex: action.payload.rowIndex,
        colIndex: action.payload.colIndex,
      })
    );
  } else { // this is for ref doc table
    yield put(
      setRefDocTableImageFilename({
        refDocGuid: action.payload.componentGuid,
        serverFilename: uploadResponse.filename,
        rowIndex: action.payload.rowIndex,
        colIndex: action.payload.colIndex,
      })
    );
  }

  yield put(setFileUploadOperation(undefined));
}

function* downloadTableTimeImageaAsync(action: Action) {
  if (!downloadTableTimeImage.match(action)) {
    return;
  }

  try {
    yield put(
      setFileUploadOperation({
        isWorking: true,
      })
    );

    if (
      action.payload.link !== "" ||
      action.payload.imageNumber !== 0 ||
      action.payload.label !== ""
    ) {
      const data = action.payload;
      const uploadResponse: IUploadSWAttachmentResponse = yield call(SWApi.uploadTimeImage,
        data.swGuid, data.swVersion, data.label, data.link, data.imageNumber.toString());

      if (action.payload.stepGuid) {
        yield put(
          setTableTimeImageFilename({
            stepGuid: action.payload.stepGuid,
            componentGuid: action.payload.componentGuid,
            serverFilename: uploadResponse.filename,
            rowIndex: action.payload.rowIndex,
            colIndex: action.payload.colIndex,
            value: action.payload.value,
            altTagValue: action.payload.altTagValue,
          })
        );
      }
    }

    yield put(
      setFileUploadOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );
  }
  catch (err: any) {
    yield put(
      showErrorToast("Failed to upload TIME image")
    );
    yield put(
      setFileUploadOperation({
        isWorking: false,
        wasSuccessful: false,
      })
    );
    return;
  }
}

function* uploadChildMaterialsAsync(action: Action) {
  if (!uploadConnectedSWIs.match(action)) {
    return;
  }

  yield put(
    setFileUploadOperation({
      isWorking: true,
    })
  );

  let uploadResponse: IUploadConnectedSWIsResponse;

  try {
    uploadResponse = yield call(
      SWApi.uploadConnectedSWIs,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.file
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(showErrorToast("Failed to upload template: " + errorMessage));

    yield put(
      setFileUploadOperation({
        isWorking: false,
        errorMessage,
      })
    );

    return;
  }

  if (uploadResponse.errors && uploadResponse.errors.length > 0 && uploadResponse.errors != null) {
    yield put(getConnectedSWIs({
        swGuid: action.payload.swGuid,
        swVersion: action.payload.swVersion
    }));
    yield put(setUploadConnectedSWIsErrors(uploadResponse.errors))
  } else if (uploadResponse.connectedSWIs && uploadResponse.connectedSWIs.length > 0) {
    yield put(getConnectedSWIs({
        swGuid: action.payload.swGuid,
        swVersion: action.payload.swVersion
    }));
  } else {
    yield put(showErrorToast("Failed to get valid response from the server."));
  }

  yield put(setFileUploadOperation(undefined));
}

function* uploadSWAttachmentAsync(action: Action) {
  if (!uploadSWAttachment.match(action)) {
    return;
  }

  if (
    action.payload.type === SWAttachmentTypes.ImageComponent
    && (!action.payload.imageComponent || !action.payload.stepGuid)
  ) {
    yield put(
      showErrorToast("Upload is missing imageComponent or stepId parameter.")
    );
    return;
  } else if (
    action.payload.type === SWAttachmentTypes.RefDoc
    && !action.payload.refDocGuid
  ) {
    yield put(showErrorToast("Upload is missing refDocGuid parameter."));
    return;
  }

  yield put(
    setFileUploadOperation({
      isWorking: true,
    })
  );

  let uploadResponse: IUploadSWAttachmentResponse;

  try {
    uploadResponse = yield call(
      SWApi.uploadSWAttachment,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.file
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(showErrorToast("Failed to upload attachment: " + errorMessage));

    yield put(
      setFileUploadOperation({
        isWorking: false,
        errorMessage,
      })
    );

    return;
  }

  if (
    action.payload.type === SWAttachmentTypes.ImageComponent
    && action.payload.imageComponent
    && action.payload.stepGuid
  ) {
    yield put(
      updateImage({
        component: {
          ...action.payload.imageComponent,
          filename: uploadResponse.filename,
        },
        stepGuid: action.payload.stepGuid,
      })
    );
  } else if (
    action.payload.type === SWAttachmentTypes.RefDoc
    && action.payload.refDocGuid
  ) {
    yield put(
      setRefDocFilename({
        refDocGuid: action.payload.refDocGuid,
        filename: uploadResponse.filename,
      })
    );
  }

  yield put(setFileUploadOperation(undefined));
}

function* watchGetStepComponentTIMEImageUrl() {
  yield takeEvery(getStepComponentTIMEImageUrl, getStepComponentTIMEImageUrlAsync);
}

function* getStepComponentTIMEImageUrlAsync(action: Action) {
  if (!getStepComponentTIMEImageUrl.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetStepComponentTIMEImageUrl,
      action,
      updateApprovalImageBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetStepComponentTIMEImageUrl,
      action,
      updateImageBlobData
    );
  }
}

function* handleGetStepComponentTIMEImageUrl(action: Action,
  updateAction: updateImageBlobDataMethodTypes) {
  if (!getStepComponentTIMEImageUrl.match(action)) {
    return;
  }

  yield put(
    updateAction({
      blobData: {
        filename: action.payload.filename,
        isLoading: true,
        blobUrl: "",
      },
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.timeImageComponent.guid,
    })
  );

  let blobUrl: string = "";

  try {
    blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename
    );
  } catch (err: any) {
    yield put(
      updateAction({
        blobData: {
          filename: action.payload.filename,
          isLoading: false,
          blobUrl: "",
          errorMessage: `Failed to load image: ${getResponseErrorMessage(
            err
          )}`,
        },
        stepGuid: action.payload.stepGuid,
        componentGuid: action.payload.timeImageComponent.guid,
      })
    );
    return;
  }

  yield put(
    updateAction({
      blobData: {
        filename: action.payload.filename,
        isLoading: false,
        blobUrl,
      },
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.timeImageComponent.guid,
    })
  );
}

function* watchGetStepComponentImageUrl() {
  yield takeEvery(getStepComponentImageUrl, getStepComponentImageUrlAsync);
}

function* watchGetTableCellImageUrls() {
  yield takeEvery(getTableCellImageUrls, getTableCellImageUrlsAsync);
}

function* watchGetRefDocTableCellImageUrls() {
  yield takeEvery(getRefDocTableCellImageUrls, getRefDocTableCellImageUrlsAsync);
}

function* getStepComponentImageUrlAsync(action: Action) {
  if (!getStepComponentImageUrl.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetStepComponentImageUrl,
      action,
      updateApprovalImageBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetStepComponentImageUrl,
      action,
      updateImageBlobData
    );
  }
}

function* getTableCellImageUrlsAsync(action: Action) {
  if (!getTableCellImageUrls.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetTableCellImageUrl,
      action,
      updateApprovalTableCellBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetTableCellImageUrl,
      action,
      updateTableCellBlobData
    );
  }
}

function* getRefDocTableCellImageUrlsAsync(action: Action) {
  if (!getRefDocTableCellImageUrls.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetRefDocTableCellImageUrl,
      action,
      updateApprovalRefDocTableCellBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetRefDocTableCellImageUrl,
      action,
      updateRefDocTableCellBlobData
    );
  }
}

type updateTableCellBlobDataMethodTypes =
  typeof updateApprovalTableCellBlobData
  | typeof updateTableCellBlobData;

type updateRefDocTableCellBlobDataMethodTypes =
  typeof updateRefDocTableCellBlobData
  | typeof updateApprovalRefDocTableCellBlobData;

function* handleGetTableCellImageUrl(action: Action,
  updateAction: updateTableCellBlobDataMethodTypes) {
  if (!getTableCellImageUrls.match(action)) {
    return;
  }

  let blobUrl: string = "";
  try {
    blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename,
    );
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to get image url: " + err)
      )
    );
    return;
  }

  //update the cells to have signed azure urls for images...
  yield put(
    updateAction({
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.componentGuid,
      fileName: blobUrl,
      rowIndex: action.payload.rowIndex,
      colIndex: action.payload.colIndex,
      serverFileName: action.payload.filename
    })
  );
}

function* handleGetRefDocTableCellImageUrl(action: Action,
  updateAction: updateRefDocTableCellBlobDataMethodTypes) {
  if (!getRefDocTableCellImageUrls.match(action)) {
    return;
  }

  let blobUrl: string = "";
  try {
      blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename,
    );
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to get image url: " + err)
      )
    );
    return;
  }

  //update the cells to have signed azure urls for images...
  yield put(
    updateAction({
      refDocGuid: action.payload.refDocGuid,
      fileName: blobUrl,
      rowIndex: action.payload.rowIndex,
      colIndex: action.payload.colIndex,
    })
  );
}

type updateImageBlobDataMethodTypes =
  typeof updateApprovalImageBlobData
  | typeof updateImageBlobData;

function* handleGetStepComponentImageUrl(action: Action,
  updateAction: updateImageBlobDataMethodTypes) {
  if (!getStepComponentImageUrl.match(action)) {
    return;
  }

  yield put(
    updateAction({
      blobData: {
        filename: action.payload.filename,
        isLoading: true,
        blobUrl: "",
      },
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.imageComponent.guid,
    })
  );

  let blobUrl: string = "";

  try {
    blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename
    );
  } catch (err: any) {
    yield put(
      updateAction({
        blobData: {
          filename: action.payload.filename,
          isLoading: false,
          blobUrl: "",
          errorMessage: `Failed to load image: ${getResponseErrorMessage(
            err
          )}`,
        },
        stepGuid: action.payload.stepGuid,
        componentGuid: action.payload.imageComponent.guid,
      })
    );
    return;
  }

  yield put(
    updateAction({
      blobData: {
        filename: action.payload.filename,
        isLoading: false,
        blobUrl,
      },
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.imageComponent.guid,
    })
  );
}

function* watchSave() {
  yield takeLatest(save, saveAsync);
}

function* watchBackgroundSave() {
  yield takeLatest(backgroundUpdate, backgroundUpdateAsync);
}

function* watchSaveReusableCompFromTLMSWI() {
  yield takeLatest(saveReusableCompFromTLMSWI, saveReusableCompFromTLMSWIAsync);
}

function* saveAsync(action: Action) {
  if (!save.match(action)) {
    return;
  }
  const sw: ISW = yield select((store: RootState) => store.manageSW.SW);

  const isCreate = !sw.guid;

  if (isCreate) {
    yield call(createAsync, sw);
  } else {
    yield call(updateAsync, sw, action.payload.isVersionChange);
  }
}

function* backgroundUpdateAsync(action: Action) {
  const updateOperation: IOperation | undefined = yield select(
    (store: RootState) => store.manageSW.updateOperation
  );

  const backgroundUpdateOperation: IOperation | undefined = yield select(
    (store: RootState) => store.manageSW.backgroundUpdateOperation
  );

  const fileUploadOperation: IOperation | undefined = yield select(
    (store: RootState) => store.manageSW.fileUploadOperation
  );

  if (
    updateOperation?.isWorking ||
    backgroundUpdateOperation?.isWorking ||
    fileUploadOperation?.isWorking
  ) {
    return;
  }

  yield put(setBackgroundUpdateOperation({
    isWorking: true,
  }));

  const sw: ISW = yield select((store: RootState) => store.manageSW.SW);
  
  try {
    yield call(SWApi.updateSW, sw);

    yield put(
      setBackgroundUpdateOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );

    yield put(finishBackgroundUpdate());
  } catch (err: any) {
    yield put(
      setBackgroundUpdateOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* saveReusableCompFromTLMSWIAsync(action: Action) {
  if (!saveReusableCompFromTLMSWI.match(action)) {
    return;
  }
  yield put(
    setReusableCompFromTLMSWIRcType(action.payload)
  );

  const sw: ISW = yield select((store: RootState) => store.manageSW.ReusableCompFromTLMSWI);
  const originalSW: ISW = yield select((store: RootState) => store.manageSW.SW);
  yield call(createReusableCompFromTLMSWIAsync, sw, originalSW.guid, originalSW.version);
}

function* createReusableCompFromTLMSWIAsync(sw: ISW, originalSWGuid: string, originalSWVersion: number) {
  yield put(
    setPublishOperation({
      isWorking: true,
    })
  );

  try {
    let swGuid: string = yield call(SWApi.createSW, sw);
    yield put(
      finishRCFromTLMSWICreating({
        swGuid,
      })
    );

    const swForUpdate: ISW = yield select((store: RootState) => store.manageSW.ReusableCompFromTLMSWI);
    let updatedSW: ISW = yield call(SWApi.updateSW, swForUpdate);
    yield put(finishRCFromTLMSWIUpdating(updatedSW));

    // Copy relevant SW blobs to RC
    yield call(SWApi.copySWAttachmentsToRC,
      originalSWGuid,
      originalSWVersion,
      swGuid);

    yield put(finishRCFromBlobCopying());

    yield put(showSuccessToast("The Reusable Content was successfully created!"));
    yield put(setCreateReusableContentPopup({
      isCreateRCPopup: false,
      swType : sw.type,
    }));
    yield put(
      setPublishOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );

  } catch (err: any) {
    yield put(
      setPublishOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}


function* createAsync(sw: ISW) {
  yield put(
    setCreateOperation({
      isWorking: true,
    })
  );

  try {
    let swGuid: string = yield call(SWApi.createSW, sw);
    yield put(
      finishCreating({
        swGuid,
      })
    );
    yield put(showSuccessToast("Your standard work was successfully created!"));
  } catch (err: any) {
    yield put(
      setCreateOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* updateAsync(sw: ISW, isVersionChanges: boolean) {
  yield put(
    setUpdateOperation({
      isWorking: true,
    })
  );

  try {
    let updatedSW: ISW = yield call(SWApi.updateSW, sw);

    yield put(
      setUpdateOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );

    yield put(finishUpdating(updatedSW));
    yield put(addRCContainerInSteps());
    yield put(setSubmitForReviewFlag({ submitForReviewFlag: isVersionChanges }));
    yield put(showSuccessToast(`Your changes have been saved.`));

    //handle focus on step after save.
    let stepGuid = updatedSW.activeStepGuid ?? "";
    let ancestorGuids: string[] = updatedSW.activeStepHierarchy ?? [];
    yield put(expandAndScrollToStep({stepGuid, ancestorGuids}));

  } catch (err: any) {
    //yield put(addRCContainerInSteps());  --commented out by rhaas on 11/19/2024 to fix bug 5328920
    yield put(
      setUpdateOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
    yield put(setSubmitForReviewFlag({ submitForReviewFlag: false }));
  }
}

function* loadConfidentialEnabledUsersAsync(action: Action) {
  if (!loadConfidentialEnabledUsers.match(action)) {
    return;
  }
  yield put(
    setConfidentialUsersLoadOperation({
      isWorking: true,
    })
  );

  try {
    const tccUsers: IConfidentialSWUser[] = yield call(
      SWApi.getConfidentialEnabledUsers,
      action.payload.swId
    );

    yield put(setConfidentialEnabledUserList(tccUsers));
    yield put(setConfidentialUsersLoadOperation({ isWorking: false }));
  } catch (err: any) {
    yield put(
      setConfidentialUsersLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* addConfidentialAccessAsync(action: Action) {
  if (!addConfidentialAccess.match(action)) {
    return;
  }
  yield put(
    setConfidentialAccessChangeOperation({
      isWorking: true,
    })
  );

  try {
    yield call(
      SWApi.modifyConfidentialEnabledUsers,
      action.payload.swId,
      action.payload.userId,
      action.payload.role,
      "add"
    );

    yield put(setConfidentialAccessChangeOperation({ isWorking: false }));
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(
      showErrorToast("Error while trying to add access: " + errorMessage)
    );
    
    yield put(
      setConfidentialAccessChangeOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  } finally {
    yield put(loadConfidentialEnabledUsers({swId: action.payload.swId}));
  }
}

function* removeConfidentialAccessAsync(action: Action) {
  if (!removeConfidentialAccess.match(action)) {
    return;
  }
  yield put(
    setConfidentialAccessChangeOperation({
      isWorking: true,
    })
  );

  try {
    yield call(
      SWApi.modifyConfidentialEnabledUsers,
      action.payload.swId,
      action.payload.userId,
      action.payload.role,
      "remove"
    );

    yield put(setConfidentialAccessChangeOperation({ isWorking: false }));
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);
    
    yield put(
      showErrorToast("Error while trying to remove access: " + errorMessage)
    );

    yield put(
      setConfidentialAccessChangeOperation({
        isWorking: false,
        errorMessage,
      })
    );
  } finally {
    yield put(loadConfidentialEnabledUsers({swId: action.payload.swId}));
  }
}

function* watchUnlockSWCurrentEditor() {
  yield takeLatest(unlockSWCurrentEditor, unlockSWCurrentEditorLockAsync);
}

function* unlockSWCurrentEditorLockAsync(action: Action) {
  if (!unlockSWCurrentEditor.match(action)) {
    return;
  }
  yield put(
    setUpdateOperation({
      isWorking: true,
    })
  );

  try {
    let unlockSWCurrentEditor: IUnlockSWCurrentEditor = yield call(SWApi.unlockSWCurrentEditorLock,
      action.payload.swGuid,
      action.payload.owningOrgId,
      action.payload.owningPlantId);
    yield put(finishUnlockSWCurrentEditor(unlockSWCurrentEditor));
    yield put(showSuccessToast(`The standard work is unlocked and lock is transferred for your login.`));
  } catch (err: any) {
    yield put(
      setUpdateOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* createRCDraftAsync(action: Action) {
  if (!createRCDraft.match(action)) {
    return;
  }
  let url: string = "";

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );

  try {
    let sw: IGetRCSW = yield call(
      SWApi.getRCGuid,
      action.payload.rcID,
    );

    if (sw.draftExists) {
      url = Routes.EditStandardWork.replace(":guid", sw.guid ? sw.guid : "0").replace(":version", "0");
    }
    else {
      const params: ICreateSWDraftParams = {
        guid: sw.guid,
        version: sw.version,
        duplicate: false,
        swType: SWTypes.Unspecified,
        newOwningOrgId: undefined,
        newOwningPlantId: undefined,
        updateGemsRevision: undefined,
      };

      let swGuid: string = yield call(SWApi.createSWDraft, params);

      url = Routes.EditStandardWork.replace(":guid", swGuid ? swGuid : "0").replace(":version", "0");
    }

    window.open(url);

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );

  } catch (err: any) {
    alert("Error in fetching RC Guid from server");

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
  }
}

function* fetchRCNoticeAsync(action: Action) {
  if (!fetchRCNotice.match(action)) {
    return;
  }

  var notice: INoticeComponent | undefined;
  let data = action.payload;

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );
  try {
    let sw: ISW;

    sw = yield call(
      SWApi.getSW,
      data.rcGuid,
      data.rcVersion,
    );

    //check if this notice already exists in sw
    const parentSW: ISW = yield select((store: RootState) => store.manageSW.SW);

    if (!data.stepGuid) {
      notice = parentSW.notices.find(x => x.rcID === sw.id);
    }
    else {
      let [step] = findStep(data.stepGuid, parentSW.steps, 0);

      if (!step) {
        return;
      }
      var components = step.components.filter(x => x.type === StepComponentTypes.Notice) as INoticeComponent[];
    }

    // Copy all RC Blobs to current SW
    yield call(SWApi.copyRCAttachments,
      data.rcGuid,
      data.rcVersion,
      data.swGuid)

    // add to step notices
    yield put(addRCNotices({
      notice: sw.notices.length > 0 ? sw.notices[0] : null,
      stepGuid: data.stepGuid,
      rcTitle: sw.title,
      currentNoticeGuid: data.currentNoticeGuid,
      isRCComponent: data.isRCComponent,
      rcID: data.rcID,
      rcVersion: data.rcVersion,
      rcGuid: data.rcGuid,
    }));

    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
  } catch (err: any) {
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
    yield put(
      showErrorToast(
        getResponseErrorMessage(err)
      )
    );
  }
}

function* fetchRCStepsAsync(action: Action) {
  if (!fetchRCSteps.match(action)) {
    return;
  }
  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );

  yield put(setPendingCompatibilityFetchRCStepsAction(undefined));

  try {
    let sw: ISW;
    sw = yield call(
      SWApi.getSW,
      action.payload.rcGuid,
      action.payload.rcVersion,
    );

    if (
      !action.payload.ignoreIncompatibility &&
      sw.compatibleSWTypes.length > 0 &&
      !sw.compatibleSWTypes.includes(action.payload.swType)) {
      yield put(
        setLoadOperation({
          isWorking: false,
        })
      );

      yield put(
        showErrorToast(
          "The selected Reusable Content is not compatible with the selected Standard Work type."
        )
      );

      yield put(setPendingCompatibilityFetchRCStepsAction({...action.payload}));
      return;
    }

    // Copy all RC Blobs to current SW
    yield call(SWApi.copyRCAttachments,
      action.payload.rcGuid,
      Number(action.payload.rcVersion),
      action.payload.swGuid)

    if (sw.rcType === RCTypes.SubStep) {
      yield put(addRCSubSteps({
        rcSteps: sw.steps,
        rcID: sw.id,
        rcTitle: sw.title,
        rcType: sw.rcType,
        parentStepGuid: action.payload.stepGuid,
      }));
    }
    else {
      yield put(addRCSteps({
        rcSteps: sw.steps,
        rcID: sw.id,
        isRCTask: action.payload.isRCTask,
        stepGuid: action.payload.stepGuid,
        rcTitle: sw.title,
      }));
    }
    // yield put( updateStepSortOrder(newSteps);)
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
  } catch (err: any) {
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
    yield put(
      showErrorToast(
        getResponseErrorMessage(err)
      )
    );
  }
}

function* fetchRCTimeStepsAsync(action: Action) {
  if (!fetchRCTimeSteps.match(action)) {
    return;
  }
  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );
  try {
    if (action.payload.source === "TIME") {
      let timeRCRequestData: ITimeRCRequestData = yield call(
        SWApi.getTimeRCSW,
        action.payload.rcGuid,
        action.payload.swGuid,
        action.payload.swType,
        action.payload.isRCTask ? "true" : "false",
        action.payload.rcType,
        action.payload.isRCTask ? undefined : action.payload.stepGuid
      );

      yield put(
        setTimeRCRequestData(
          timeRCRequestData
        )
      );

      yield put(clearFilter())
      yield put(
        redirectToSearchFromTimeRC(true)
      );
    }
  } catch (err: any) {
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
    yield put(
      showErrorToast(
        getResponseErrorMessage(err)
      )
    );
  }
}

function* watchLoad() {
  yield takeLatest(load, loadAsync);
}

function* loadAsync(action: Action) {
  if (!load.match(action)) {
    return;
  }

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );
    let sw: ISW;
    try {
      sw = yield call(
        SWApi.getSW,
        action.payload.guid,
        action.payload.version,
      );
    } catch (err: any) {
      yield put(
        setLoadOperation({
          isWorking: false,
          errorMessage: '' + err
        })
      );
      return;
    }
    let configParameter: IConfigParameters[] = yield call(MasterDataApi.getConfigParameters);
    var enableMeters = configParameter.find(x => x.key.toLowerCase() === "enablemeters");
    var enableFormulaComponent = configParameter.find(x => x.key.toLowerCase() === "enableformulacomponent");
    var enableConfiguredMFGPlantsInTLM = configParameter.find(x => x.key.toLowerCase() === "enableconfiguredmfgplantsintlm");
    var enablePhotoInputComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enablephotoinputcomponentmfg");
    var enableTimeImageComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enabletimeimagecomponentmfg");
    var enableNoticeComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enablenoticecomponentmfg");
    var enableTableComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enabletablecomponentmfg");
    var enableSingleListComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enablesinglelistcomponentmfg");
    var enableMultiListComponentMFG = configParameter.find(x => x.key.toLowerCase() === "enablemultilistcomponentmfg");
    var disableMFGInputComponent = configParameter.find(x => x.key.toLowerCase() === "disablemfginputcomponent");
    var disableMFGContentComponent = configParameter.find(x => x.key.toLowerCase() === "disablemfgcontentcomponent");
    yield put(setConfigParaMeterFlag({keyName: "enableMeters", value: enableMeters ? enableMeters.value : "false"}))
    yield put(setConfigParaMeterFlag({keyName: "enableFormulaComponent", value: enableFormulaComponent ? enableFormulaComponent.value : "false" }))
    yield put(setConfigParaMeterFlag({keyName: "enableConfiguredMFGPlantsInTLM", value: enableConfiguredMFGPlantsInTLM ? enableConfiguredMFGPlantsInTLM.value : "false" }))
    yield put(setConfigParaMeterFlag({keyName: "enablePhotoInputComponentMFG", value: enablePhotoInputComponentMFG ? enablePhotoInputComponentMFG.value : "false"}))
    yield put(setConfigParaMeterFlag({keyName: "enableTimeImageComponentMFG", value: enableTimeImageComponentMFG ? enableTimeImageComponentMFG.value : "false"}))
    yield put(setConfigParaMeterFlag({keyName: "enableNoticeComponentMFG", value: enableNoticeComponentMFG ? enableNoticeComponentMFG.value : "false" }))
    yield put(setConfigParaMeterFlag({keyName: "enableTableComponentMFG", value: enableTableComponentMFG ? enableTableComponentMFG.value : "false" }))
    yield put(setConfigParaMeterFlag({keyName: "enableSingleListComponentMFG", value: enableSingleListComponentMFG ? enableSingleListComponentMFG.value : "false"}))
    yield put(setConfigParaMeterFlag({keyName: "enableMultiListComponentMFG", value: enableMultiListComponentMFG ? enableMultiListComponentMFG.value : "false"}))
    yield put(setConfigParaMeterFlag({keyName: "disableMFGInputComponent", value: disableMFGInputComponent ? disableMFGInputComponent.value : ""}))
    yield put(setConfigParaMeterFlag({keyName: "disableMFGContentComponent", value: disableMFGContentComponent ? disableMFGContentComponent.value : "" }))
    yield put(finishLoading(sw));
    yield put(addRCContainerInSteps());
    yield put(loadAllSWComments({ swGuid: sw.guid, swVersion: sw.version }));

}

function* getAllSiteLocationsAsync(action: Action)
{
  if(!getAllSiteLocations.match(action))
  {
    return;
  }
  let siteLocationsResponse: ISiteLocationsData = yield call(
    SWApi.getSiteLocations
  );
  yield put(setAllSiteLocations(siteLocationsResponse.siteLocations))
}

function* getNoticeImageUrlAsync(action: Action) {
  if (!getNoticeImageUrl.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetNoticeImageUrl,
      action,
      updateApprovalNoticeImageBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetNoticeImageUrl,
      action,
      updateNoticeImageBlobData
    );
  }
}

type updateNoticeImageBlobDataMethodTypes =
  typeof updateApprovalNoticeImageBlobData
  | typeof updateNoticeImageBlobData;

function* handleGetNoticeImageUrl(action: Action,
  updateAction: updateNoticeImageBlobDataMethodTypes) {
  if (!getNoticeImageUrl.match(action)) {
    return;
  }

  if (!action.payload.filename) {
    return
  }

  yield put(
    updateAction({
      noticeGuid: action.payload.noticeGuid,
      stepGuid: action.payload.stepGuid,
      isDirty: action.payload.isDirty,
      filename: action.payload.filename,
      blob: {
        filename: action.payload.filename,
        isLoading: true,
        blobUrl: "",
      },
      imageType: findNoticeImageType(action.payload.filename),
    })
  );

  let blobUrl: string = "";

  try {
    blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename
    );
  } catch (err: any) {
    yield put(
      updateAction({
        noticeGuid: action.payload.noticeGuid,
        stepGuid: action.payload.stepGuid,
        filename: action.payload.filename,
        blob: {
          filename: action.payload.filename,
          isLoading: false,
          blobUrl: "",
          errorMessage: `Failed to load image: ${getResponseErrorMessage(
            err
          )}`,
        },
        imageType: findNoticeImageType(action.payload.filename),
      })
    );
    return;
  }

  yield put(
    updateAction({
      noticeGuid: action.payload.noticeGuid,
      stepGuid: action.payload.stepGuid,
      isDirty: action.payload.isDirty,
      filename: action.payload.filename,
      blob: {
        filename: action.payload.filename,
        isLoading: false,
        blobUrl,
      },
      imageType: findNoticeImageType(action.payload.filename),
    })
  );
}

function* uploadNoticeImageAsync(action: Action) {
  if (!uploadNoticeImage.match(action)) {
    return;
  }

  try {
    yield put(
      setFileUploadOperation({
        isWorking: true,
      })
    );

    if (action.payload.file !== undefined) {
      let uploadResponse: IUploadSWAttachmentResponse;

      try {
        uploadResponse = yield call(
          SWApi.uploadSWAttachment,
          action.payload.swGuid,
          action.payload.swVersion,
          action.payload.file
        );
      } catch (err: any) {
        const errorMessage = getResponseErrorMessage(err);

        yield put(
          showErrorToast("Failed to upload attachment: " + errorMessage)
        );

        yield put(
          setFileUploadOperation({
            isWorking: false,
            errorMessage,
          })
        );

        return;
      }

      yield put(
        getNoticeImageUrl({
          swGuid: action.payload.swGuid,
          swVersion: action.payload.swVersion,
          filename: uploadResponse.filename,
          noticeGuid: action.payload.noticeGuid,
          stepGuid: action.payload.stepGuid,
          isDirty: true,
          destination: ImageDataDestinations.ManageSW,
          imageType: findNoticeImageType(uploadResponse.filename),
        })
      );
    }
    else {
      yield put(
        updateNoticeImageBlobData({
          noticeGuid: action.payload.noticeGuid,
          stepGuid: action.payload.stepGuid,
          isDirty: true,
          filename: "",
          blob: {
            filename: "",
            isLoading: false,
            blobUrl: "",
          },
          imageType: findNoticeImageType(""),
        })
      );
    }

    yield put(setFileUploadOperation(undefined));
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to upload notice image: " + err)
      )
    );
    yield put(setFileUploadOperation(undefined));
    return;
  }
}

function* downloadNoticeTimeImageAsync(action: Action) {
  if (!downloadNoticeTimeImage.match(action)) {
    return;
  }
  try {
    yield put(
      setFileUploadOperation({
        isWorking: true,
      })
    );

    if (
      action.payload.link !== "" ||
      action.payload.imageNumber !== 0 ||
      action.payload.label !== ""
    ) {
      const data = action.payload;
      const uploadResponse: IUploadSWAttachmentResponse = yield call(SWApi.uploadTimeImage,
        data.swGuid, data.swVersion, data.label, data.link, data.imageNumber.toString());

      yield put(
        getNoticeImageUrl({
          swGuid: action.payload.swGuid,
          swVersion: action.payload.swVersion,
          filename: uploadResponse.filename,
          noticeGuid: action.payload.noticeGuid,
          stepGuid: action.payload.stepGuid,
          isDirty: true,
          destination: ImageDataDestinations.ManageSW,
          imageType: findNoticeImageType(uploadResponse.filename),
        })
      );
    }
    else {
      yield put(
        updateNoticeImageBlobData({
          noticeGuid: action.payload.noticeGuid,
          stepGuid: action.payload.stepGuid,
          isDirty: true,
          filename: "",
          blob: {
            filename: "",
            isLoading: false,
            blobUrl: "",
          },
          imageType: findNoticeImageType(""),
        })
      );
    }

    yield put(
      setFileUploadOperation({
        isWorking: false,
        wasSuccessful: true,
      })
    );
  }
  catch (err: any) {
    yield put(
      showErrorToast("Failed to upload TIME image: " + err)
    );
    yield put(
      setFileUploadOperation({
        isWorking: false,
        wasSuccessful: false,
      })
    );
    return;
  }
}

function* uploadVideoAsync(action: Action) {
  if (!uploadVideo.match(action)) {
    return;
  }

  yield put(
    setFileUploadOperation({
      isWorking: true,
    })
  );

  let uploadResponse: IUploadSWAttachmentResponse;

  try {
    uploadResponse = yield call(
      SWApi.uploadSWAttachment,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.file
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(showErrorToast("Failed to upload attachment: " + errorMessage));

    yield put(
      setFileUploadOperation({
        isWorking: false,
        errorMessage,
      })
    );

    return;
  }

  yield put(
    getVideoUrl({
      swGuid: action.payload.swGuid,
      swVersion: action.payload.swVersion,
      filename: uploadResponse.filename,
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.componentGuid,
      isDirty: true,
      destination: ImageDataDestinations.ManageSW,
    })
  );

  yield put(setFileUploadOperation(undefined));
}

function* uploadAudioAsync(action: Action) {
  if (!uploadAudio.match(action)) {
    return;
  }

  yield put(
    setFileUploadOperation({
      isWorking: true,
    })
  );

  let uploadResponse: IUploadSWAttachmentResponse;

  try {
    uploadResponse = yield call(
      SWApi.uploadSWAttachment,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.file
    );
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);

    yield put(showErrorToast("Failed to upload attachment: " + errorMessage));

    yield put(
      setFileUploadOperation({
        isWorking: false,
        errorMessage,
      })
    );

    return;
  }

  yield put(
    getAudioUrl({
      swGuid: action.payload.swGuid,
      swVersion: action.payload.swVersion,
      filename: uploadResponse.filename,
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.componentGuid,
      isDirty: true,
      destination: ImageDataDestinations.ManageSW,
    })
  );

  yield put(setFileUploadOperation(undefined));
}

function* getVideoUrlAsync(action: Action) {
  if (!getVideoUrl.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetVideoAudioUrl,
      action,
      updateApprovalVideoBlobData
    );
  } else if (action.payload.destination === ImageDataDestinations.ManageSW) {
    yield call(handleGetVideoAudioUrl,
      action,
      updateVideoBlobData
    );
  }
}

function* getAudioUrlAsync(action: Action) {
  if (!getAudioUrl.match(action)) {
    return;
  }

  if (action.payload.destination === ImageDataDestinations.Approval) {
    yield call(handleGetVideoAudioUrl,
      action,
      updateApprovalAudioBlobData
    );
  }
  else {
    yield call(handleGetVideoAudioUrl,
      action,
      updateAudioBlobData
    );
  }
}

type updateVideoBlobDataMethodTypes =
  typeof updateApprovalVideoBlobData
  | typeof updateVideoBlobData
  | typeof updateAudioBlobData
  | typeof updateApprovalAudioBlobData;

function* handleGetVideoAudioUrl(action: Action,
  updateAction: updateVideoBlobDataMethodTypes) {
  if (!getVideoUrl.match(action) && !getAudioUrl.match(action)) {
    return;
  }
  if (!action.payload.filename) {
    return
  }

  yield put(
    updateAction({
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.componentGuid,
      isDirty: action.payload.isDirty,
      filename: action.payload.filename,
      blob: {
        filename: action.payload.filename,
        isLoading: true,
        blobUrl: "",
      },
    })
  );

  let blobUrl: string = "";

  try {
    blobUrl = yield call(
      SWApi.getSWBlobURL,
      action.payload.swGuid,
      action.payload.swVersion,
      action.payload.filename
    );
  } catch (err: any) {
    yield put(
      updateAction({
        stepGuid: action.payload.stepGuid,
        componentGuid: action.payload.componentGuid,
        filename: action.payload.filename,
        blob: {
          filename: action.payload.filename,
          isLoading: false,
          blobUrl: "",
          errorMessage: `Failed to load video: ${getResponseErrorMessage(
            err
          )}`,
        },
      })
    );
    return;
  }

  yield put(
    updateAction({
      stepGuid: action.payload.stepGuid,
      componentGuid: action.payload.componentGuid,
      isDirty: action.payload.isDirty,
      filename: action.payload.filename,
      blob: {
        filename: action.payload.filename,
        isLoading: false,
        blobUrl,
      },
    })
  );
}

function* resolveSWCommentAsync(action: Action) {
  if (!resolveSWComment.match(action)) {
    return;
  }

  try {

    yield put(setComments({
      loadOperation: {
        isWorking: true,
      },
    }));

    const response: IComment[] = yield call(SWApi.resolveSWComment,
      action.payload.commentId,
      action.payload.swId,
      action.payload.resolved);

    yield put(setComments({
      loadOperation: {
        isWorking: false,
        wasSuccessful: true,
      },
      comments: response,
    }));
    yield put(showSuccessToast("SW Comment Resolved"));
  } catch (err: any) {
    yield put(
      showErrorToast(
        getResponseErrorMessage("Failed to resolve sw comment: " + err)
      )
    );
    yield put(setComments({
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
      },
    }));
    return;
  }
}

function* watchGetConnectedSWIs()
{
  yield takeLatest(getConnectedSWIs, getConnectedSWIsAsync);
}
function* getConnectedSWIsAsync(action: Action)
{
  if(!getConnectedSWIs.match(action))
  {
    return;
  }

  yield put(
    setLoadOperation({
      isWorking: true,
    })
  );

  try {
    let connectedSWIsResponse: IConnectedSWIData = yield call(
      SWApi.getConnectedSWIs,
      action.payload.swGuid,
      action.payload.swVersion,
    );
    yield put(setSetConnectedSWIs(connectedSWIsResponse.connectedSWIs))
    
    yield put(
      setLoadOperation({
        isWorking: false,
      })
    );
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}
function* watchAddConnectedSWI()
{
  yield takeLatest(addConnectedSWI, addConnectedSWIAsync);
}
function* addConnectedSWIAsync(action: Action)
{
  if(!addConnectedSWI.match(action))
  {
    return;
  }

  try {
    yield put(
      setAddConnectedSWILoadOperation({
        isWorking: true,
      })
    );
    
    yield call(SWApi.addConnectedSWI, action.payload!!);
    yield put(getConnectedSWIs(
      {
        swGuid: action.payload.swGuid,
        swVersion: action.payload.swVersion
      }));
  
    yield put(
      setAddConnectedSWILoadOperation({
        isWorking: false,
        wasSuccessful: true
      })
    );
    yield put(showSuccessToast("New connected SWI saved."));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setAddConnectedSWILoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* watchDeleteConnectedSWI()
{
  yield takeLatest(deleteConnectedSWI, deleteConnectedSWIAsync);
}

function* deleteConnectedSWIAsync(action: Action)
{
  if(!deleteConnectedSWI.match(action))
  {
    return;
  }

  try {
    yield put(
      setLoadOperation({
        isWorking: true,
      })
    );
    
    yield call(SWApi.deleteConnectedSWI, action.payload!!);
    yield put(getConnectedSWIs(
      {
        swGuid: action.payload.swGuid,
        swVersion: action.payload.swVersion
      }));

    yield put(
      setLoadOperation({
        isWorking: false,
        wasSuccessful: true
      })
    );
    yield put(showSuccessToast("Connected SWI deleted."));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}

function* watchPasteSWComponentToSWDraftAsync()
{
  yield takeEvery(pasteSWComponentToSWDraft, pasteSWComponentToSWDraftAsync);
}

function* pasteSWComponentToSWDraftAsync(action: Action)
{
  if(!pasteSWComponentToSWDraft.match(action))
  {
    return;
  }
  
  try {
    yield put(
      setLoadOperation({
        isWorking: true,
      })
    );

    let copiedAttachments: ICopiedSWAttachment[] = []
    if(action.payload.filenames.length > 0) {
        copiedAttachments =
        yield call(SWApi.copySWAttachmentsToSWDraft, {
          sourceSWGuid: action.payload.sourceSWGuid,
          sourceSWVersion: action.payload.sourceSWVersion,
          fileNames: action.payload.filenames,
          destinationSWGuid: action.payload.swGuid
        });
    }
    
    yield put(pasteComponent({
        stepGuid: action.payload.stepGuid.toString() ?? "",
        operation: "PasteAtStepEnd",
        components: [action.payload.component],
        copiedAttachments: copiedAttachments
      })
    );
    
    yield put(
      setLoadOperation({
        isWorking: false,
        wasSuccessful: true
      })
    );
    yield put(showSuccessToast("Component pasted."));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(
      setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      })
    );
  }
}
