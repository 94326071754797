import { IFileData } from "interfaces/files/fileInterfaces";
import { ImageType } from "interfaces/sw/SWInterfaces";
import { v4 as uuidv4 } from "uuid";

const imageExtensions = [
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "gif",
  "tiff",
  "svg",
];

const previewableExtensions = [
  ...imageExtensions,
  "pdf",
];

const videoExtensions = [
  "mp4",
  "avi",
  "mov",
];

const audioExtensions = [
  "m4a",
  "mp3",
  "wav"
];

export const imagesFilter = imageExtensions
  .map(x => `.${x}`)
  .join(', ');

export const filesFilter = previewableExtensions
  .map(x => `.${x}`)
  .join(',');

export const videoFilter = videoExtensions
  .map(x => `.${x}`)
  .join(', ');

export const audioFilter = audioExtensions
  .map(x => `.${x}`)
  .join(', ');

export function isFilenameImage(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!imageExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function isFilenamePreviewable(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!previewableExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function isFilenameVideo(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!videoExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function isFilenameAudio(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!audioExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function getFileSizeError(file: File): string | undefined {
  if (file.size > 5242880) {
    return "File must be 5 MB or smaller.";
  }

  return undefined;
}

export function downloadData(data: any, filename: string) {
  var textUrl = URL.createObjectURL(data);
  var element = document.createElement('a');
  element.setAttribute('href', textUrl);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function getVideoSizeError(file: File): string | undefined {
  if (file.size > 10485760) {
    return "Video must be 10 MB or smaller.";
  }

  return undefined;
}
export function getAudioSizeError(file: File): string | undefined {
  if (file.size > 10485760) {
    return "Audio must be 10 MB or smaller.";
  }

  return undefined;
}

export function addImageNumberToLabel(label: string, imageNumber: number): string {
  const lastDot = label.lastIndexOf('.');

  if (lastDot === -1) {
    return label;
  }

  const extension = label.substring(lastDot + 1);
  let filenamePart = label.substring(0, lastDot);
  return `${filenamePart}_${imageNumber}.${extension}`;
}

export function extractContent(s: string): string {
  const value = document.createElement('alt');
  value.innerHTML = s;
  return value.textContent || value.innerText;
}

export function findNoticeImageType(fileName: string): ImageType {
  if (fileName === "" || fileName === undefined || fileName === null) {
    return ImageType.Both;
  }

  let fileParts = fileName.split("_");
  const count = fileParts.length;
  const lastFilePart = fileParts[count - 1];
  const mediaId = lastFilePart.split(".")[0];
  return isNumeric(mediaId) ? ImageType.Time : ImageType.Normal;
}

export function isNumeric(str: string): boolean {
  if (typeof str !== 'string') {
    return false;
  }
  const num = Number(str);
  if (Number.isInteger(num) && num > 0) {
    return true;
  }
  return false;
}

export function addGuidToFilename(filename: string): string {
  let lastDotIx = filename.lastIndexOf('.');

  if (lastDotIx === -1) {
    throw Error("Unknown file extension. Please select a valid file.");
  }

  let filenamePart = filename.substring(0, lastDotIx);
  let fileExtension = filename.substring(lastDotIx + 1);

  return `${filenamePart}_${uuidv4()}.${fileExtension}`;
}

export function getMediaNumberFromLabel(filename: string): string {
  let expectedValueArray: string[] = filename.split("_");
  let count = expectedValueArray.length;

  let lastFilePart = expectedValueArray[count - 1];
  return lastFilePart.split(".")[0];
}

export const readFileAsDataUri = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      reject(reader.error);
    }

    reader.onabort = () => {
      reject("File read was aborted.");
    }

    reader.readAsDataURL(file);
  });

export async function validateAndReadFile(file: File): Promise<IFileData> {
  const validationErr = getFileSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);

  return {
    filename,
    dataUri,
  };
}

export async function validateAndReadVideoFile(file: File): Promise<IFileData> {
  if (!isFilenameVideo(file.name)) {
    throw new Error("Please select a valid video.");
  }

  const validationErr = getVideoSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);

  return {
    filename,
    dataUri,
  };
}

export async function validateAndReadAudioFile(file: File): Promise<IFileData> {
  if (!isFilenameAudio(file.name)) {
    throw new Error("Please select a valid video.");
  }

  const validationErr = getAudioSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);

  return {
    filename,
    dataUri,
  };
}

export function getImageName (input: string): string | null {
  const segments = input.split(/[/\\]/);
  const imageName = segments.pop();
  return imageName ? imageName.replace(/\?.*$/, '') : null;
}