import Banner, { BannerType } from "components/common/Banner";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IMultiSelectInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import ItemPicker from "../controls/ItemPicker";

interface IMultiSelectInputProps {
  component: IMultiSelectInputComponent,
  isDisabled: boolean,
}

const MultiSelectInput: React.FC<IMultiSelectInputProps> = ({ component, isDisabled }) => {
  const [value, setValue] = useState([""]);
  const [isExpectedValue, setIsExpectedValue] = useState(true);
  let meter = component.meterCode === undefined ? "" : component.meterCode;
  const onItemAdded = (item: IMasterDataItem) => {
    let compResponses: string[] | undefined;
    compResponses = value
      ? [...value, item.value]
      : [item.value]
    setValue(compResponses);
    setIsExpectedValue(true);

    if (expectedValue !== undefined) {

      // Convert string to array to check the expected value.
      let expectedValueArray: string[] = expectedValue.split(",").sort();
      if (JSON.stringify(expectedValueArray) !== JSON.stringify(compResponses.sort())) {
        setIsExpectedValue(false);
      }
    }
  }

  const onItemRemoved = (item: IMasterDataItem) => {
    setValue(value.filter(x => x !== item.value))
    setIsExpectedValue(true);

    // Convert string to array to check the expected value.
    if (expectedValue !== undefined) {
      let expectedValueArray: string[] = expectedValue.split(",").sort();
      if (JSON.stringify(expectedValueArray) !== JSON.stringify(value.sort())) {
        setIsExpectedValue(false);
      }
    }
  }

  let options: string[] = component.options || [];
  let nonConform: string | undefined = component.nonConform;
  let expectedValue: string | undefined = component.expectedValue?.join(",");
  let shouldShowConformInfo = expectedValue !== undefined;
  let existingResponseNonConform = false;

  if (value && (value.length !== 0 && value !== undefined)) {
    let selectedValue = value;

    if (expectedValue !== undefined) {
      let expectedValueArray = expectedValue.split(",");
      let sortedArray: string[] = [...selectedValue].sort();
      let sortedExpectedArray: string[] = [...expectedValueArray].sort();
      if (JSON.stringify(sortedArray) !== JSON.stringify(sortedExpectedArray)) {
        existingResponseNonConform = true;
      }
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (!isExpectedValue
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <ItemPicker
        label={component.label || ""}
        meter={meter}
        selectedItems={options?.map(x => ({
          guid: x,
          value: x,
        })) || []}
        masterDataSection={{
          items: options.map(x => ({
            guid: x,
            value: x,
          }))
        }}
        loadItems={() => { }}
        itemFormatter={(item) => item.value}
        onAddItem={onItemAdded}
        onRemoveItem={onItemRemoved}
        disabled={isDisabled}
        allowMultiple={true}
      />
    </div>
  );
}

export default MultiSelectInput;