import Banner, { BannerType } from "components/common/Banner";
import React, { useRef, useState } from "react";
import useSelector from "store/useSelector";
import SwRepoRow from "./SwRepoRow";
import FlowLayout from "components/layout/FlowLayout";
import SearchSwRepoBar from "./SearchSwRepoBar";
import "./SearchSwRepo.scoped.scss";
import { useDispatch } from "react-redux";
import { setSearchText, setSwRepoListData } from "store/ManageSwReports/manageSwReportsActions";
import ModalSpinner from "components/common/ModalSpinner";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import upArrowIcon from "media/icons/dls/arrow-up-2.svg";
import sortIcon from "media/icons/dls/sort.svg";
import { ISwRepoSummaryItem } from "store/ManageSwReports/ManageSwReportsTypes";

const SearchSwRepo: React.FC = () => {
    const {
        manageSwReports: {
            loadOperation,
            swList,
        }
    } = useSelector(store => store);
    const [sortConfig, setSortConfig] = useState<{ key: keyof ISwRepoSummaryItem; direction: 'asc' | 'desc' } | null>(null);
    const dispatch = useDispatch();
    const handleSort = (key: keyof ISwRepoSummaryItem) => {
        let direction: 'asc' | 'desc' = 'asc';

        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedData = [...swList].sort((a, b) => {
            if (key === 'publishedDate') {
                const dateA = a.publishedDate ? new Date(a.publishedDate).getTime() : 0;
                const dateB = b.publishedDate ? new Date(b.publishedDate).getTime() : 0;
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            }
            else if (key === 'version') {
                const versionA = Number(a.version);
                const versionB = Number(b.version);
                return direction === 'asc' ? versionA - versionB : versionB - versionA;
            }
            else {
                if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                return 0;
            }
        });

        setSortConfig({ key, direction });
        dispatch(setSwRepoListData(sortedData));
    };

    const getSortIcon = (key: keyof ISwRepoSummaryItem) => {
        if (!sortConfig || sortConfig.key !== key) {
            return sortIcon;
        }
        return sortConfig.direction === 'asc' ? upArrowIcon : downArrowIcon;
    };

    let onSetSearchRef = useRef((value: string) => dispatch(setSearchText(value)));
    let component: JSX.Element | undefined;

    if (loadOperation?.errorMessage) {
        component = (
            <Banner
                type={BannerType.Error}
            >
                {loadOperation.errorMessage}
            </Banner>
        );
    } else if (!swList.length) {
        component = (
            <label>No StandardWorks found.</label>
        );
    } else {
        component = (
            <>
                <div className="row header">
                    <div onClick={() => handleSort('title')}
                    >
                        <span>Standard Work Name</span>&nbsp;
                        <img
                            src={getSortIcon('title')}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </div>
                    <div className="cell sw-name swname"
                        onClick={() => handleSort('UID')}
                    >
                        <span>UID</span>&nbsp;
                        <img
                            src={getSortIcon('UID')}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </div>
                    <div className="cell pub"
                        onClick={() => handleSort('version')}
                    >
                        <span>Version</span>&nbsp;
                        <img
                            src={getSortIcon('version')}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </div>
                    <div className="cell pub"
                        onClick={() => handleSort('publishedDate')}
                    >
                        <span>Published Date</span>&nbsp;
                        <img
                            src={getSortIcon('publishedDate')}
                            alt=""
                            className="icon-extra-small-sort"
                        />
                    </div>
                    <div className="cell pub">
                        <span></span>
                    </div>
                </div>
                <div className="list-body">
                    {swList.map(sw =>
                        <SwRepoRow
                            sw={sw}
                            key={sw.id}
                        />
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <br />
            <FlowLayout
                header={
                    <SearchSwRepoBar
                        setSearchText={onSetSearchRef.current}
                    />
                }
            >
                {component}
                {loadOperation?.isWorking &&
                    <ModalSpinner />
                }
            </FlowLayout>
        </>
    );
};

export default SearchSwRepo;