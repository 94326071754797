import { authGetJson, authPostJson } from "utilities/msalFetches";
import config from "config";
import { throwIfResponseError } from "apis/apiUtilities";
import {
  formatOrganizations,
  formatLanguages,
  formatCountries,
  formatTasks,
  formatServiceTypes,
  formatCompetencyElements,
  formatActivityWorkflows,
  formatOperatingEnvironments,
  formatEquipment,
  formatSafetyCategories,
  formatEquivalentStandardWorks,
  formatEpicEquipment,
  formatBusinessRoles,
  formatProductCenters,
  formatGeoUnits,
  formatCustomers,
  formatSubBusinessLines,
  formatOwningPlants,
  formatPerformingPlants,
  formatMaterials,
  formatWorkCenters,
  formatHazardCategories,
  formatOperationTree,
  formatGroups,
  formatGroupCounter,
  formatQTracOOSs,
  formatUoms,
  formatEvents,
  formatMetersResponse,
  formatMetersRequest,
  formatConfigParameters,
  formatGemsRevisionNumbers,
  formatPotentialLoss
} from "./masterDataFormatters";
import { IConfigParameters, IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { SWTypes, PlantSource } from "interfaces/sw/SWInterfaces";
import { IOperationTreeModel } from "store/masterData/masterDataTypes";

class MasterDataApi {
  public async getCountries(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatCountries(
      await this.getMasterData(config.endpoints.masterData.getCountries
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getGeoUnits(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatGeoUnits(
      await this.getMasterData(config.endpoints.masterData.getGeoUnits
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getLanguages(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatLanguages(
      await this.getMasterData(config.endpoints.masterData.getLanguages
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getOrganizations(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatOrganizations(
      await this.getMasterData(config.endpoints.masterData.getOrganizations
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getSubBusinessLines(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatSubBusinessLines(
      await this.getMasterData(config.endpoints.masterData.getSubBusinessLines
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getSafetyCategories(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatSafetyCategories(
      await this.getMasterData(config.endpoints.masterData.getSafetyCategories
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getOwningPlants(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatOwningPlants(
      await this.getMasterData(config.endpoints.masterData.getPlants
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getHazardCategories(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatHazardCategories(
      await this.getMasterData(config.endpoints.masterData.getHazardCategories
        + `?onlyActiveStatus=${onlyActive}`));
  }
    public async getPotentialLoss(onlyActive: boolean): Promise<IMasterDataItem[]> {
        return formatPotentialLoss(
          await this.getMasterData(config.endpoints.masterData.getPotentialLoss
              + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getPerformingPlants(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatPerformingPlants(
      await this.getMasterData(config.endpoints.masterData.getPlants
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getQTracOOS(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatQTracOOSs(
      await this.getMasterData(config.endpoints.masterData.getQTracOOS
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async searchActivityWorkflows(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatActivityWorkflows(
      await this.getMasterData(config.endpoints.masterData.searchActivityWorkflows
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchEquivalentSWs(searchTerm: string, SWType: SWTypes): Promise<IMasterDataItem[]> {
    return formatEquivalentStandardWorks(
      await this.getMasterData(config.endpoints.masterData.searchEquivalentSWs
        + `?searchTerm=${searchTerm}&SWType=${SWType}`), SWType);
  }

  public async searchCompetencyElements(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatCompetencyElements(
      await this.getMasterData(config.endpoints.masterData.searchCompetencyElements
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchEquipment(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatEquipment(
      await this.getMasterData(config.endpoints.masterData.searchEquipment
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchEpicEquipment(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatEpicEquipment(
      await this.getMasterData(config.endpoints.masterData.searchEpicEquipment
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchBusinessRoles(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatBusinessRoles(
      await this.getMasterData(config.endpoints.masterData.searchBusinessRoles
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchProductCenters(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatProductCenters(
      await this.getMasterData(config.endpoints.masterData.searchProductCenters
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchCustomers(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatCustomers(
      await this.getMasterData(config.endpoints.masterData.searchCustomers
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchOperatingEnvironments(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatOperatingEnvironments(
      await this.getMasterData(config.endpoints.masterData.searchOperatingEnvironments
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchServiceTypes(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatServiceTypes(
      await this.getMasterData(config.endpoints.masterData.searchServiceTypes
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchTasks(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatTasks(
      await this.getMasterData(config.endpoints.masterData.searchTasks
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchMaterials(searchTerm: string, plantCode: string, onlyActive: boolean, plantSource?: PlantSource): Promise<IMasterDataItem[]> {
    searchTerm = encodeURIComponent(searchTerm);

    let materialsUrl = `?searchTerm=${searchTerm}&plantCodes=${plantCode ? plantCode : ""}&onlyActiveStatus=${onlyActive}`;
    if (plantSource) {
      materialsUrl = materialsUrl + `&plantSource=${plantSource}`;
    }
    return formatMaterials(
      await this.getMasterData(config.endpoints.masterData.searchMaterials
        + materialsUrl));
  }

  public async loadGemsRevisionNumbers(
    plantCode: string | undefined,
    materialCode: string | undefined,
    onlyActive: boolean,
    plantSource: PlantSource | undefined
  ): Promise<IMasterDataItem[]> {
    materialCode = encodeURIComponent(materialCode ?? "");
    let urlParams = `?plantCode=${plantCode}&materialCode=${materialCode}&onlyActiveStatus=${onlyActive}`;

    if (plantSource) {
      urlParams = urlParams + `&plantSource=${plantSource}`;
    }

    return formatGemsRevisionNumbers(
      await this.getMasterData(
        config.endpoints.masterData.loadGemsRevisionNumbers + urlParams
      )
    );
  }

  public async searchOwningPlants(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatOwningPlants(
      await this.getMasterData(config.endpoints.masterData.getPlants
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async getConfiguredOwningPlants(onlyConfiguredPlants: boolean, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatOwningPlants(
      await this.getMasterData(config.endpoints.masterData.getPlants
        + `?onlyConfiguredPlants=${onlyConfiguredPlants}&onlyActiveStatus=${onlyActive}`));
  }

  public async searchPerformingPlants(searchTerm: string, onlyActive: boolean, plantSource?: PlantSource): Promise<IMasterDataItem[]> {
    let plantsUrl = `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}&PerformingPlants=true`;
    if (plantSource) {
      plantsUrl = plantsUrl + `&plantSource=${plantSource}`;
    }
    return formatPerformingPlants(
      await this.getMasterData(config.endpoints.masterData.getPlants
        + plantsUrl));
  }

  public async searchWorkCenters(searchTerm: string, plantCode: string, onlyActive: boolean, plantSource?: PlantSource): Promise<IMasterDataItem[]> {
    let workCentersUrl = `?searchTerm=${searchTerm}&plantCodes=${plantCode ? plantCode : ""}&onlyActiveStatus=${onlyActive}`;
    if (plantSource) {
      workCentersUrl = workCentersUrl + `&plantSource=${plantSource}`;
    }
    return formatWorkCenters(
      await this.getMasterData(config.endpoints.masterData.searchWorkCenters
        + workCentersUrl));
  }

  public async searchEvents(searchTerm: string, onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatEvents(
      await this.getMasterData(config.endpoints.masterData.searchEvents
        + `?searchTerm=${searchTerm}&onlyActiveStatus=${onlyActive}`));
  }

  public async getOperationTree(
    plantCode: string,
    materialCode: string,
    group: string,
    groupCounter: number,
    plantSource?: PlantSource
  ): Promise<IOperationTreeModel> {
   
    const url = config.endpoints.masterData.getOperationTree;
    return formatOperationTree(await this.postMasterData(url,
      JSON.stringify({
        PlantCode:plantCode,
        MaterialCode:materialCode,
        Group:group,
        GroupCounter:groupCounter.toString(),
        PlantSource:plantSource
      })
    ));
  }

  public async getGroups(plantCode?: string, materialCode?: string, plantSource?: PlantSource): Promise<IMasterDataItem[]> {

      const url = config.endpoints.masterData.getGroups;
      return await formatGroups(await this.postMasterData(url,
      JSON.stringify({
        PlantCode:plantCode,
        MaterialCode: materialCode,
        PlantSource: plantSource
      })
    ));
  }

  public async getGroupCounter(
    plantCode: string,
    materialCode: string,
    group: string,
    plantSource?: PlantSource): Promise<IMasterDataItem[]> {
    const url = config.endpoints.masterData.getGroupCounter;
    return formatGroupCounter(await this.postMasterData(url,
        JSON.stringify({
        PlantCode:plantCode,
        MaterialCode: materialCode,
        Group:group,
        PlantSource: plantSource
      })
    ));
  }

  public async getUoms(onlyActive: boolean): Promise<IMasterDataItem[]> {
    return formatUoms(
      await this.getMasterData(config.endpoints.masterData.getUoms
        + `?onlyActiveStatus=${onlyActive}`));
  }

  public async getConfigParameters(): Promise<IConfigParameters[]> {
    const response = await authPostJson(
      config.endpoints.masterData.getConfigParameters,
      JSON.stringify({
        "Keys": [
          "EnableMeters",
          "EnableFormulaComponent",
          "EnablePhotoInputComponentMFG",
          "EnableTimeImageComponentMFG",
          "EnableNoticeComponentMFG",
          "EnableTableComponentMFG",
          "EnableSingleListComponentMFG",
          "EnableMultiListComponentMFG",
          "EnableReportsTab",
          "DisableMFGContentComponent",
          "DisableMFGInputComponent",
          "enableConfiguredMFGPlantsInTLM",
          "EnableConnectedSWI",
        ]
      })
    );
    await throwIfResponseError(response);

    return formatConfigParameters(await response.json());
  }


  public async getCustomizedEvents(onlyCustomizedEvents: boolean): Promise<IMasterDataItem[]> {
    return formatEvents(
      await this.getMasterData(config.endpoints.masterData.getCustomizedEvents + `?onlyCustomizedEvents=${onlyCustomizedEvents}`));
  }

  public async getMeters(equipmentCodes: IMasterDataItem[], stepLocation: string): Promise<IMasterDataItem[]> {
    const response = await authPostJson(
      config.endpoints.masterData.getMeters,
      JSON.stringify(formatMetersRequest(equipmentCodes, stepLocation))
    );

    await throwIfResponseError(response);

    return formatMetersResponse(await response.json());
  }
  private async getMasterData(url: string): Promise<any> {
    let response = await authGetJson(url);
    await throwIfResponseError(response);
    return await response.json();
  }

  private async postMasterData(url: string, data:any): Promise<any> {
    let response = await authPostJson(url, data);
    await throwIfResponseError(response);
    return await response.json();
  }

}

export default new MasterDataApi();