import React from 'react';
import '../FindAndReplace.scoped.scss'

interface RadioGroupProps {
  title: string;
  name: string;
  options: { value: string; label: string }[];
  selectedValue: string | null;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ title, name, options, selectedValue }) => {
  return (
    <div className="column">
      <div className="radio-group">
        {options.map((option) => (
          <label
            key={option.value}
            className={`radio-button ${selectedValue === option.value ? 'selected' : ''}`}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={selectedValue === option.value}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;