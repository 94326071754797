import React, { useEffect, useMemo } from "react";
import ManageSWAttributes from "./attributes/ManageSWAttributes";
import ManageSteps from "./steps/ManageSteps";
import Toolbar from "./toolbar/Toolbar";
import useSelector from "store/useSelector";
import { ManageSWTabs } from "store/manageSW/manageSWTypes";
import "./ManageSW.scoped.scss";
import { useDispatch } from "react-redux";
import { reset, setCreateOperation, setUpdateOperation, load, setPublishOperation, setType, setTLMSWIType, setTemplateType, setContentType } from "store/manageSW/manageSWActions";
import Modal, { ModalTheme } from "components/common/Modal";
import Banner, { BannerType } from "components/common/Banner";
import { Redirect, RouteComponentProps, Prompt, Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import ManageSWRefDocs from "./refDocs/ManageSWRefDocs";
import ManageSWComments from "./comments/ManageSWComments";
import ManageRevisionHistory from "./revisionHistory/ManageRevisionHistory";
import MFGManageConnectedSWIs from "./connectedSWIs/MFGManageConnectedSWIs";
import { setSubmitDraftOperation } from "store/approvals/approvalsActions";
import { AuthFilter } from "auth/AuthFilter";
import ManageSWNoticeSummary from "./noticeSummary/ManageSWNoticeSummary";

import {
  ConfidentialAccessRoles,
  IManageUserUser,
  Roles,
  UserApprovalLevels,
} from "interfaces/user/UserInterfaces";

import { ApprovalLevels } from "interfaces/approvals/approvalInterfaces";
import ApprovalHistory from "../../approvals/history/ApprovalHistory";
import FlowLayout from "components/layout/FlowLayout";
import { useMsal } from "@azure/msal-react";
import SWCommentList from "./SWCommentList";
import { ContentTypes, SWTypes, TLMSWIType, TemplateTypes } from "interfaces/sw/SWInterfaces";
import { getUserGroupSWTypes } from "utilities/sw/filterUtils";
import { loadCustomizedEvents, setSection } from "store/masterData/masterDataActions";

interface IManageSWProps {
  guid?: string,
  version?: string,
  mode? : string,
}

const canCreateSwType = (type: SWTypes, currentUser : IManageUserUser) => {
  if (currentUser.roles.includes(Roles.SysAdmin)) {
    return true;
  }

  if (!currentUser.groupAssignments) {
    return false;
  }

  const authorEnabledGroups = currentUser.groupAssignments
    .filter(
      (ga) =>
        ga.roles.includes(Roles.Author) ||
        ga.roles.includes(Roles.TechComm) ||
        ga.roles.includes(Roles.OrgAdmin) ||
        ga.roles.includes(Roles.SysAdmin)
    )
    .map((ga) => ga.group);

  const userGroupSwTypes = getUserGroupSWTypes();

  for (const userGroup of authorEnabledGroups) {
    if (
      userGroupSwTypes[userGroup] &&
      userGroupSwTypes[userGroup].includes(type)
    ) {
      return true;
    }
  }

  return false;
};


const ManageSW: React.FC<RouteComponentProps<IManageSWProps>> = (props) => {
  const {
    approvals: {
      submitDraftOperation,
    },
    manageSW: {
      currentTab,
      SW: {
        id,
        title,
        guid,
        version,
        activeApprovalRequestId,
        activeApprovalLevel,
        isLocked,
        currentEditor,
        owningOrg,
        owningPlant,
        type,
        gemsRevisionNumber,
      },
      createOperation,
      updateOperation,
      loadOperation,
      isDirty,
      publishOperation,
      redirectToSearchPageFromTimeRC,
    },
    auth: {
      currentUser,
    }
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const urlGuid = props.match.params.guid;
  const urlVersion = props.match.params.version;
  const urlMode = props.match.params.mode;

  const { accounts } = useMsal();
  const user = accounts[0];

  const isLoggedInUserNotSWCurrentEditor = () => {
    if (user && currentEditor !== null && user.username !== currentEditor) {
      return true;
    }

    return false;
  }

  let isDisabled = ((type === SWTypes.TLMSWI || type === SWTypes.TLMRC) &&
    currentUser.roles.find(x => x === Roles.TechComm || x=== Roles.ApproverCOE)?.length &&
    version === 0)
    ? false
    : (version > 0 || ((type === SWTypes.TLMSWI || type === SWTypes.TLMRC) && activeApprovalLevel !== undefined));

  if (!isDisabled
    && activeApprovalLevel) {
    if (activeApprovalLevel === ApprovalLevels.COE
      && AuthFilter
        .isLoggedIn()
        .hasRole(Roles.ApproverCOE)
        .hasApprovalLevel(UserApprovalLevels.COE)
        .isMatch(currentUser)) {
      isDisabled = false;
    } else if (type === SWTypes.TLMSWI) {
      isDisabled = false;
    }
    else {
      isDisabled = true;
    }
  }
  
  let orginalDisabled = isDisabled;
  let isLoggedInUserNotSWCurrentEditorUser = (currentEditor != null
    && isLoggedInUserNotSWCurrentEditor());
  if (isLocked
    || isLoggedInUserNotSWCurrentEditorUser) {
    isDisabled = true;
  }

  // Check Owning Org with Author role.
  if (!isDisabled
    && !activeApprovalLevel
    && guid !== ""
    && (((type !== SWTypes.MFGCL && type !== SWTypes.MFGRC && type !== SWTypes.MFGSWI) &&
      owningOrg && !currentUser.orgs.find(a => a.guid === owningOrg?.guid)) ||
      ((type === SWTypes.MFGCL || type === SWTypes.MFGRC || type === SWTypes.MFGSWI) &&
        owningPlant && !currentUser.plants.find(a => a.guid === owningPlant?.guid)))
    && !currentUser.confidentialSWAllocations.find(
      (a) => a.swId === id && a.role === ConfidentialAccessRoles.Editor
    )
    && !currentUser.roles.find(a => a === Roles.SysAdmin || a === Roles.ApproverCOE)
    && (!currentUser.roles.find(a => a === Roles.TechComm)
      || type !== SWTypes.TLMSWI)
    && !((type === SWTypes.TLMRC) &&
      (currentUser.roles.find(a => a === Roles.TechComm)
        || currentUser.roles.find(a => a === Roles.OrgAdmin)
        || currentUser.roles.find(a => a === Roles.Author)
      ))) {
    isDisabled = true
  }

  let enableGlobalLocal =
    (
      type === SWTypes.TLMSWI &&
      (
        (
          currentUser.roles.find(x => x === Roles.Author)?.length &&
          currentUser.orgs.find(a => a.guid === owningOrg?.guid)
        )
        || currentUser.roles.find(x => x === Roles.TechComm)?.length
        || currentUser.roles.find(x => x === Roles.SysAdmin)?.length
        ||
        (
          currentUser.roles.find(x => x === Roles.OrgAdmin)?.length &&
          currentUser.orgs.find(a => a.guid === owningOrg?.guid)
        )
      )
      && version === 0
      && !activeApprovalLevel
    )
      ? false
      : true;

  useEffect(() => {
    if (!urlGuid) {
      return;
    }
    cleanupMFGMetadata();
    dispatch(load({
      guid: urlGuid,
      version: parseInt(urlVersion || "", 10) || 0,
    }));
  }, [dispatch, urlGuid, urlVersion]);

  const cleanupMFGMetadata = () =>
  {
    dispatch(setSection({ name: "workCenters", section: { items: [] } }));
    dispatch(setSection({ name: "performingPlants", section: { items: [] } }));
    dispatch(setSection({ name: "materials", section: { items: [] } }));
  }

  useEffect(() => {
    if (createOperation?.wasSuccessful) {
      dispatch(setCreateOperation(undefined));
    }
  }, [createOperation, dispatch]);

  useEffect(() => {
    const onUnload = (e: Event) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isDirty]);

  const availableSwTypes = useMemo(
    () =>
      Object.values(SWTypes)
        .filter(
          (type) =>
            type !== SWTypes.Unspecified &&
            type !== SWTypes.LCL &&
            type !== SWTypes.TIMERC
        )
        .filter((t) => canCreateSwType(t, currentUser)),
    [currentUser]
  );

  const changeSwType = (type: SWTypes) => {
    dispatch(setType(type));
    if (type === SWTypes.TLMSWI) {
      dispatch(setTLMSWIType(TLMSWIType.Global));
      dispatch(setTemplateType(TemplateTypes.Standard));
      dispatch(setContentType(ContentTypes.Executable));
      dispatch(loadCustomizedEvents({ onlyCusomizedEvents: true }));
    }

    clearEquivalentSWs();
  };

  const clearEquivalentSWs = () => {
    dispatch(setSection({ name: "equivalentSWs", section: { items: [] } }));
  };

  useEffect(() => {
    if (urlMode === "create") {
      dispatch(reset());
      // clean up of Material, workcenters, peforming plants
      cleanupMFGMetadata();

      if (availableSwTypes.length > 0) {
        changeSwType(availableSwTypes[0]);
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, urlMode, availableSwTypes]);

  let mainComponent: JSX.Element | undefined;

  if (!urlGuid) {
    mainComponent = (
      <ManageSWAttributes
        isDisabled={(type !== SWTypes.MFGSWI ? isDisabled : (version > 0 ? true : false))}
        enableGlobalLocal={enableGlobalLocal}
        availableSwTypes={availableSwTypes}
        onSWTypeChange={changeSwType}
      />
    );
  } else {
    mainComponent = (
      <>
        {currentTab === ManageSWTabs.Attributes &&
          <ManageSWAttributes
            isDisabled={isDisabled}
            enableGlobalLocal={enableGlobalLocal}
            availableSwTypes={[type]}
            onSWTypeChange={() => void 0}
          />
        }
        {
          currentTab === ManageSWTabs.Steps &&
          <ManageSteps
            isDisabled={isDisabled}
          />
        }
        {
          currentTab === ManageSWTabs.RefDocs &&
          <ManageSWRefDocs
            isDisabled={isDisabled}
          />
        }
        {
          currentTab === ManageSWTabs.Comments &&
          <ManageSWComments
            swGuid={guid}
            swVersion={version}
          />
        }
        {
          currentTab === ManageSWTabs.RevisionHistory &&
          <ManageRevisionHistory
            swGuid={guid}
            swType={type}
          />
        }
        {
          currentTab === ManageSWTabs.ConnectedSWIs &&
          <MFGManageConnectedSWIs 
            isDisabled={isDisabled}
          />
        }
        {
          currentTab === ManageSWTabs.NoticeSummary &&
          <ManageSWNoticeSummary
              swGuid={guid}
              swVersion={version}
          />
        }
      </>
    );
  }

  const backgroundStyle = gemsRevisionNumber?.isOutdated
    ? { "backgroundColor": "lightyellow" }
    : {};

  return (
      <FlowLayout
        style={backgroundStyle}
        header={(
          <>
            <h2 className="page-title">
              {!urlGuid ? "Create Standard Work" : title}
            </h2>
            {!!urlGuid &&
              <Toolbar
                isSavable={(!orginalDisabled && !isLocked)}
                isSubmittable={!activeApprovalRequestId && !isLocked}
                isLoggedInUserNotSWCurrentEditorUser={isLoggedInUserNotSWCurrentEditorUser}
              />
            }
          </>
        )}
      >
        {mainComponent}
        {createOperation?.errorMessage &&
          <Modal
            header="Failed to Create"
            isOpen={true}
            theme={ModalTheme.Error}
            controls={(
              <button
                className="primary-button"
                onClick={() => dispatch(setCreateOperation())}
              >
                OK
              </button>
            )}
          >
            <Banner
              type={BannerType.Error}
            >
              {createOperation.errorMessage}
            </Banner>
          </Modal>
        }

        {publishOperation?.errorMessage &&
          <Modal
            header="Failed to Publish"
            isOpen={true}
            theme={ModalTheme.Error}
            controls={(
              <button
                className="primary-button"
                onClick={() => dispatch(setPublishOperation())}
              >
                OK
              </button>
            )}
          >
            <Banner
              type={BannerType.Error}
            >
              {publishOperation.errorMessage}
            </Banner>
          </Modal>
        }

        {updateOperation?.errorMessage &&
          <Modal
            header="Failed to Save"
            isOpen={true}
            theme={ModalTheme.Error}
            controls={(
              <button
                className="primary-button"
                onClick={() => dispatch(setUpdateOperation())}
              >
                OK
              </button>
            )}
          >
            <Banner
              type={BannerType.Error}
            >
              {updateOperation.errorMessage}
            </Banner>
          </Modal>
        }
        {loadOperation?.errorMessage &&
          <Modal
            header="Failed to Load"
            isOpen={true}
            theme={ModalTheme.Error}
            controls={(
              <button
                className="primary-button ok-button"
              >
                <Link
                  to={Routes.SearchStandardWork}
                >
                  OK
                </Link>
              </button>
            )}
          >
            <Banner
              type={BannerType.Error}
            >
              {loadOperation.errorMessage}
            </Banner>
          </Modal>
        }
        {submitDraftOperation?.errorMessage &&
          <Modal
            header="Failed to Submit for Approval"
            isOpen={true}
            theme={ModalTheme.Error}
            controls={(
              <button
                className="primary-button ok-button"
                onClick={() => setSubmitDraftOperation()}
              >
                OK
              </button>
            )}
          >
            <Banner
              type={BannerType.Error}
            >
              {submitDraftOperation.errorMessage}
            </Banner>
          </Modal>
        }
        {createOperation?.wasSuccessful &&
          <Redirect
            to={Routes.EditStandardWork.replace(":guid", guid).replace(":version", "0")}
          />
        }
        {redirectToSearchPageFromTimeRC &&
          <Redirect
            to={Routes.SearchStandardWork}
          />
        }
        <Prompt
          when={!!isDirty}
          message="You have unsaved changes that will be lost if you leave. Leave anyway?"
        />
        <ApprovalHistory />
        <SWCommentList />
      </FlowLayout>
  );
}

export default ManageSW;