import React, { useState } from "react";
import { NoticeTypes, INoticeComponent, SWTypes, ImageType, IAllowedRCControlsConfig } from "interfaces/sw/SWInterfaces";
import "./RCNoticeEditor.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import WarningIcon from "media/icons/dls/warning.svg";
import ErrorIcon from "media/icons/dls/error.svg";
import InfoIcon from "media/icons/dls/info.svg";
import { ImageDataDestinations, INoticeVisibility } from "store/manageSW/manageSWTypes";
import NoticeTimeImageEditor from "../../components/NoticeTimeImageEditor";
import NoticeImageEditor from "../../components/NoticeImageEditor";
import Collapsible from "components/common/Collapsible";
import { disableRcNoticeContent, removeComponent, updateRCNoticeLinkBreak } from "store/manageSW/manageSWActions";
import { useDispatch } from "react-redux";
import NoticeRichTextEditor from "../../components/NoticeRichTextEditor";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import useSelector from "store/useSelector";

interface IRCNoticeEditorProps {
    allowEdit?: boolean,
    notice: INoticeComponent,
    stepGuid?: string,
    swGuid: string,
    swVersion: number,
    imageDataDestination: ImageDataDestinations,
    swType: SWTypes,
    isVisibilityExternal?: boolean,
    noticeVisibilities?: INoticeVisibility[],
    setNoticeVisibility?(stepGuid: string, isOpen: boolean): void,
    isDraftPage?: boolean,
    hideControls: boolean
}

const RCNoticeEditor: React.FC<IRCNoticeEditorProps> = ({
    allowEdit,
    notice,
    stepGuid,
    swGuid,
    swType,
    swVersion,
    imageDataDestination,
    isVisibilityExternal,
    noticeVisibilities,
    setNoticeVisibility,
    isDraftPage, 
    hideControls}) => {
    let noticeTextArr: (string | JSX.Element)[] = [];
    let label = notice.label;
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(allowEdit);

    const {
        masterData: { hazardCategories, potentialLosses },
      } = useSelector(store => store);
    
      const renderHazardCategories = () => {
        return (
          <MasterDataInput
            label="Hazard Category"
            selectedItems={notice?.hazardCategory?.guid ? [notice?.hazardCategory] : []}
            masterDataSection={hazardCategories}
            loadItems={() => void 0}
            onAddItem={(item) => void 0}
            onRemoveItem={() => void 0}
            itemFormatter={(item) => item.value}
            allowMultiple={false}
            isMandatory={false}
            disabled={true}
          />
        );
      };
    const renderPotentialLossData = () => {
        return (
            <MasterDataInput
                label="Potential Loss"
                selectedItems={notice?.potentialLoss ? notice?.potentialLoss : []}
                masterDataSection={potentialLosses}
                loadItems={() => void 0}
                onAddItem={() => void 0}
                onRemoveItem={() => void 0}
                itemFormatter={(item) => item.value}
                allowMultiple={true}
                isMandatory={false}
                disabled={true}
            />
          );
    };

    if (label) {
        const reg = /<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g;
        let match;
        let cursorIx = 0;

        while ((match = reg.exec(label))) {
            if (cursorIx !== match.index) {
                noticeTextArr.push(
                    label.substr(cursorIx, match.index - cursorIx)
                        .replace(/ /g, " ")
                );
            }

            noticeTextArr.push(
                <a href={match[1]}>{match[2].replace(/ /g, " ")}</a>
            );

            cursorIx = match.index + match[0].length;
        }

        if (cursorIx < label.length) {
            noticeTextArr.push(
                label.substr(cursorIx)
                    .replace(/ /g, " ")
            );
        }
    }

    let noticeImage: JSX.Element | undefined = <NoticeImageEditor
        notice={notice}
        stepGuid={stepGuid}
        allowEdit={isEdit}
        swGuid={swGuid}
        swVersion={swVersion}
        imageDataDestination={imageDataDestination}
    />

    let noticeTimeImage: JSX.Element | undefined = <NoticeTimeImageEditor
        notice={notice}
        stepGuid={stepGuid}
        allowEdit={isEdit}
        swGuid={swGuid}
        swVersion={swVersion}
        imageDataDestination={imageDataDestination}
    />

    let noticeComp: JSX.Element | undefined;
    if (notice.image.imageType === ImageType.Both) {
        noticeComp = (
            <>
                {noticeImage}
                {noticeTimeImage}
            </>
        )
    }
    else if (notice.image.imageType === ImageType.Time) {
        noticeComp = (
            <>
                {noticeTimeImage}
            </>
        )
    }
    else if (notice.image.imageType === ImageType.Normal) {
        noticeComp = (
            noticeImage
        )
    }
    const disableRcTaskStep = (checked: boolean) => {
        return;
    }

    const clickDeleteNotice = () => {
        dispatch(removeComponent({
            stepGuid,
            componentGuid: notice.guid,
        }));
    }

    const clickRCDelinkNotice = () => {
        dispatch(updateRCNoticeLinkBreak({
            stepGuid,
            componentGuid: notice.guid,
        }));
    }

    const getAllowedRCControls = () => {
        let rcControl: IAllowedRCControlsConfig = {
            createDraft: true,
            whereUsed: true,
            delink: true,
            canDisableNotice: true,
            canDisableStep: false,
        }
        if (swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI || swType == SWTypes.TLMRC || swType == SWTypes.MFGRC) {
            return rcControl;
        }
        else {
            return;
        }
    }

    const noticeVisibility = isVisibilityExternal
        ? noticeVisibilities
            ?.find(x => x.noticeGuid === notice.guid)
        : undefined;

    const ondisableRcNotice = (checked: boolean) => {
        setIsEdit(!checked);
        dispatch(disableRcNoticeContent({ stepGuid: stepGuid, componentGuid: notice.guid, disabled: !checked }));
    }


    return (
        <Collapsible
            header={notice.rcTitle || ""}
            swType={swType}
            isStepDisabled={false}
            isNoticeDisabled={notice.isDisabled}
            disableRcTaskStep={disableRcTaskStep}
            disableRcNotice={ondisableRcNotice}
            imageDataDestination={imageDataDestination}
            isRCContainer={true}
            clickDeleteNotice={clickDeleteNotice}
            hideRCControls={(swType === SWTypes.TLMSWI || swType === SWTypes.MFGSWI) && swVersion !== 0}
            hideControls={hideControls}
            allowedRCControlsConfig={getAllowedRCControls()}
            clickRCDelinkNotice={clickRCDelinkNotice}
            isRCNotice={true}
            rcID={notice.rcID}
            isExternallyControlled={isVisibilityExternal}
            isInitiallyOpen={!allowEdit}
            externalIsOpen={noticeVisibility?.isOpen}
            externalOnToggle={setNoticeVisibility
                ? (isOpen: boolean) => setNoticeVisibility(notice.guid, isOpen)
                : undefined
            }
            draftPage={isDraftPage}
        >
            <Banner type={getBannerType(notice.noticeType)}>
                <div className="inner">
                    <>
                        {getIcon(notice.noticeType)}
                        <div className="text">
                        <NoticeRichTextEditor 
                            html={notice.label} 
                            type = {notice.noticeType} 
                            allowEdit = {allowEdit} 
                            onLabelChange={(lable : string) => {}}/>
                        </div>
                    </>

                </div>
                {
                    <>
                        <div className="inner">
                            {noticeComp}
                        </div>
                        {notice.noticeType !== NoticeTypes.Info &&
                            <div className="inner">
                                { renderHazardCategories()}
                            </div>
                        }
                        <div className="inner">
                            {renderPotentialLossData()}
                        </div>
                    </>
                }
            </Banner>
        </Collapsible>
    );
};

const getBannerType = (type: NoticeTypes): BannerType => {
    switch (type) {
        case NoticeTypes.Caution: return BannerType.Warn;
        case NoticeTypes.Warning: return BannerType.Error;
        default: return BannerType.Info;
    }
}

const getIcon = (type: NoticeTypes) => {
    if (type === NoticeTypes.Caution) {
        return (
            <img
                src={WarningIcon}
                className={`icon-large type-icon ${type}`}
                alt="Caution"
            />
        );
    } else if (type === NoticeTypes.Warning) {
        return (
            <img
                src={ErrorIcon}
                className={`icon-large type-icon ${type}`}
                alt="Warning"
            />
        );
    } else if (type === NoticeTypes.Info) {
        return (
            <img
                src={InfoIcon}
                className={`icon-large type-icon ${type}`}
                alt="Info"
            />
        );
    }
    return null;
}

export default RCNoticeEditor;