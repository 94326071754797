import React, { useEffect } from "react";
import "./Modal.scoped.scss";
import Portal from "components/layout/Portal";

export interface IModalProps {
  isOpen: boolean,
  showCloseButton?: boolean,
  header?: string | React.ReactNode,
  width?: string | number,
  maxHeight?: string | number,
  controls?: React.ReactNode,
  theme?: ModalTheme,
  onCloseButtonClicked?(): void,
  top?: string | number,
  children: any,
  zIndex?: number,
}

export enum ModalTheme {
  Normal = "modal",
  Error = "modal error",
}

const Modal: React.FC<IModalProps> = (props) => {
  useEffect(() => {
    let pageOffset = window.pageYOffset;

    if (props.isOpen) {
      document.body.style.top = `-${pageOffset}px`;
      document.body.classList.add('has-open-modal');
    }

    return () => {
      document.body.classList.remove('has-open-modal');
      document.body.style.removeProperty('top');
      window.scrollTo(0, pageOffset || 0);
    };
  }, [props.isOpen]);

  if (!props.isOpen) {
    return null;
  }

  const zIndex = props.zIndex || 1000;

  return (
    <Portal>
      <div className="fade" style={{zIndex: zIndex - 1}}></div>
      <div className={props.theme || ModalTheme.Normal} style={{ width: props.width, maxHeight: props.maxHeight, top: props.top, zIndex : zIndex }}>
        <div className="top">
          <span className="header">
            {props.header}
          </span>
          {props.showCloseButton &&
            <span className="close"
              onClick={props.onCloseButtonClicked}>x</span>
          }
        </div>
        <div className="middle">
          {props.children}
        </div>
        {props.controls &&
          <div className="bottom">
            {props.controls}
          </div>
        }
      </div>
    </Portal>
  );
}

export default Modal;