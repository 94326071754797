import { useSyncExternalStore } from "react";

const getComponentFromLocalStorage = (key: string): string => {
  return localStorage.getItem(key) || "";
};

const subscribe = (callback: () => void): (() => void) => {
  window.addEventListener("storage", callback);
  return () => {
    window.removeEventListener("storage", callback);
  };
};

const useLocalStorageItemStore = (key: string): [string, (newValue: string) => void] => {
  const storageItem = useSyncExternalStore(subscribe, () => getComponentFromLocalStorage(key));
  const setStorageItem = (newValue: string) => {
    localStorage.setItem(key, newValue);
    window.dispatchEvent(new Event("storage"));
  };
  return [storageItem, setStorageItem];
};

export default useLocalStorageItemStore;