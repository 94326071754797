import React, { useRef, useState, useEffect } from "react";
import { IRefDocTableComponent, IReferenceDoc } from "interfaces/sw/SWInterfaces";
import "./RefDoc.scoped.scss";
import { useDispatch } from "react-redux";
import { setRefDocLabel, uploadSWAttachment, removeReferenceDoc, setRefDocTableData, setRefDocFilename, } from "store/manageSW/manageSWActions";
import { showErrorToast } from "store/toast/toastActions";
import { ImageDataDestinations, SWAttachmentTypes } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";
import DeleteIcon from "media/icons/dls/delete.svg";
import { addGuidToFilename, getFileSizeError } from "utilities/fileUtilities";
import downloadIcon from "media/icons/dls/download.svg";
import viewIcon from "media/icons/dls/viewer.svg";
import SWApi from "apis/sw/SWApi";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import ModalSpinner from "components/common/ModalSpinner";
import RefDocTableCell from "./RefDocTableCell";
import TableModal from "components/common/TableModal";
import { validateRefDocURL } from "utilities/sw/validateSW";
import ExpandIcon from "media/icons/dls/arrow-right-3.svg";
import CollapseIcon from "media/icons/dls/arrow-down-3.svg";
import Banner, { BannerType } from "components/common/Banner";

interface IRefDocProps {
  refDoc: IReferenceDoc,
  disabled: boolean,
}

const RefDoc: React.FC<IRefDocProps> = ({ disabled,
  refDoc,
}) => {
  const {
    SW: {
      guid,
      version,
    },
  } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUpForRow, setShowPopUpForRow] = useState(false);
  const [rowReduce, setRowReduce] = useState(refDoc.tableData?.rowCount || 0);
  const [showPopUpForColumn, setShowPopUpForColumn] = useState(false);
  const [colReduce, setColReduce] = useState(refDoc.tableData?.colCount || 0);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (rowReduce < (refDoc.tableData?.rowCount || 0) && rowReduce > 0) {
      setShowPopUpForRow(true);
    }
    if (colReduce < (refDoc.tableData?.colCount || 0) && colReduce > 0) {
      setShowPopUpForColumn(true);
    }
  }, [rowReduce, colReduce, refDoc.tableData]);

  const onFileChosen = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length !== 1) {
      return;
    }

    const file = event.target.files[0];

    const fileSizeError = getFileSizeError(file);
    if (fileSizeError) {
      dispatch(showErrorToast(fileSizeError));
      return;
    }

    try {
      addGuidToFilename(file.name);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
      return;
    }

    dispatch(uploadSWAttachment({
      swGuid: guid,
      swVersion: 0,
      file,
      type: SWAttachmentTypes.RefDoc,
      refDocGuid: refDoc.guid,
    }));
  }

  const onDeleteClick = () => {
    dispatch(removeReferenceDoc({
      refDocGuid: refDoc.guid,
    }));
  }

  const downloadRefDoc = async () => {
    try {
      setIsLoading(true);
      const blobUri = await SWApi.getSWBlobURL(guid, version, refDoc.refDocData);
      const link = document.createElement("a");
      link.href = blobUri;
      link.download = refDoc.refDocData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Clean up the object URL
      URL.revokeObjectURL(blobUri);
    } catch (err: any) {
      dispatch(showErrorToast(`Failed to download file: ${getResponseErrorMessage(err)}`));
    } finally {
      setIsLoading(false);
    }
  }

  let onColInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { valueAsNumber, min, max } = e.target;
    valueAsNumber = Math.max(Number(min), Math.min(Number(max), valueAsNumber));

    if (isNaN(valueAsNumber)) {
      valueAsNumber = Number(min);
    }

    if (valueAsNumber < (refDoc.tableData?.colCount || 0)) {
      let cellsToDelete = refDoc.tableData?.cells.filter(x => x.colIndex === valueAsNumber) || [];
      if (cellsToDelete.find(x => x.value !== undefined)) {
        // only show popup if column has data
        setColReduce(valueAsNumber);
      } else {
        setColReduce(valueAsNumber);
        updateColumn(valueAsNumber);
      }
    }
    else {
      setColReduce(valueAsNumber);
      updateColumn(valueAsNumber);
    }
  }

  let onRowInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { valueAsNumber, min, max } = e.target;
    valueAsNumber = Math.max(Number(min), Math.min(Number(max), valueAsNumber));

    if (isNaN(valueAsNumber)) {
      valueAsNumber = Number(min);
    }

    if (valueAsNumber < (refDoc.tableData?.rowCount || 0)) {
      let cellsToDelete = refDoc.tableData?.cells.filter(x => x.rowIndex === valueAsNumber) || [];
      if (cellsToDelete.find(x => x.value !== undefined)) {
        // only show popup if row has data.
        setRowReduce(valueAsNumber);
      }
      else {
        setRowReduce(valueAsNumber);
        updateRow(valueAsNumber);
      }
    }
    else {
      setRowReduce(valueAsNumber);
      updateRow(valueAsNumber);
    }
  }

  const updateRow = (rowCount: number) => {
    if (refDoc.tableData) {
      const newTableData = { ...refDoc.tableData };
      if (rowCount <= newTableData?.rowCount) {
        newTableData.cells = [];
        refDoc.tableData?.cells.forEach((item, index, object) => {
          if (item.rowIndex < rowCount)
            newTableData.cells.push(item);
        });
      }

      dispatch(setRefDocTableData({
        refDocGuid: refDoc.guid,
        component: {
          ...newTableData,
          rowCount: rowCount
        }
      }));
    }
  }

  const updateColumn = (colCount: number) => {
    if (refDoc.tableData) {
      const newTableData = { ...refDoc.tableData };
      if (colCount <= newTableData?.colCount) {
        newTableData.cells = [];
        refDoc.tableData?.cells.forEach((item, index, object) => {
          if (item.colIndex < colCount)
            newTableData.cells.push(item);
        });
      }

      dispatch(setRefDocTableData({
        refDocGuid: refDoc.guid,
        component: {
          ...newTableData,
          colCount: colCount
        }
      }));
    }
  }

  const onRowModalClick = (isContinue: boolean) => {
    setShowPopUpForRow(false);
    if (isContinue) {
      updateRow(rowReduce);
    }
    else {
      setRowReduce(refDoc.tableData?.rowCount || 0);
    }
  }

  const onColModalClick = (isContinue: boolean) => {
    setShowPopUpForColumn(false);
    if (isContinue) {
      updateColumn(colReduce);
    }
    else {
      setColReduce(refDoc.tableData?.colCount || 0);
    }
  }

  const setRefDocLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRefDocFilename({
      refDocGuid: refDoc.guid,
      filename: e.target.value,
    }))

    const validationResult = validateRefDocURL(e.target.value);
    if (!validationResult.isValid) {
      dispatch(showErrorToast(validationResult.errorMessage));
    }
  }

  const renderTable = (disabled: boolean, tableData: IRefDocTableComponent) => {
    //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
    let cellArray = new Array(tableData.rowCount);
    for (var r = 0; r < cellArray.length; r++) {
      cellArray[r] = new Array(tableData.colCount);
      for (var c = 0; c < cellArray[r].length; c++) {
        let cIx = c;
        let rIx = r;

        let cell = tableData.cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
        if (cell !== undefined) {
          cellArray[rIx][cIx] = { colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false };
        } else {
          cellArray[rIx][cIx] = { colSpan: 1, rowSpan: 1, deleted: false };
        }
      }
    }

    // figure out which cells should be deleted to handle col spans and row spans
    for (var ra = 0; ra < tableData.rowCount; ra++) {
      for (var ca = 0; ca < tableData.colCount; ca++) {
        let cell = cellArray[ra][ca];
        if (cell.deleted) {
          continue;
        }

        if (cell.colSpan > 1 && cell.rowSpan > 1) {
          // disable columns and rows??
          for (var i = ca + 1; i < ca + cell.colSpan; i++) {
            let cellToDisable = cellArray[ra][i];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
          for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
            let cellToDisable = cellArray[j][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }

            for (var z = 1; z < cell.colSpan; z++) {
              let cellToDisable = cellArray[j][ca + z];
              if (cellToDisable) {
                cellToDisable.deleted = true;
                cellToDisable.rowSpan = 1;
                cellToDisable.colSpan = 1;
              }
            }
          }
        } else if (cell.colSpan > 1) {
          // disable columns
          for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
            let cellToDisable = cellArray[ra][ii];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        } else if (cell.rowSpan > 1) {
          // disable rows
          for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
            let cellToDisable = cellArray[jj][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      }
    }

    const colWidth = 100 / tableData.colCount;
    return (
      <table>
        <colgroup>
          {
            [...new Array(tableData.colCount)].map((_, cIx) => {
              return <col key={cIx} style={{ width: colWidth + '%' }} />
            })
          }
        </colgroup>
        <tbody>
          {[...new Array(tableData.rowCount)].map((_, ixr) => (
            <tr key={"r" + ixr}>
              {
                [...new Array(tableData.colCount)].map((_, ixc) => {
                  let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                  if (!cellFormatter.deleted) {
                    let cell = tableData.cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                    let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                    let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;

                    return (
                      <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                        <RefDocTableCell
                          rowKey={ixr}
                          colKey={ixc}
                          refDocGuid={refDoc.guid}
                          tableComponent={tableData}
                          disabled={disabled}
                          swGuid={guid}
                          swVersion={version}
                          imageDataDestination={ImageDataDestinations.ManageSW}
                        />
                      </td>);
                  } else {
                    return undefined;
                  }
                })
              }
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  let component: JSX.Element | undefined;
  if (refDoc.tableData) {
    component = (
      <div className="reference-doc-table">
        {showPopUpForRow
          && <TableModal
            onModalClick={onRowModalClick}
            header="Table Warning"
            message="Row has data and will be deleted. Do you want to continue?"
          />
        }
        {showPopUpForColumn
          && <TableModal
            onModalClick={onColModalClick}
            header="Table Warning"
            message="Column has data and will be deleted. Do you want to continue?"
          />
        }
        <div className="row">
          <img
            src={isCollapsed ? ExpandIcon : CollapseIcon}
            className="icon-small collapse-button hover-gray-bg"
            alt={isCollapsed ? "Expand" : "Collapse"}
            title={isCollapsed ? "Expand Component" : "Collapse Component"}
            onClick={toggleCollapse}
          />
          {!disabled &&
            <img
              src={DeleteIcon}
              alt="Delete"
              title="Delete"
              className="delete-button icon-small float-right"
              onClick={onDeleteClick}
            />
          }
        </div>
        <div className={isCollapsed ? "collapsed" : ""}>
          <label className="mandatory controlLabel">
            Label
          </label>
          <input
            type="text"
            className="controlWide"
            defaultValue={refDoc.label}
            disabled={disabled}
            onBlur={(e) => dispatch(setRefDocLabel({
              refDocGuid: refDoc.guid,
              label: e.target.value,
            }))}
          />
          <label className="controlLabel">Columns</label>
          <input
            className="control numberInput"
            type="number"
            value={refDoc.tableData.colCount}
            disabled={disabled}
            onChange={(e) => onColInputChange(e)}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min="1"
            max="10"
          />
          <label className="controlLabel">Rows</label>
          <input
            className="control numberInput"
            type="number"
            value={refDoc.tableData.rowCount}
            disabled={disabled}
            onChange={(e) => onRowInputChange(e)}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            min="1"
            max="20"
          />
          {renderTable(disabled, refDoc.tableData)}
          {isLoading &&
            <ModalSpinner />
          }
        </div>
        {isCollapsed && (
          <Banner type={BannerType.Info}>
            <p onClick={toggleCollapse}>
              Table is collapsed. Click <b>here</b> to expand.
            </p>
          </Banner>
        )}
      </div>
    );
  }
  else if (refDoc.type === "Link") {
    component = (
      <div className="reference-doc">
        <div>
          <label className="block mandatory">Link Label
            <span className="compliancelbl"> (Link should be in compliance with SLB security policy)</span>
          </label>
          <input
            type="text"
            defaultValue={refDoc.label}
            disabled={disabled}
            className="label-text"
            onBlur={(e) => dispatch(setRefDocLabel({
              refDocGuid: refDoc.guid,
              label: e.target.value,
            }))}
          />
          <label className="block mandatory">
            URL
          </label>
          <input
            type="text"
            defaultValue={refDoc.refDocData}
            disabled={disabled}
            className="label-text"
            onBlur={setRefDocLink}
          />
          {!!refDoc.label
            && !!refDoc.refDocData
            && <>
              <label className="label-text">Link Preview</label>
              <span>
                <a
                  href={refDoc.refDocData}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {refDoc.label}
                </a>
              </span>
            </>
          }
        </div>
        {!disabled &&
          <img
            src={DeleteIcon}
            alt="Delete"
            title="Delete"
            className="delete-button icon-small"
            onClick={onDeleteClick}
          />
        }
        {isLoading &&
          <ModalSpinner />
        }
      </div>
    );
  }
  else {
    component = (
      <div className="reference-doc">
        <div>
          <label className="mandatory">
            Label
          </label>
          <input
            type="text"
            className="label-text"
            defaultValue={refDoc.label}
            disabled={disabled}
            onBlur={(e) => dispatch(setRefDocLabel({
              refDocGuid: refDoc.guid,
              label: e.target.value,
            }))}
          />
        </div>
        <div>
          <label className="mandatory">
            File
          </label>
          <div className="file-input-holder">
            <input
              type="file"
              accept="image/*,application/pdf"
              ref={fileInputRef}
              onChange={(e) => onFileChosen(e)}
            />

            <input
              type="text"
              className="filename"
              value={refDoc.refDocData}
              readOnly={true}
              disabled={true}
            />
            <button
              className="primary-button browse"
              disabled={disabled}
              onClick={() => fileInputRef.current?.click()}
            >
              ...
            </button>

            {!!refDoc.refDocData &&
                <button
                  className="secondary-button"
                  onClick={downloadRefDoc}
                  title="Download"
                >
                  <img
                    src={downloadIcon}
                    className="icon-small"
                    alt="Download"
                  />
                </button>
            }
          </div>
        </div>
        {!disabled &&
          <img
            src={DeleteIcon}
            alt="Delete"
            title="Delete"
            className="delete-button icon-small"
            onClick={onDeleteClick}
          />
        }
        {isLoading &&
          <ModalSpinner />
        }
      </div>
    );
  }

  return component;
}

export default RefDoc;