interface ICopyToClipboard {
  value: string;
  errorMessage: string;
}

export async function copyToClipboard(copyVale: ICopyToClipboard) {
  let copyValue = "";
  if (!navigator.clipboard) {
    throw new Error(
      `Browser doesn't have support for native clipboard.`
    );
  }

  if (copyVale.value) {
    copyValue = copyVale.value;
  }
  await navigator.clipboard.writeText(copyValue);
}

export async function readFromClipboard() {
  if (!navigator.clipboard) {
    throw new Error(
      `Browser doesn't have support for native clipboard.`
    );
  }
  return await navigator.clipboard.readText();
}
