import Banner, { BannerType } from "components/common/Banner";
import { INoticeComponent, NoticeTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import React from "react";
import "./Notice.scoped.scss";
import WarningIcon from "media/icons/dls/warning.svg";
import ErrorIcon from "media/icons/dls/error.svg";
import InfoIcon from "media/icons/dls/info.svg";
import NoticeImage from "./NoticeImage";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";

import NoticeRichTextEditor, {
  RichTextNoticeSWTypes,
} from "components/sw/manage/steps/components/NoticeRichTextEditor";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import useSelector from "store/useSelector";
import { loadHazardCategories } from "store/masterData/masterDataActions";

interface INoticeProps {
  swType: SWTypes,
  component: INoticeComponent,
  swGuid: string,
  swVersion: number,
  stepGuid: string,
  imageDataDestination: ImageDataDestinations,
}

const Notice: React.FC<INoticeProps> = ({ swType, component, swGuid, swVersion, stepGuid, imageDataDestination }) => {
  let noticeTextArr: (string | JSX.Element)[] = [];
  let label = component.label;

  const allowRichTextLinksNotices = RichTextNoticeSWTypes.includes(swType);

  const {
    masterData: { hazardCategories, potentialLosses},
  } = useSelector(store => store);

  const renderHazardCategories = () => {
    return (
      <MasterDataInput
        label="Hazard Category"
        selectedItems={component?.hazardCategory?.guid ? [component?.hazardCategory] : []}
        masterDataSection={hazardCategories}
        loadItems={() => void 0}
        onAddItem={(item) => void 0}
        onRemoveItem={() => void 0}
        itemFormatter={(item) => item.value}
        allowMultiple={false}
        isMandatory={false}
        disabled={true}
      />
    );
  };

  const renderPotentialLossData = () => {
      return (
          <MasterDataInput
              label="Potential Loss"
              selectedItems={component?.potentialLoss ? component?.potentialLoss : []}
              masterDataSection={potentialLosses}
              loadItems={() => void 0}
              onAddItem={() => void 0}
              onRemoveItem={() => void 0}
              itemFormatter={(item) => item.value}
              allowMultiple={true}
              isMandatory={false}
              disabled={true}
          />
      );
  };

  if (label) {
    const reg = /<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g;
    let match;
    let cursorIx = 0;

    while ((match = reg.exec(label))) {
      if (cursorIx !== match.index) {
        noticeTextArr.push(
          label.substr(cursorIx, match.index - cursorIx)
            .replace(/ /g, " ")
        );
      }

      noticeTextArr.push(
        <a href={match[1]}>{match[2].replace(/ /g, " ")}</a>
      );

      cursorIx = match.index + match[0].length;
    }

    if (cursorIx < label.length) {
      noticeTextArr.push(
        label.substr(cursorIx)
          .replace(/ /g, " ")
      );
    }
  }

  const allowHazardCategory = () => {
    const disallowedTypes = [SWTypes.CL, SWTypes.SWI, SWTypes.ECL, SWTypes.LCL];
      const isVisible = component?.hazardCategory?.guid ? true : false;
      return !disallowedTypes.includes(swType) && isVisible;
  };
    const allowPotentialLoss = () => {
      const allowedTypes = [SWTypes.TLMRC, SWTypes.TLMSWI];
        const isVisible = component?.potentialLoss.length > 0 ? true : false;
        return allowedTypes.includes(swType) && isVisible;
  };

  return (
    <Banner type={getBannerType(component.noticeType)}>
      <div className="inner">
        {getIcon(component.noticeType)}
        {allowRichTextLinksNotices ?
          <NoticeRichTextEditor
            html={component.label}
            type={component.noticeType}
            allowEdit={false}
            onLabelChange={(lable: string) => { }}
          /> :
          <div className="text">
            {noticeTextArr.map((sect, ix) => (
              <React.Fragment key={ix}>{sect}</React.Fragment>
            ))}
          </div>
        }
      </div>
      {
        <>
          <div>
            <NoticeImage
              image={component.image}
              noticeGuid={component.guid}
              swGuid={swGuid}
              swVersion={swVersion}
              stepGuid={stepGuid}
              imageDataDestination={imageDataDestination}
            />
          </div>
          {allowHazardCategory() &&  component.noticeType !== NoticeTypes.Info &&
            <div className="notice-hazard-categories"> 
              {renderHazardCategories()}
            </div>
          }
          {allowPotentialLoss() && 
            <div className="tlm-notice-potential-loss">
                {renderPotentialLossData()}
            </div>
          }
        </>
      }
    </Banner>
  );
};

const getBannerType = (type: NoticeTypes): BannerType => {
  switch (type) {
    case NoticeTypes.Caution: return BannerType.Warn;
    case NoticeTypes.Warning: return BannerType.Error;
    default: return BannerType.Info;
  }
}

const getIcon = (type: NoticeTypes) => {
  if (type === NoticeTypes.Caution) {
    return (
      <img
        src={WarningIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Caution"
      />
    );
  } else if (type === NoticeTypes.Warning) {
    return (
      <img
        src={ErrorIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Warning"
      />
    );
  } else if (type === NoticeTypes.Info) {
    return (
      <img
        src={InfoIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Info"
      />
    );
  }
  return null;
}

export default Notice;