import { all, call, put, takeLatest } from "redux-saga/effects";
import { downloadSwRepoPdf, setLoadOperation, setSearchText, setSwRepoListData } from "./manageSwReportsActions";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import { showErrorToast } from "store/toast/toastActions";
import { Action } from "@reduxjs/toolkit";
import { ISwRepoSummaryItem } from "./ManageSwReportsTypes";
import SwReportsApi from "apis/reports/SwReportsApi";
import { downloadData } from "utilities/fileUtilities";

export default function* watchSwReportsSagas() {
    yield all([
        watchGetSwRepoList(),
        watchDowloadSwRepoPdf(),
    ]);
}

function* watchGetSwRepoList() {
    yield takeLatest([
        setSearchText,
    ], getSwRepoListAsync);
}

function* watchDowloadSwRepoPdf() {
    yield takeLatest([
        downloadSwRepoPdf,
    ], downloadSwRepoPdfAsync);
}

function* getSwRepoListAsync(action: Action) {
    if (!setSearchText.match(action)) {
        return;
    }

    yield put(
        setLoadOperation({
            loadOperation: {
                isWorking: true,
            }
        })
    );

    try {
        let response: ISwRepoSummaryItem[] = yield call(SwReportsApi.getSwRepoList, action.payload);
        yield put(
            setLoadOperation({
                loadOperation: {
                    isWorking: false,
                }
            })
        );
        yield put(
            setSwRepoListData(response)
        );
    } catch (err: any) {
        yield put(
            showErrorToast("Failed to get SWRepo data: " + getResponseErrorMessage(err))
        );
        yield put(
            setLoadOperation({
                loadOperation: {
                    isWorking: false,
                }
            })
        );
    }
}

function* downloadSwRepoPdfAsync(action: Action) {
    if (!downloadSwRepoPdf.match(action)) {
        return;
    }

    try {
        yield put(
            setLoadOperation({
                loadOperation: {
                    isWorking: true,
                }
            })
        );

        let data: Blob = yield call(
            SwReportsApi.downloadSwRepoPdf,
            action.payload.uid,
            action.payload.version,
            action.payload.sourceSystem,
            action.payload.isArchived
        );

        if (data.size > 0) {
            yield call(downloadData, data, action.payload.uid + '_' + action.payload.version + 'pdf');
        }

        yield put(
            setLoadOperation({
                loadOperation: {
                    isWorking: false,
                }
            })
        );
    }
    catch (err: any) {
        yield put(
            showErrorToast("Failed to generate pdf File: " + getResponseErrorMessage(err))
        );
        yield put(
            setLoadOperation({
                loadOperation: {
                    isWorking: false,
                }
            })
        );
    }
}