import React, { useRef, useEffect, useState } from "react";
import { IImageComponent, ImageAlignments, ImageScale } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import "./ComponentEditor.scss";
import "./ImageEditor.scoped.scss";
import { showErrorToast } from "store/toast/toastActions";
import { uploadSWAttachment, getStepComponentImageUrl, updateImage } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";
import { SWAttachmentTypes, ImageDataDestinations } from "store/manageSW/manageSWTypes";
import BlockSpinner from "components/common/BlockSpinner";
import { addGuidToFilename, getFileSizeError, isFilenameImage, imagesFilter } from "utilities/fileUtilities";
import FileDropzone from "components/common/FileDropzone";
import InfoIcon from "media/icons/dls/info.svg";
import InfoModal from "../../../../common/InfoModal";

interface IImageEditorProps {
  image: IImageComponent,
  allowEdit: boolean,
  stepGuid: string,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
}

const ImageEditor: React.FC<IImageEditorProps> = ({
  image,
  allowEdit,
  stepGuid,
  swGuid,
  swVersion,
  imageDataDestination,
}) => {
  const { fileUploadOperation } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [scale, setScale] = useState<ImageScale>();
  const [isInfoClicked, setIsInfoClicked]=useState<boolean>(false);

  useEffect(() => {
    if (!image.filename
      || image.blobData?.isLoading
      || image.filename === image.blobData?.filename
      || fileUploadOperation?.isWorking) {
      // Blob url already loaded. Ignore.
      return;
    }

    dispatch(getStepComponentImageUrl({
      stepGuid,
      imageComponent: image,
      swGuid,
      swVersion,
      filename: image.filename,
      destination: imageDataDestination,
    }));
  }, [image, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

  const handleFile = (file : File) => {
    if (!isFilenameImage(file.name)) {
      dispatch(showErrorToast("Please select a valid image."));
      return;
    }

    const fileSizeError = getFileSizeError(file);
    if (fileSizeError) {
      dispatch(showErrorToast(fileSizeError));
      return;
    }

    try {
      addGuidToFilename(file.name);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
      return;
    }

    const newImage = { ...image };
    newImage.scale = scale;

    dispatch(uploadSWAttachment({
      swGuid,
      swVersion: 0,
      file,
      type: SWAttachmentTypes.ImageComponent,
      imageComponent: newImage,
      stepGuid,
    }));
  }

  const onFileChosen: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files?.length) {
      return;
    }

    handleFile(event.target.files[0]);
  };

  const alignmentChange = (alignment: ImageAlignments) => {
    dispatch(updateImage({
      component: {
        ...image,
        alignment,
      },
      stepGuid,
    }));
  }

  const onCheckedChange = () => {
    if (image.scale !== undefined) {
      dispatch(updateImage({
        component: {
          ...image,
          scale: undefined,
        },
        stepGuid,
      }));
    } else {
      dispatch(updateImage({
        component: {
          ...image,
          scale: ImageScale.Small,
        },
        stepGuid,
      }));
    }
  }

  const onScaleChange = (newScale: ImageScale) => {
    setScale(newScale);
    dispatch(updateImage({
      component: {
        ...image,
        scale: newScale,
      },
      stepGuid,
    }));
  }

  let img: JSX.Element | undefined;

  if (image.blobData?.isLoading) {
    img = (
      <BlockSpinner />
    );
  } else if (image.blobData?.errorMessage) {
    img = (
      <label className="error">
        {image.blobData?.errorMessage}
      </label>
    );
  } else if (image.blobData?.blobUrl) {
    img = (
      <img
        src={image.blobData?.blobUrl}
        alt="Selected"
        className="chosen-image"
      />
    );
    }

  let toolTipTextArray = ['If Scale is not selected & image Width > Max Width & Height > Max Height, then image get set to default size.',
                            'Scaling width has been set as follows: Small-255, Medium-380, Large-430.',
                            'If image Width > Large, image can be scaled down to Small, Medium, or Large.',
                            'If image Width > Medium and Width < Large, it can be scaled down to Small or Medium.',
                            'If image Width > Small and Width < Medium, it can be scaled down to small.' ];

  return (
    <>
      <div className="image-wrapper">
        {img}
      </div>
      {allowEdit &&
        <>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={onFileChosen}
          />
          <div
            className="controls"
          >
            <FileDropzone onFilesDropped={([file]) => handleFile(file)}>
              <button
                className="secondary-button choose-image"
                onClick={() => fileInputRef.current?.click()}
                title={imagesFilter}
              >
                {image.filename ? "Drop Image here or click to replace" : "Drop Image here or click to upload"}
              </button>
            </FileDropzone>
            <div
              className="align-holder"
            >
              <label>Alignment</label>
              <select
                value={image.alignment}
                onChange={(e) => alignmentChange(e.target.value as ImageAlignments)}
              >
                <option
                  value={ImageAlignments.Right}
                >
                  {ImageAlignments.Right}
                </option>
                <option
                  value={ImageAlignments.Bottom}
                >
                  {ImageAlignments.Bottom}
                </option>
              </select>
            </div>
          </div>
          <br />
          <div className="imageEditor-Conatiner">
            <button className="info-button" onClick={() => setIsInfoClicked(true)}>
                <img
                    src={InfoIcon}
                    alt="InfoIcon"
                    className="info-icon"
                />
            </button>
            <InfoModal
                isOpen={isInfoClicked}
                showCloseButton={true}
                onCloseButtonClicked={() => setIsInfoClicked(false)}
            >
                <ul>
                    {toolTipTextArray.map((text, index) =>
                        <li key={index.toString()}>{text}</li>
                    )}
                </ul>
            </InfoModal>
            <label>Scale</label>
            <input
              type="checkbox"
              checked={image.scale !== undefined
                ? true
                : false}
              onChange={onCheckedChange}
            />
            {image.scale !== undefined &&
              <>
                <br />
                <label>Width</label>&nbsp;
                <select
                  onChange={(e) => onScaleChange(e.target.value as ImageScale)}
                  value={image.scale}
                >
                  {Object.keys(ImageScale).map(t =>
                    <option
                      key={t}
                      value={t}
                    >
                      {t}
                    </option>
                  )}
                </select>
              </>}
          </div>
        </>
      }
    </>
  );
}

export default ImageEditor;