import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import React, { useState } from "react";
import { IMasterDataSection } from "store/masterData/masterDataTypes";
import "./ItemPicker.scoped.scss";
import infoIcon from "media/icons/dls/info.svg";
import PickerModal from "./PickerModal";

interface IItemPickerProps {
  label: string,
  helpText?: string,
  isMandatory?: boolean,
  selectedItems: IMasterDataItem[],
  disabled?: boolean,
  allowMultiple?: boolean,
  isAsyncSearch?: boolean,
  loadOnStart?: boolean,
  masterDataSection: IMasterDataSection,
  loadItems(searchTerm: string | undefined): void,
  onAddItem(item: IMasterDataItem): void,
  onRemoveItem(item: IMasterDataItem): void,
  itemFormatter(item: IMasterDataItem): string | JSX.Element,
  itemFilter?(item: IMasterDataItem): boolean,
  meter?: string
}

const ItemPicker: React.FC<IItemPickerProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <span className="label-holder">
        <label className={props.isMandatory ? "mandatory" : ""}>
          {props.label}
          {props.meter &&<><br/>Meter : {props.meter}  </> }
        </label>

        {props.helpText &&
          <img
            src={infoIcon}
            className="icon-small help-cursor mandatory"
            title={props.helpText}
            alt={props.helpText}
          />
        }
      </span>
      <div
        className="input-holder"
      >
        <div
          className={`tag-holder input ${props.disabled ? "disabled" : ""}`}
        >
          {props.selectedItems.map(x =>
            <span
              className="tag no-text-select"
              key={x.guid}
            >
              <span
                className="text"
              >
                {props.itemFormatter(x)}
              </span>
              {!props.disabled &&
                <span
                  className="remove-button-holder"
                  onClick={() => props.onRemoveItem(x)}
                >
                  <span
                    className="remove-button"
                  >
                    X
                  </span>
                </span>
              }
            </span>
          )}
        </div>
        <button
          className="add-button primary-button"
          onClick={() => setIsModalVisible(true)}
          disabled={props.disabled}
          title={`Add ${props.label}`}
        >
          +
        </button>
      </div>
      {isModalVisible &&
        <PickerModal
          label={props.label}
          isAsyncSearch={props.isAsyncSearch}
          allowMultiple={props.allowMultiple}
          loadOnStart={props.loadOnStart}
          onAddItem={props.onAddItem}
          onClose={() => setIsModalVisible(false)}
          onLoad={props.loadItems}
          masterDataSection={props.masterDataSection}
          selectedItems={props.selectedItems}
          itemFormatter={props.itemFormatter}
          itemFilter={props.itemFilter}
        />
      }
    </>
  );
}

export default ItemPicker;