import React from "react";
import Modal from "components/common/Modal";
import { IStep, RCTypes, StepComponentTypes, StepTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import "./ComponentTypeModal.scoped.scss";
import InfoIcon from "media/icons/dls/info.svg";
import TextIcon from "media/icons/dls/text.svg";
import ImageIcon from "media/icons/dls/image.svg";
import PlayIcon from "media/icons/dls/play.svg";
import LinkIcon from "media/icons/dls/link.svg";
import TableIcon from "media/icons/dls/table-view.svg";
import PPEIcon from "media/icons/dls/engg.svg";
import ControlsIcon from "media/icons/dls/controls.svg";

import { useDispatch } from "react-redux";
import { setIsSearchRCNoticeOpen } from "store/manageSW/manageSWActions";
import { clearList } from "store/swList/swListActions";
import useSelector from "store/useSelector";
interface IComponentTypeModalProps {
  step:IStep,
  swType: SWTypes,
  isTask: boolean,
  onCancel(): void,
  onTypeChosen(componentType: StepComponentTypes): void,
  disabledTypes?: IDisabledComponentType[],
}
export interface IDisabledComponentType {
    type: StepComponentTypes,
    reason?: string,
}
const ContentComponentTypeModal: React.FC<IComponentTypeModalProps> = ({ step, swType, isTask, onCancel, onTypeChosen, disabledTypes = [] }) => {
  const dispatch = useDispatch();
  const openRCNoticeSearchModal = () => {
    dispatch(setIsSearchRCNoticeOpen(true));
    dispatch(clearList());
  }

// Static content that can be inserted at any index, between dynamic content-elements.
  const rcNoticeButton = (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGSWI)
      &&
      <button
      key={"RCNotice"}
      className="primary-button"
      onClick={function () { openRCNoticeSearchModal(); onCancel(); }}
    >
      <img
        src={typeIcons.get(StepComponentTypes.Notice)}
        alt=""
        className="black-to-white-filter icon-medium"
      />
      {"Reusable Notice"}
    </button>
  
  const dynamicButtons = Array.from(typeDisplays)
  .filter(key => filterBySWType(key[0], swType, isTask, step.depth, step.stepType))
  .map(([type, display]) =>
    <button
      key={type}
      className={disabledTypes.some(t => t.type === type) ? "primary-button-hide" : "primary-button"}
      onClick={() => onTypeChosen(type)}
    >
      <img
        src={typeIcons.get(type)}
        alt=""
        className="black-to-white-filter icon-medium"
      />
      {display}
    </button>
  )

  const insertRCNoticeIndex = 1;
  const allButtons = [
    ...dynamicButtons.slice(0, insertRCNoticeIndex),
    rcNoticeButton,
    ...dynamicButtons.slice(insertRCNoticeIndex)
  ];

  return (
    <>
      <Modal
        isOpen={true}
        header={"Component Type"}
        controls={(
          <button
            className="secondary-button"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
      >
        <p>
          Choose the type of component to add to the step.
        </p>
        <div className="type-list">
          {allButtons}
        </div>
      </Modal>
    </>
  );
}

export default ContentComponentTypeModal;

const swSupport: Map<SWTypes, StepComponentTypes[]> = new Map();
swSupport.set(SWTypes.ECL, [
  StepComponentTypes.Notice,
]);
swSupport.set(SWTypes.CL, [

]);
swSupport.set(SWTypes.LCL, [

]);
swSupport.set(SWTypes.TLMRC, [
  StepComponentTypes.Notice,
  StepComponentTypes.Image,
  StepComponentTypes.TimeImage,
  StepComponentTypes.MicrosoftStreamVideo,
  StepComponentTypes.Video,
  StepComponentTypes.Audio,
  StepComponentTypes.Link,
  StepComponentTypes.Table,
  StepComponentTypes.EnhancedTable,
  StepComponentTypes.PPE,
  StepComponentTypes.RichTextParagraph,
  StepComponentTypes.QualityControlPoint,
]);
swSupport.set(SWTypes.SWI, [
  StepComponentTypes.Notice,
  StepComponentTypes.Image,
  StepComponentTypes.MicrosoftStreamVideo,
  StepComponentTypes.Link,
  StepComponentTypes.Table,
  StepComponentTypes.EnhancedTable,
]);
swSupport.set(SWTypes.TLMSWI, [
  StepComponentTypes.Notice,
  StepComponentTypes.Image,
  StepComponentTypes.TimeImage,
  StepComponentTypes.MicrosoftStreamVideo,
  StepComponentTypes.Video,
  StepComponentTypes.Audio,
  StepComponentTypes.Link,
  StepComponentTypes.Table,
  StepComponentTypes.EnhancedTable,
  StepComponentTypes.PPE,
  StepComponentTypes.RichTextParagraph,
  StepComponentTypes.QualityControlPoint,
]);
swSupport.set(SWTypes.MFGSWI, [
  StepComponentTypes.Image,
  StepComponentTypes.Link,
  StepComponentTypes.RichTextParagraph,
  StepComponentTypes.TimeImage,
  StepComponentTypes.Notice,
  StepComponentTypes.Table,
  StepComponentTypes.EnhancedTable,
  StepComponentTypes.PPE,
  StepComponentTypes.MicrosoftStreamVideo,
  StepComponentTypes.Video,
]);
swSupport.set(SWTypes.MFGRC, [
  StepComponentTypes.Image,
  StepComponentTypes.Link,
  StepComponentTypes.RichTextParagraph,
  StepComponentTypes.TimeImage,
  StepComponentTypes.Notice,
  StepComponentTypes.Table,
  StepComponentTypes.EnhancedTable,
  StepComponentTypes.PPE,
  StepComponentTypes.MicrosoftStreamVideo,
  StepComponentTypes.Video,
]);
swSupport.set(SWTypes.MFGCL, [
    StepComponentTypes.Image,
    StepComponentTypes.Link,
    StepComponentTypes.RichTextParagraph,
    StepComponentTypes.TimeImage,
    StepComponentTypes.Notice,
    StepComponentTypes.Table,
    StepComponentTypes.EnhancedTable,
    StepComponentTypes.PPE,
    StepComponentTypes.MicrosoftStreamVideo,
    StepComponentTypes.Video,
]);

const typeDisplays: Map<StepComponentTypes, string> = new Map();
typeDisplays.set(StepComponentTypes.Notice, "Notice");
typeDisplays.set(StepComponentTypes.Image, "Image");
typeDisplays.set(StepComponentTypes.TimeImage, "TIME Image");
typeDisplays.set(StepComponentTypes.MicrosoftStreamVideo, "Microsoft Stream Video");
typeDisplays.set(StepComponentTypes.Link, "Link");
typeDisplays.set(StepComponentTypes.Table, "Table");
typeDisplays.set(StepComponentTypes.EnhancedTable, "Enhanced Table");
typeDisplays.set(StepComponentTypes.PPE, "PPE");
typeDisplays.set(StepComponentTypes.Video, "Video");
typeDisplays.set(StepComponentTypes.Audio, "Audio");
typeDisplays.set(StepComponentTypes.RichTextParagraph, "RichText Paragraph");
typeDisplays.set(StepComponentTypes.QualityControlPoint, "Quality Control Point");

const typeIcons: Map<StepComponentTypes, string> = new Map();
typeIcons.set(StepComponentTypes.Notice, InfoIcon);
typeIcons.set(StepComponentTypes.Image, ImageIcon);
typeIcons.set(StepComponentTypes.TimeImage, ImageIcon);
typeIcons.set(StepComponentTypes.MicrosoftStreamVideo, PlayIcon);
typeIcons.set(StepComponentTypes.Link, LinkIcon);
typeIcons.set(StepComponentTypes.Table, TableIcon);
typeIcons.set(StepComponentTypes.PPE, PPEIcon);
typeIcons.set(StepComponentTypes.Video, PlayIcon);
typeIcons.set(StepComponentTypes.Audio, PlayIcon);
typeIcons.set(StepComponentTypes.RichTextParagraph, TextIcon);
typeIcons.set(StepComponentTypes.QualityControlPoint, ControlsIcon);
typeIcons.set(StepComponentTypes.EnhancedTable, TableIcon);

const shouldRemovePPEForMfg = (
  depth: number,
  stepType: StepTypes | undefined,
  swType: SWTypes,
  isTask: boolean
): boolean => {
  return (
    (swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI) &&
    (isTask || (!isTask && depth === 2) || stepType === StepTypes.SubStep)
  );
};

const filterBySWType = (key: StepComponentTypes, swType: SWTypes, isTask: boolean, depth:number, stepType:StepTypes|undefined) => {
  const support = swSupport.get(swType);

  // ppe filter out conditions.
  const tlmCondition = swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC;
  const mfgConditionAdd =
    (swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI) &&
    (isTask || (!isTask && depth === 1) || stepType === StepTypes.Step);
  const mfgPPEConditionRemove = shouldRemovePPEForMfg(
    depth,
    stepType,
    swType,
    isTask
  );

  if ((tlmCondition && !isTask) 
    || mfgPPEConditionRemove) {
    if(support){
      for (let i = 0; i < support.length; i++) {
        if (support[i] === StepComponentTypes.PPE) {
          support.splice(i, 1);
          i--;
        }
      }
    }
  } else if (support 
    && (tlmCondition 
      || mfgConditionAdd)) {
    support?.push(StepComponentTypes.PPE);
  }

  const ix = support
    ?.indexOf(key);

  return ix !== undefined && ix > -1;
}