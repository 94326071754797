import Banner, { BannerType } from "components/common/Banner";
import { ISelectInputComponent } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import ToggleButtonInput from "../controls/ToggleButtonInput";

interface ISelectInputProps {
  component: ISelectInputComponent,
  isDisabled: boolean,
}

const SelectInput: React.FC<ISelectInputProps> = ({ component, isDisabled }) => {
  const [value, setValue] = useState("");
  const [isExpectedValue, setIsExpectedValue] = useState(true);
  let uom = component.uom === undefined ? "" : component.uom;
  let meter = component.meterCode === undefined ? "" : component.meterCode;

  const onValueChange = (value: string) => {
    setValue(value);
    setIsExpectedValue(true);
    if (isDisabled) {
      return;
    }

    if (expectedValue !== undefined) {
      if (value !== expectedValue) {
        setIsExpectedValue(false);
      }
    }
  }

  let options: string[] = component.options;
  let nonConform: string = component.nonConform || "";
  let expectedValue: string | undefined = component.expectedValue;
  let shouldShowConformInfo = expectedValue !== undefined;
  let existingResponseNonConform = false;

  if (value && (value !== "" && value !== undefined)) {
    let selectedValue = value;

    if ((expectedValue !== undefined
      && selectedValue !== expectedValue)) {
      existingResponseNonConform = true;
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (!isExpectedValue
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <label>{component.label}</label>
      {meter && <label><br/>Meter : {meter} </label>}
      <ToggleButtonInput
        options={options}
        disabled={isDisabled}
        onAnswerChosen={val => onValueChange(val)}
        currentValue={value}
      />
    </div>
  );
}

export default SelectInput;