import { isErrorValidationErrorResponse } from "utilities/validationErrorHelpers";
import { ResponseError, ResponseErrorforRC } from "./responseError";

export async function throwIfResponseError(response: Response) {
  if (response.status === 500) {
    await throwHttp500Error(response, "The server encountered a fatal error.");
  } else if (response.status === 400) {
    await throwResponseError(response, "The request sent to the server was invalid.");
  } else if (response.status === 401) {
    await throwResponseError(response, "The requested resource requires authentication to access.");
  } else if (response.status === 403) {
    await throwResponseError(response, "You do not have permission to access the requested resource.");
  } else if (response.status === 404) {
    await throwResponseError(response, "The requested resource was not found.");
  } else if (response.status === 504) {
    await throwResponseError(response, "The requested resource took longer than expected. Please try again later.");
  } else if (response.status < 200 || response.status > 299) {
    await throwResponseError(response, `The server returned an error (${response.statusText}).`);
  }
}

async function throwHttp500Error(response: Response, defaultErrorMsg: string) {
  let responseText: string = "";
  try {
    responseText = await response.text();
  } catch {
    throw new Error(defaultErrorMsg);
  }

  if (responseText) {
    throw new Error(responseText.split('\n')[0]);
  }

  throw new Error(defaultErrorMsg);
}

async function throwResponseError(response: Response, defaultErrorMsg: string) {
  let jsonResponse: any;

  try {
    jsonResponse = await response.json();
  } catch {
    throw new Error(defaultErrorMsg);
  }
  
  if (isErrorValidationErrorResponse(jsonResponse)) {
    throw jsonResponse;
  } else if (jsonResponse.Message && jsonResponse.AfftectedSws) {
      throw new ResponseErrorforRC(jsonResponse.Message, jsonResponse.ErrorType ?? "Unknown", jsonResponse.AfftectedSws);
  } else if (jsonResponse.Message) {
      throw new ResponseError(jsonResponse.Message, jsonResponse.ErrorType ?? "Unknown");
  } else {
    throw new Error(defaultErrorMsg);
  }
}

