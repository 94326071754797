import React, { useEffect, useState } from "react";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import Modal from "components/common/Modal";
import "./FindAndReplace.scoped.scss";
import RadioGroup from "./RadioGroup/RadioGroup";

import {
  INoticeComponent,
  IStep,
  ISW,
  ITableComponent,
  ITableComponentCell,
  StepComponentType,
  StepComponentTypes,
  StepTypes,
} from "interfaces/sw/SWInterfaces";
import { finishUpdating, save } from "store/manageSW/manageSWActions";

interface IFindAndReplaceModal {
  header: string;
  message: string;
  onClose?: () => void;
}

const FindAndReplaceModal: React.FC<IFindAndReplaceModal> = (props) => {
  const { steps, type } = useSelector((store) => store.manageSW.SW) as ISW;
  const dispatch = useDispatch();
  type ComponentKeys =
    | "Notice"
    | "RichTextParagraph"
    | "QualityControlPoint"
    | "Table"
    | "EnhancedTable"
    | undefined;
  const { SW } = useSelector((store) => store.manageSW);
  const [selectedTitleTypes, setSelectedTitleTypes] =
    useState<StepTypes | null>(null);
  const [selectedComponentType, setSelectedComponentType] =
    useState<ComponentKeys | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [replacementText, setReplacementText] = useState("");
  const [isReplaced, setIsReplaced] = useState(false);
  const [textareaContent, setTextareaContent] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isForward, setIsForward] = useState(true);
  const [disableReplace, setDisableReplace] = useState(false);
  const [contentToSearch, setContentToSearch] = useState<
    {
      content: string;
      type: StepTypes | ComponentKeys;
      guid: string;
      parentTitle?: string;
      hasRoutingOperation: boolean;
      isRC: boolean;
      hasMasterStepID: boolean;
      rowIndex?: number;
      colIndex?: number;
      context?: "label" | "cell" | "html";
    }[]
  >([]);
  const [replaceItem, setReplaceItem] = useState<{
    guid: string;
    type?: StepTypes | ComponentKeys;
    rowIndex?: number;
    colIndex?: number;
    context?: "label" | "cell" | "html"; 
  } | null>(null);

  const [foundInMessage, setFoundInMessage] = useState<string>("");
  const titleOptions = [
    { value: "Task", label: "Task" },
    { value: "Step", label: "Step" },
    { value: "SubStep", label: "Sub-Step" },
  ];

  const componentOptions = [
    { value: "Notice", label: "Notice" },
    { value: "Table", label: "Table" },
    { value: "EnhancedTable", label: "Enhanced Table" },
    { value: "RichTextParagraph", label: "Rich Text Paragraph" },
    { value: "QualityControlPoint", label: "Quality Control Points" },
  ];

  const handleNavigation = (isNext: boolean) => {
    if (contentToSearch.length === 0) {
      performSearch();
      return;
    }
    setIsForward(isNext);
    setCurrentIndex((prevIndex) => {
      const newIndex = isNext
        ? (prevIndex + 1) % contentToSearch.length
        : (prevIndex - 1 + contentToSearch.length) % contentToSearch.length;

      handleFindText(newIndex);
      return newIndex;
    });
  };

  const performSearch = () => {
    setContentToSearch([]);
    setTextareaContent("");
    setFoundInMessage("");
    setCurrentIndex(0);

    if (!searchTerm.trim()) {
      setTextareaContent("Please enter a valid search term.");
      return;
    }
    const searchPattern = new RegExp(`(${searchTerm})`, "i");
    // we used this to handle initial search issues
    const newContentToSearch: any = [];
    const { titles, componentValues } = collectTitlesAndComponents(steps);

    titles.forEach((content) => {
      if (searchPattern.test(content.title)) {
        newContentToSearch.push({
          content: content.title,
          type: content.type,
          guid: content.guid,
          hasRoutingOperation: content.hasRoutingOperation,
          isRC: content.isRC,
          hasMasterStepID: content.hasMasterStepId,
        });
      }
    });
    
    componentValues.forEach((component) => {
    if (component.type === "Table" || component.type === "EnhancedTable") {
      // Searching table label
      if (component.content && component.context === "label" && searchPattern.test(component.content)) {
        newContentToSearch.push({
          content: component.content,    
          type: component.type,
          guid: component.guid,
          context: "label",             
          isRC: component.isRC,
          hasMasterStepID: component.hasMasterStepId,
        });
      }

      // Searching table cells
      if (component.context === "cell" && searchPattern.test(component.content)) {
        newContentToSearch.push({
          content: component.content,    
          type: component.type,
          guid: component.guid,
          rowIndex: component.rowIndex,  
          colIndex: component.colIndex,  
          context: "cell",               
          isRC: component.isRC,
          hasMasterStepID: component.hasMasterStepId,
        });
      }
    } else {
      let compContext = component.type === "RichTextParagraph" ? "html" : "label";
        if (searchPattern.test(component.content)) {
          newContentToSearch.push({
            content: component.content,
            context:compContext,
            type: component.type,
            guid: component.guid,
            isRC: component.isRC,
            hasMasterStepID: component.hasMasterStepId,
          });
        }
      }
    });
    
    if (newContentToSearch.length > 0) {
      setContentToSearch(newContentToSearch);
      setCurrentIndex(0);
      handleFindText(0);
    } else {
      setTextareaContent("No matches found.");
      setFoundInMessage("");
      setCurrentIndex(0);
    }
  };

  // to handle initial search
  useEffect(() => {
    if (contentToSearch.length > 0) {
      const currentItem = contentToSearch[currentIndex];
      if (currentItem.hasRoutingOperation) {
        setDisableReplace(true);
        setFoundInMessage("- Found, but Routing operation Task name cannot be replaced.");
      } else if (currentItem.isRC) {
        setDisableReplace(true);
        setFoundInMessage("- Found, but RC content cannot be replaced.");
      } else if (currentItem.hasMasterStepID) {
        setDisableReplace(true);
        setFoundInMessage("- Found, But CL SW content cannot be replaced.");
      } else {
        setDisableReplace(false);
        setFoundInMessage("");
      }
      handleFindText(0);
    }
  }, [contentToSearch]);

  const handleFindText = (index: number) => {
    if (!searchTerm.trim() || contentToSearch.length === 0) {
      setTextareaContent("No matches found.");
      setFoundInMessage("");
      return;
    }
    const searchPattern = new RegExp(`(${searchTerm})`, "gi");
    if (contentToSearch.length === 0) {
      return;
    }

    const validIndex = index % contentToSearch.length;
    const currentItem = contentToSearch[validIndex];

    if(currentItem.isRC){
      setDisableReplace(true);
      setFoundInMessage("- Found, but rc content cannot be replaced.");
    } else if (currentItem.hasRoutingOperation) {
      setDisableReplace(true);
      setFoundInMessage("- Found, but Routing operation Task name cannot be replaced.");
    }
    else if (currentItem.hasMasterStepID) {
      setDisableReplace(true);
      setFoundInMessage("Found, But CL SW content cannot be replaced.");
    } else {
      setDisableReplace(false);
      setFoundInMessage("");
    }

    if (!currentItem) {
      setTextareaContent("No matches found.");
      return;
    }

    setReplaceItem({
      guid: currentItem.guid,
      type: currentItem.type,
      rowIndex: currentItem.rowIndex,  // Include rowIndex if it's a table cell
      colIndex: currentItem.colIndex,  // Include colIndex if it's a table cell
      context: currentItem.context,    // Include context (label or cell)
    });

    const isTableComponent = (component: StepComponentType): component is ITableComponent => {
      return component.type === StepComponentTypes.Table || component.type === StepComponentTypes.EnhancedTable;
    };
    
// Handling table components specifically
if (currentItem.type === StepComponentTypes.Table || currentItem.type === StepComponentTypes.EnhancedTable) {
  const tableComponent = findTableComponentByGuid(currentItem.guid); // Find the table by GUID

  if (tableComponent && isTableComponent(tableComponent)) {
    if (currentItem.context === "label") {
      // Highlight the table label
      const highlightedLabel = tableComponent.label?.replace(searchPattern, (match) => `<mark>${match}</mark>`);
      setTextareaContent(highlightedLabel ?? "");  // Display the highlighted label
    } else if (currentItem.context === "cell") {
      // Highlight the specific cell using rowIndex and colIndex (if available)
      const highlightedCells = tableComponent.cells.map((cell) =>
        cell.rowIndex === currentItem.rowIndex && cell.colIndex === currentItem.colIndex
          ? cell.value.replace(searchPattern, (match) => `<mark>${match}</mark>`)
          : cell.value
      );
      setTextareaContent(highlightedCells.join(" "));  // Display the highlighted cells
    }
  }
} else {
  // Handle non-table components (RichTextParagraph, etc.)
  const highlightedContent = currentItem.content.replace(
    searchPattern,
    (match) => `<mark>${match}</mark>`
  );
  setTextareaContent(highlightedContent);
}

    if (currentItem.parentTitle !== undefined) {
      setFoundInMessage(
        `- Found in '${currentItem.type}' component of '${currentItem.parentTitle}'`
      );
    }

    // Ensure correct radio button selection based on the result type
    if (["Task", "Step", "SubStep"].includes(currentItem.type as string)) {
      setSelectedTitleTypes(currentItem.type as StepTypes);
      setSelectedComponentType(null);
    } else {
      setSelectedComponentType(currentItem.type as ComponentKeys);
      setSelectedTitleTypes(null);
    }
  };

  const findTableComponentByGuid = (guid: string): StepComponentType | undefined => {
    let foundComponent;
  
    const searchInSteps = (steps: IStep[]) => {
      for (const step of steps) {
        // Search in the components of each step
        for (const component of step.components) {
          if (component.guid === guid 
              && (component.type === StepComponentTypes.Table
              || component.type === StepComponentTypes.EnhancedTable
             )) {
            foundComponent = component;
            return;
          }
        }
        // Recursively search in child steps
        if (step.children.length > 0) {
          searchInSteps(step.children);
        }
      }
    };
  
    searchInSteps(steps); // Search in the current steps
  
    return foundComponent;
  };
  const collectTitlesAndComponents = (steps: IStep[]) => {
    let titles: {
      guid: string;
      title: string;
      type?: StepTypes;
      hasRoutingOperation: boolean;
      isRC: boolean;
      hasMasterStepId: boolean;
    }[] = [];
    let componentValues: {
      content: string;
      type: ComponentKeys;
      guid: string;
      parentTitle?: string;
      isRC: boolean;
      rowIndex?: number;                 // For Table cells: row index
      colIndex?: number; 
      context?: "label" | "cell" | "html"; 
      hasMasterStepId: boolean;
    }[] = [];

    const hasRouting = (step: IStep) => {
      return step.components.some((a) => a.type === "RoutingOperation");
    };
    const collect = (stepList: IStep[], depth = 0) => {
    const parentLCL = SW.type === "LCL" ? true : false;
      //collecting parent notice
      if (SW.notices?.length > 0) {
        SW.notices.forEach((notice) => {
          componentValues.push({
            guid: notice.guid,
            type: mapStepComponentTypeToComponentKey(notice.type),
            content: notice.label ?? "",
            parentTitle: '',
            isRC: false,
            context: "label", 
            hasMasterStepId: parentLCL,
          });
        });
      }
              
      stepList.forEach((step) => {
        const hasRoutingOperation = hasRouting(step) ? true : false;
        titles.push({
          guid: step.guid,
          title: step.title,
          type: step.isTask ? StepTypes.Task : step.stepType,
          hasRoutingOperation: hasRoutingOperation,
          isRC: step.rcID !== undefined && step.rcID !== null,
          hasMasterStepId : step.masterStepId !== undefined && step.masterStepId !== null,
        });
        const searchPattern = new RegExp(`(${searchTerm})`, "i");

        // Collect components with labels (e.g., Notice, Table, RichTextParagraph, etc.)
        step.components.forEach((component) => {
          if (component.type === StepComponentTypes.Table || component.type === StepComponentTypes.EnhancedTable ) {
            // Check the table label for matches
            if (component.label && searchPattern.test(component.label)) {
              componentValues.push({
                guid: component.guid,
                type: mapStepComponentTypeToComponentKey(component.type),
                content: component.label,    // Match in the table's label
                context: "label",            // Specify it's the label
                parentTitle: step.title,
                isRC: step.rcID !== undefined && step.rcID !== null,
                hasMasterStepId: step.masterStepId !== undefined && step.masterStepId !== null,
              });
            }
    
            // Check each cell in the table for matches
            if (component.cells) {
              component.cells.forEach((cell) => {
                if (searchPattern.test(cell.value)) {
                  componentValues.push({
                    guid: component.guid,
                    type: mapStepComponentTypeToComponentKey(component.type),
                    content: cell.value,      // Match in the table cell
                    rowIndex: cell.rowIndex,  // Row index of the matched cell
                    colIndex: cell.colIndex,  // Column index of the matched cell
                    context: "cell",          // Mark as a cell match
                    parentTitle: step.title, 
                    isRC: step.rcID !== undefined && step.rcID !== null,
                    hasMasterStepId: step.masterStepId !== undefined && step.masterStepId !== null,
                  });
                }
              });
            }
          } else if (component.type === StepComponentTypes.RichTextParagraph) {
            // Handle RichTextParagraph components
            if (searchPattern.test(component.html)) {
              componentValues.push({
                guid: component.guid,
                type: mapStepComponentTypeToComponentKey(component.type),
                content: component.html, // Use 'html' property instead of 'label'
                parentTitle: step.title,
                isRC: step.rcID !== undefined && step.rcID !== null,
                hasMasterStepId: step.masterStepId !== undefined && step.masterStepId !== null,
              });
            }
          } else if ("label" in component && component.label) {
            // Handle other components with labels
            if (searchPattern.test(component.label)) {
              let isRCComponent : boolean | undefined = false; 

              if ('rcID' in component) {
                if(component.rcID !== undefined && component.rcID !== null){  
                  isRCComponent = true;
                }
                else{
                  isRCComponent = step.rcID !== undefined && step.rcID !== null;
                }
              }
              componentValues.push({
                guid: component.guid,
                type: mapStepComponentTypeToComponentKey(component.type),
                content: component.label,
                parentTitle: step.title,
                isRC: isRCComponent,
                hasMasterStepId: step.masterStepId !== undefined && step.masterStepId !== null,
              });
            }
          }
        });

        // Recursively collect child steps (substeps) after the parent step
        if (step.children.length > 0) {
          collect(step.children, depth + 1);
        }
      });
    };
    collect(steps);
    return { titles, componentValues };
  };

  const mapStepComponentTypeToComponentKey = (
    type: StepComponentTypes
  ): ComponentKeys => {
    switch (type) {
      case StepComponentTypes.Notice:
        return "Notice";
      case StepComponentTypes.RichTextParagraph:
        return "RichTextParagraph";
      case StepComponentTypes.QualityControlPoint:
        return "QualityControlPoint";
      case StepComponentTypes.Table:
        return "Table";
      case StepComponentTypes.EnhancedTable:
        return "EnhancedTable";
      default:
        return undefined;
    }
  };

  const handleReplace = () => {
    if (!searchTerm || !replacementText || !replaceItem) {
      return;
    } 
    const { guid, type, rowIndex, colIndex,context } = replaceItem;
    // Update individual components based on the type of component
    const updateContent = (component: StepComponentType, newValue: string) => {
      switch (component.type) {
        case StepComponentTypes.Notice:
        case StepComponentTypes.QualityControlPoint:
            return updateComponentLabel(component, newValue);
        case StepComponentTypes.Table:
        case StepComponentTypes.EnhancedTable:
            if ("cells" in component) {
              if (context === "cell" && component.cells) {
                // Update only the specific cell in the Table component based on rowIndex and colIndex
                const updatedCells = component.cells.map((cell) =>
                  cell.rowIndex === rowIndex && cell.colIndex === colIndex
                    ? { ...cell, value: cell.value.replace(new RegExp(`(${searchTerm})`, "gi"), newValue) }
                    : cell
                );
                return {
                  ...component,
                  cells: updatedCells,
                };
              } else if (context === "label") {
                // Replace the table label
                return {
                  ...component,
                  label: component.label?.replace(new RegExp(`(${searchTerm})`, "gi"), newValue),
                };
              }
            }
            return component;
        case StepComponentTypes.RichTextParagraph:
          // Update html content for RichTextParagraph components
          return {
            ...component,
            html: component.html.replace(
              new RegExp(`(${searchTerm})`, "gi"),
              newValue
            ),
          };

        default:
          return component;
      }
    };
    const updateComponentLabel = (component: any, newValue: string) => {
      if (context === "label" && component.label) {
        return {
          ...component,
          label: component.label.replace(new RegExp(`(${searchTerm})`, "gi"), newValue),
        };
      }
      console.warn(`No label found for component with guid: ${component.guid}`);
      return component;
    };
    // Recursively updating steps' titles or components based on the GUID and type
    const updateStepContent = (steps: IStep[]): IStep[] => {
      return steps.map((step) => {
        const isForTitle = (type: string | undefined): boolean =>
          ["Task", "Step", "SubStep"].includes(type ?? "");
        if (step.guid === guid && isForTitle(type)) {
          return {
            ...step,
            title: step.title.replace(
              new RegExp(`(${searchTerm})`, "gi"),
              replacementText
            ),
          };
        }

        // Replace the content for the exact matched component based on GUID
        return {
          ...step,
          components: step.components.map((component) =>
            component.guid === guid &&
            type === mapStepComponentTypeToComponentKey(component.type)
              ? updateContent(component, replacementText)
              : component
          ),
          children:
            step.children.length > 0
              ? updateStepContent(step.children) // Recursively update children if needed
              : step.children,
        };
      });
    };

    // Updating parent-level notices if a notice is replaced
    const updateNotices = (notices: INoticeComponent[]): INoticeComponent[] => {
      return notices.map((notice) => {
        if (notice.guid === guid) {
          return {
            ...notice,
            label: notice.label?.replace(
              new RegExp(`(${searchTerm})`, "gi"),
              replacementText
            ), // Replace in parent-level notice
          };
        }
        return notice;
      });
    };
    const currentSW = SW;

    // Update the SW object with the new steps and updated content
    const updatedSW = {
      ...currentSW,
      steps: updateStepContent(currentSW.steps),
      notices: updateNotices(currentSW.notices),
    };

    dispatch(finishUpdating(updatedSW));
    setTextareaContent(`Replaced "${searchTerm}" with "${replacementText}"`);
    setIsReplaced(true);

    setFoundInMessage("");
    setContentToSearch([]);
  };

  const handleSave = () => {
    dispatch(save({ isVersionChange: false }));
    setIsReplaced(false);
  };
  return (
    <Modal
      header={props.header}
      controls={
        <>
          <button
            className="primary-button ok-button"
            onClick={handleSave}
            disabled={!isReplaced}
          >
            Save
          </button>
          <button className="primary-button ok-button" onClick={props.onClose}>
            Cancel
          </button>
        </>
      }
      isOpen={true}
    >
      <p>{props.message}</p>
      <div className="container">
        <div className="row">
          <input
            type="text"
            className="textbox"
            placeholder="Find what"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setContentToSearch([]);
              setCurrentIndex(0);
            }}
          />
          <button
            className="button primary-button"
            onClick={() => handleNavigation(true)}
            disabled={!searchTerm.trim()}
          >
            Find Next
          </button>
          <button
            className="button primary-button"
            onClick={() => handleNavigation(false)}
            disabled={!searchTerm.trim()}
          >
            Find Prev
          </button>
        </div>
        <div className="row">
          <input
            type="text"
            className="textbox"
            placeholder="Replace with"
            value={replacementText}
            onChange={(e) => setReplacementText(e.target.value)}
          />
          <button
            className="button primary-button"
            onClick={handleReplace}
            disabled={
              !searchTerm.trim() || !replacementText.trim() || disableReplace
            }
          >
            Replace
          </button>
        </div>
        <div>
          <p className="search-result">
            {`Search Result ${
              foundInMessage == undefined ? "" : foundInMessage
            }`}
          </p>
        </div>
        <div className="radio-row">
          <RadioGroup
            title="&nbsp;"
            name="titleText"
            options={titleOptions}
            selectedValue={selectedTitleTypes ?? ""}
          />
          <RadioGroup
            title="&nbsp;"
            name="components"
            options={componentOptions}
            selectedValue={selectedComponentType ?? ""}
          />
        </div>
        <div className="text-area-container">
          <div dangerouslySetInnerHTML={{ __html: textareaContent }} />
        </div>
      </div>
    </Modal>
  );
};

export default FindAndReplaceModal;
