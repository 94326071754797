import "./SWMetadata.scoped.scss";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import {
    DataClassificationTypes,
    ContentTypes,
    ILinkComponent,
    ShowCLSignatureBlock,
    StepComponentTypes,
    SWTypes,
    TaskAlignments,
    TemplateTypes,
    TLMSWIType,
    ServiceLevel,
} from "interfaces/sw/SWInterfaces";
import React from "react";
import { useDispatch } from "react-redux";
import {
    addMetaDataItem,
    removeMetaDataItem,
    setDataClassification,
    setTCC,
    setContentType,
    setEquivalentSW,
    setHazardCategory,
    setLanguage,
    setOwningOrg,
    setOwningPlant,
    setStringAttribute,
    setTaskAlignment,
    setTemplateType,
    setTLMSWIType,
    setGroup,
    setGroupCounter,
    updateTaskStepsMetadata,
    setLevelStringAttribute
} from "store/manageSW/manageSWActions";
import {
    loadCountries,
    loadGeoUnits,
    loadGroupCounter,
    loadGroups,
    loadHazardCategories,
    loadLanguages,
    loadOrganizations,
    searchOwningPlants,
    searchPerformingPlants,
    loadSafetyCategories,
    loadSubBusinessLines,
    searchActivityWorkflows,
    searchBusinessRoles,
    searchCompetencyElements,
    searchCustomers,
    searchEpicEquipment,
    searchEquipment,
    searchEquivalentSWs,
    searchMaterials,
    searchOperatingEnvironments,
    searchProductCenters,
    searchServiceTypes,
    searchTasks,
    searchWorkCenters,
    searchEvents,
    setSection,
    loadGemsRevisionNumbers
} from "store/masterData/masterDataActions";
import useSelector from "store/useSelector";
import LinkEditor from "components/sw/manage/steps/components/LinkEditor";
import { Routes } from "components/routing/Routing";
import { showInfoToast } from "store/toast/toastActions";

interface ISWMetadataProps {
    metadataName: "activityWorkflows" |
    "competencyElements" |
    "countries" |
    "geoUnits" |
    "equipment" |
    "epicEquipment" |
    "equivalentSWs" |
    "languages" |
    "operatingEnvironments" |
    "organizations" |
    "subBusinessLines" |
    "customers" |
    "safetyCategories" |
    "businessRoles" |
    "productCenters" |
    "serviceTypes" |
    "tasks" |
    "performingOrganization" |
    "title" |
    "description" |
    "taskAlignment" |
    "versionChanges" |
    "globalOrLocal" |
    "introduction" |
    "maintenanceTask" |
    "CEMarking" |
    "foreword" |
    "harc" |
    "parentChecklist" |
    "owingPlants" |
    "performingPlants" |
    "workCenters" |
    "materials" |
    "gemsRevisionNumber" |
    "showclsignatureblock" |
    "dataClassification" |
    "tcc" |
    "templateType" |
    "contentType" |
    "groups" |
    "groupCounter" |
    "events" |
    "customizedEvents" |
    "levels"
    isDisabled: boolean,
    enableGlobalLocal?: boolean,
    closeOnEmptyList?: boolean,
}

const TypesWithoutMandatoryEquivalentSW = [
    SWTypes.MFGSWI,
    SWTypes.MFGCL,
    SWTypes.MFGRC,
];

const SWMetadata: React.FC<ISWMetadataProps> = ({ metadataName, isDisabled, enableGlobalLocal, closeOnEmptyList }) => {
    const {
        manageSW: {
            SW,
        },
        masterData: {
            activityWorkflows,
            competencyElements,
            countries,
            geoUnits,
            equipment,
            epicEquipment,
            equivalentSWs,
            languages,
            operatingEnvironments,
            organizations,
            subBusinessLines,
            customers,
            safetyCategories,
            businessRoles,
            productCenters,
            serviceTypes,
            tasks,
            owningPlants,
            performingPlants,
            workCenters,
            materials,
            gemsRevisionNumbers,
            groups,
            groupCounter,
            events,
            customizedEvents,
        },
    } = useSelector(store => store);
    const dispatch = useDispatch();
    let isTitleEditable = SW.type === SWTypes.ECL
        || SW.type === SWTypes.TLMSWI
        || SW.type === SWTypes.MFGSWI
        || SW.type === SWTypes.TLMRC
        || SW.type === SWTypes.MFGCL
        || SW.type === SWTypes.MFGRC
        || SW.language?.value !== "English";
    let disabledTitle = SW.type === SWTypes.TLMRC
        || SW.type === SWTypes.MFGSWI
        || SW.type === SWTypes.MFGCL
        || SW.type === SWTypes.MFGRC ?
        isDisabled :
        (!isTitleEditable || SW.type === SWTypes.LCL || isDisabled);
    let clLink: ILinkComponent = {
        url: Routes.EditStandardWork.replace(":guid", SW.clGuid ? SW.clGuid : "").replace(":version", SW.clVersion ? SW.clVersion.toString() : "0"),
        guid: "",
        type: StepComponentTypes.Link,
        sortOrder: 1,
        label: SW.clTitle + " - " + SW.description + " - " + SW.clGuid,
    };

    let isRequiredEquivalentSW =
        !TypesWithoutMandatoryEquivalentSW.includes(SW.type) &&
        SW.language && SW.language.value !== "English";

    let component: JSX.Element | undefined;

    const onRemovePlant = () => {
        dispatch(setOwningPlant({ owningPlant: undefined, swType: SW.type }));
        dispatch(setSection({ name: "workCenters", section: { items: [] } }));
        dispatch(setSection({ name: "performingPlants", section: { items: [] } }));
        dispatch(setSection({ name: "materials", section: { items: [] } }));
        if (SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL) {
            dispatch(setGroup(undefined));
            dispatch(setGroupCounter(undefined));
            dispatch(loadGroups({ plantCode: undefined, materialCode: undefined, plantSource: undefined }));

            dispatch(
                loadGroupCounter({
                    plantCode: undefined,
                    materialCode: undefined,
                    group: undefined,
                    plantSource: undefined,
                })
            );
        }
    }

    const onRemoveMaterial = (item: IMasterDataItem) => {
        if (SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL) {
            dispatch(setGroup(undefined));
            dispatch(setGroupCounter(undefined));
            dispatch(loadGroups({ plantCode: SW.owningPlant?.guid, materialCode: undefined, plantSource:SW.owningPlant?.source }));

            dispatch(
                loadGroupCounter({
                    plantCode: SW.owningPlant?.guid,
                    materialCode: undefined,
                    group: undefined,
                    plantSource: SW.owningPlant?.source
                })
            );
            if(routingChangeWarning){
                dispatch(showInfoToast("This will change the Routing data in the Standard Work"))
            }
        }

        dispatch(removeMetaDataItem({
            metaDataName: "materials",
            item,
        }))
    }

    const onRemoveGroup = () => {
        dispatch(setGroup(undefined));
        dispatch(setGroupCounter(undefined));

        dispatch(
            loadGroupCounter({
                plantCode: SW.owningPlant?.guid,
                materialCode:
                    SW.materials.length > 0 ? SW.materials[0].guid : undefined,
                group: undefined,
                plantSource: SW.owningPlant?.source,
            })
        );
    };

    function isEventMandatory(): boolean {
        if (SW.type === SWTypes.TLMSWI
            && SW.contentType === ContentTypes.Executable.toString()
            && (SW.templateType === TemplateTypes.Standard.toString()
                || SW.templateType === TemplateTypes.Consolidated.toString())) {
            return true;
        }
        else {
            return false;
        }
    }

    function isMaintenanceTaskMandatory(): boolean {
        return false;
    }
 
     
    function isLevelMandatory(): boolean { 
        if (SW.type === SWTypes.TLMSWI
            && SW.contentType === ContentTypes.Executable.toString()
            && SW.templateType === TemplateTypes.Standard.toString()
            && SW.events.length > 0 && SW.events[0].code
            && customizedEvents.items.filter(a => a.code === SW.events[0].code).length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const onTemplateTypeChanged = (templateType: TemplateTypes) => {
        dispatch(setTemplateType(templateType));

        if (templateType === TemplateTypes.Consolidated) {
            dispatch(setContentType(ContentTypes.Executable));
            dispatch(updateTaskStepsMetadata({ metadataName: "serviceLevel", value: ServiceLevel.SL_0 }));
            dispatch(setLevelStringAttribute({ attributeName: "level", value: undefined }))
        } else {
            dispatch(updateTaskStepsMetadata({ metadataName: "serviceLevel", value: null }));
        }
        dispatch(setContentType(ContentTypes.Executable));

    }

    const routingChangeWarning =
        [SWTypes.MFGSWI, SWTypes.MFGCL].includes(SW.type) &&
        SW.hasPublishedVersion;

    const renderEpicEquipment = () => {
        return (
            <MasterDataInput
                label="EPIC Equipment"
                isMandatory={SW.type === SWTypes.TLMRC || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL ? false : "submitOnly"}
                disabled={isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.epicEquipment}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "epicEquipment",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "epicEquipment",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchEpicEquipment({ searchTerm, onlyActive: true }))}
                masterDataSection={epicEquipment}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    // const renderHazardCategories = () => {
    //     return (
    //         <MasterDataInput
    //             label="Hazard Category"
    //             selectedItems={SW.hazardCategory ? [SW.hazardCategory] : []}
    //             masterDataSection={hazardCategories}
    //             loadItems={() => dispatch(loadHazardCategories())}
    //             onAddItem={(item) => dispatch(setHazardCategory(item))}
    //             onRemoveItem={() => dispatch(setHazardCategory(undefined))}
    //             itemFormatter={(item) => item.value}
    //             allowMultiple={false}
    //             isMandatory={
    //                 SW.type === SWTypes.TLMRC && SW.notices.length > 0
    //                     ? "submitOnly"
    //                     : false
    //             }
    //             disabled={isDisabled}
    //         />
    //     );
    // };

    const renderProductCenters = () => {
        return (
            <MasterDataInput
                label="Product Center"
                isMandatory={
                    SW.type === SWTypes.TLMRC ||
                        (SW.type === SWTypes.TLMSWI && SW.TLMSWIType === TLMSWIType.Local) ||
                        SW.type === SWTypes.MFGSWI ||
                        SW.type === SWTypes.MFGCL
                        ? false
                        : "submitOnly"
                }
                isAsyncSearch={true}
                disabled={isDisabled}
                selectedItems={SW.productCenters}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "productCenters",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "productCenters",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchProductCenters({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={productCenters}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderTask = () => {
        let _label: "SPX Task" | "Tasks" | "Task";

        switch (SW.type) {
            case SWTypes.TLMSWI:
                _label = "SPX Task";
                break;
            case SWTypes.ECL:
                _label = "Tasks";
                break;
            case SWTypes.MFGSWI:
                _label = "SPX Task";
                break;
            case SWTypes.MFGCL:
                _label = "SPX Task";
                break;
            default:
                _label = "Task";
        }

        let isMandatory: boolean | "submitOnly" = false;

        if ([SWTypes.CL, SWTypes.SWI, SWTypes.LCL].includes(SW.type)) {
            isMandatory = SW.language?.value === "English" ? true : "submitOnly";
        }

        if (SW.type === SWTypes.ECL && SW.activityWorkflows.length === 0) {
            isMandatory = "submitOnly";
        }

        return (
            <MasterDataInput
                label={_label}
                isMandatory={isMandatory}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.tasks}
                allowMultiple={SW.type === SWTypes.ECL}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "tasks",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "tasks",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchTasks({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={tasks}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderEquipment = () => {
        return (
            <MasterDataInput
                label={SW.type === SWTypes.TLMSWI || SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL
                    ? "SPX Equipment"
                    : "Equipment"}
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.equipment}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "equipment",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "equipment",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchEquipment({ searchTerm, onlyActive: true }))}
                masterDataSection={equipment}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }
    function isDisabledEdit(): boolean {
        if (SW.type === SWTypes.TLMSWI && SW.maximoOrgEventCheck) {
            return true;
        }
        return isDisabled;
    }

    const renderOwningOrganization = () => {
        return (
            <MasterDataInput
                label="Owning Organization"
                isMandatory={true}
                disabled={SW.type === SWTypes.LCL || isDisabledEdit()}
                selectedItems={SW.owningOrg ? [SW.owningOrg] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setOwningOrg({ owningOrg: item, swType: SW.type }))}
                onRemoveItem={() => dispatch(setOwningOrg({ owningOrg: undefined, swType: SW.type }))}
                loadItems={() => dispatch(loadOrganizations({ onlyActive: true }))}
                masterDataSection={organizations}
                itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
                isAsyncSearch={false}
            />
        );
    }

    const renderSubBusinessLine = () => {
        return (
            <MasterDataInput
                label="Sub BusinessLines"
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                selectedItems={SW.subBusinessLines}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "subBusinessLines",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "subBusinessLines",
                    item,
                }))}
                loadItems={() => dispatch(loadSubBusinessLines({ onlyActive: true }))}
                masterDataSection={subBusinessLines}
                itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
                isAsyncSearch={false}
            />
        );
    }

    const renderPerformingOrganization = () => {
        return (
            <MasterDataInput
                label="Performing Organizations"
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                selectedItems={SW.performingOrgs}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "performingOrgs",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "performingOrgs",
                    item,
                }))}
                loadItems={() => dispatch(loadOrganizations({ onlyActive: true }))}
                masterDataSection={organizations}
                itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
                isAsyncSearch={false}
            />
        );
    }

    const renderCompetencyElement = () => {
        return (
            <MasterDataInput
                label={"Competency Elements"}
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.competencyElements}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "competencyElements",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "competencyElements",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchCompetencyElements({ searchTerm, onlyActive: true }))}
                masterDataSection={competencyElements}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderCountry = () => {
        return (
            <MasterDataInput
                label={SW.type === SWTypes.TLMSWI
                    ? "Country"
                    : "Geography: Country"}
                isMandatory={SW.type === SWTypes.LCL ? true : false}
                disabled={isDisabled}
                selectedItems={SW.countries}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "countries",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "countries",
                    item,
                }))}
                loadItems={() => dispatch(loadCountries({ onlyActive: true }))}
                masterDataSection={countries}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderCustomer = () => {
        return (
            <MasterDataInput
                label="Customer"
                isMandatory={false}
                disabled={isDisabled}
                selectedItems={SW.customers}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "customers",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "customers",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchCustomers({ searchTerm, onlyActive: true }))}
                masterDataSection={customers}
                itemFormatter={(item: IMasterDataItem) => item.value}
                isAsyncSearch={true}
            />
        );
    }

    const renderServiceType = () => {
        return (
            <MasterDataInput
                label={"Service Types"}
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.serviceTypes}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "serviceTypes",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "serviceTypes",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchServiceTypes({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={serviceTypes}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderOperatingEnvironment = () => {
        return (
            <MasterDataInput
                label={"Operating Environments"}
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.operatingEnvironments}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "operatingEnvironments",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "operatingEnvironments",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchOperatingEnvironments({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={operatingEnvironments}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderSafetyCategory = () => {
        return (
            <MasterDataInput
                label="Safety Category"
                isMandatory={false}
                disabled={SW.type === SWTypes.LCL || isDisabled}
                selectedItems={SW.safetyCategories}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "safetyCategories",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "safetyCategories",
                    item,
                }))}
                loadItems={() => dispatch(loadSafetyCategories({ onlyActive: true }))}
                masterDataSection={safetyCategories}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderGeoUnit = () => {
        return (
            <MasterDataInput
                label="GeoUnit"
                isMandatory={
                    SW.TLMSWIType === TLMSWIType.Local ? "submitOnly" : false
                }
                disabled={isDisabled}
                selectedItems={SW.geoUnits}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "geoUnits",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "geoUnits",
                    item,
                }))}
                loadItems={() => dispatch(loadGeoUnits({ onlyActive: true }))}
                masterDataSection={geoUnits}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderActivityWorkflow = () => {
        return (
            <MasterDataInput
                label={"Activity Workflows"}
                isMandatory={SW.tasks.length === 0 ? "submitOnly" : false}
                disabled={isDisabled}
                isAsyncSearch={true}
                selectedItems={SW.activityWorkflows}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "activityWorkflows",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "activityWorkflows",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchActivityWorkflows({ searchTerm, onlyActive: true }))}
                masterDataSection={activityWorkflows}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.value}<br />
                        {item.code}
                    </span>
                )}
            />
        );
    }

    const renderEquivalentSW = () => {
        return (
            <MasterDataInput
                label={"Equivalent SW"}
                isMandatory={isRequiredEquivalentSW ? "submitOnly" : false}
                isAsyncSearch={true}
                selectedItems={SW.equivalentSW ? [SW.equivalentSW] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setEquivalentSW(item))}
                onRemoveItem={() => dispatch(setEquivalentSW(undefined))}
                loadItems={(searchTerm: string) => dispatch(searchEquivalentSWs({ searchTerm, SWType: SW.type }))}
                masterDataSection={equivalentSWs}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.value}<br />
                        {item.code}
                    </span>
                )}
                disabled={(SW.language?.value === "English" && SW.countries.length === 0 && SW.geoUnits.length === 0) || SW.type === SWTypes.LCL || isDisabled}
            />
        );
    }

    const renderBusinessRole = () => {
        return (
            <MasterDataInput
                label="Business Role"
                isMandatory={false}
                isAsyncSearch={true}
                disabled={isDisabled}
                selectedItems={SW.businessRoles}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "businessRoles",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "businessRoles",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchBusinessRoles({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={businessRoles}
                itemFormatter={(item: IMasterDataItem) => item.value}
            />
        );
    }

    const renderLanguage = () => {
        return (
            <MasterDataInput
                label="Language"
                isMandatory="submitOnly"
                selectedItems={SW.language ? [SW.language] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setLanguage({
                    masterDataItem: item,
                    countrySelected: SW.countries.length > 0, geoUnitSelected: SW.geoUnits.length > 0
                }))}
                onRemoveItem={() => dispatch(setLanguage({
                    masterDataItem: undefined,
                    countrySelected: SW.countries.length > 0, geoUnitSelected: SW.geoUnits.length > 0
                }))}
                loadItems={() => dispatch(loadLanguages({ onlyActive: true }))}
                masterDataSection={languages}
                itemFormatter={(item: IMasterDataItem) => item.value}
                disabled={SW.type === SWTypes.LCL || isDisabled}
            />
        );
    }

    const renderTitle = () => {
        return (
            <>
                <label className="mandatory">Title</label>
                <input
                    type="text"
                    value={SW.title}
                    disabled={disabledTitle}
                    onChange={isTitleEditable
                        ? (e) => dispatch(setStringAttribute({ attributeName: "title", value: e.target.value }))
                        : undefined}

                />
            </>
        );
    }

    const renderDescription = () => {
        return (
            <>
                <label>Description</label>
                <input
                    type="text"
                    value={SW.description}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "description", value: e.target.value }))}
                    disabled={SW.type === SWTypes.LCL || isDisabled}
                />
            </>
        );
    }

    const renderTaskAlignment = () => {
        return (
            <>
                <label className="mandatory">Task Alignment</label>
                <select
                    onChange={(e) =>
                        dispatch(setTaskAlignment(e.target.value as TaskAlignments))
                    }
                    value={SW.taskAlignment}
                    disabled={
                        SW.type === SWTypes.SWI || SW.type === SWTypes.LCL || isDisabled
                    }
                >
                    {Object.keys(TaskAlignments)
                        .filter(
                            (t) =>
                                t !== "Unspecified" &&
                                (SW.type === SWTypes.CL || SW.type === SWTypes.MFGCL ? t !== "At" : true)
                        )
                        .map((t) => (
                            <option key={t}>{t}</option>
                        ))}
                </select>
            </>
        );
    }

    const renderVersionChanges = () => {
        return (
            <>
                <label className="mandatory-alternative">
                    Version Changes
                </label>
                <input
                    type="text"
                    value={SW.versionChanges}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "versionChanges", value: e.target.value }))}
                    disabled={isDisabled} />
            </>
        );
    }

    const renderShowCLSignatureBlock = () => {
        return (
            <>
                <label className="mandatory">ShowCLSignatureBlock</label>
                <select
                    value={SW.showCLSignatureBlock?.toString()}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "showclsignatureblock", value: e.target.value }))}
                    disabled={SW.type === SWTypes.LCL || isDisabled}
                >
                    {Object.keys(ShowCLSignatureBlock).map(t =>
                        <option
                            key={t}
                        >
                            {t}
                        </option>
                    )}
                </select>
            </>
        );
    }

    const renderGlobalOrLocal = () => {
        return (
            <>
                <label className="mandatory">Global or Local?</label>
                <select
                    value={SW.TLMSWIType}
                    onChange={(e) => dispatch(setTLMSWIType(e.target.value as TLMSWIType))}
                    disabled={enableGlobalLocal || isDisabled}
                >
                    {Object.keys(TLMSWIType).map(t =>
                        <option
                            key={t}
                        >
                            {t}
                        </option>
                    )}
                </select>
            </>
        );
    }

    const renderTemplateType = () => {
        return (
            <>
                <label className="mandatory">Template Type</label>
                <select
                    value={SW.templateType}
                    onChange={(e) => onTemplateTypeChanged(e.target.value as TemplateTypes)}
                    disabled={isDisabled}
                >
                    {Object.keys(TemplateTypes).map(t =>
                        <option
                            key={t}
                        >
                            {t}
                        </option>
                    )}
                </select>
            </>
        );
    }

    const renderDataClassificationBlock = () => {
        return (
            <>
                <label className="mandatory-alternative">Data Classification</label>
                <select
                    value={SW.dataClassification}
                    onChange={(e) => dispatch(setDataClassification(e.target.value as DataClassificationTypes))}
                    disabled={isDisabled}
                >
                    {Object.keys(DataClassificationTypes).map(t =>
                        <option
                            key={t}
                        >
                            {t}
                        </option>
                    )}
                </select>
            </>
        );
    }

    const renderTCCBlock = () => {
        return (
            <>
                <label>TCC</label>
                <div className="tcc-container">
                    <input type="checkbox"
                        style={{ width: "25px", height: "25px" }}
                        checked={SW.tcc}
                        onChange={(e) => dispatch(setTCC(e.target.checked))}
                        disabled={isDisabled}
                    />
                    {SW.tcc && <span style={{ color: "rgb(177, 0, 0)", fontSize: "small" }}>
                        You are creating TCC confidential content. Please use 'Manage Confidential TCC SW.
                    </span>
                    }
                </div>
            </>
        );
    }

    const renderContentTypeBlock = () => {
        return (
            <>
                <label className="mandatory">Content Type</label>
                <select
                    value={SW.contentType}
                    onChange={(e) => dispatch(setContentType(e.target.value as ContentTypes))}
                    disabled={isDisabled || SW.templateType === TemplateTypes.Consolidated}
                >
                    {Object.keys(ContentTypes).map(t =>
                        <option
                            key={t}
                        >
                            {t}
                        </option>
                    )}
                </select>
            </>
        );
    }

    const rendermaintenanceTask = () => {
        return (
            <>
                <label className={isMaintenanceTaskMandatory() ? "mandatory" : ""}>
                    Maintenance Task
                </label>
                <input
                    type="text"
                    value={SW.maintenanceTaskIds}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "maintenanceTaskIds", value: e.target.value }))}
                    disabled={isDisabled}
                />
            </>
        );
    }

    const renderIntroduction = () => {
        return (
            <>
                <label>Introduction</label>
                <input
                    type="text"
                    value={SW.introduction}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "introduction", value: e.target.value }))}
                    disabled={isDisabled}
                />
            </>
        );
    }

    const renderForeword = () => {
        return (
            <>
                <label>Foreword</label>
                <input
                    type="text"
                    value={SW.foreward}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "foreward", value: e.target.value }))}
                    disabled={isDisabled} />
            </>
        );
    }

    const renderHarc = () => {
        return (
            <>
                <label>HARC</label>
                <input
                    type="text"
                    value={SW.harc}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "harc", value: e.target.value }))}
                    disabled={isDisabled} />
            </>
        );
    }

    const renderCEMarking = () => {
        return (
            <>
                <label>CE Marking</label>
                <input
                    type="text"
                    value={SW.ceMarking}
                    onChange={(e) => dispatch(setStringAttribute({ attributeName: "ceMarking", value: e.target.value }))}
                    disabled={isDisabled} />
            </>
        );
    }

    const renderParentChecklist = () => {
        return (
            <>
                <label>
                    Parent Checklist
                </label>
                <LinkEditor
                    allowEdit={false}
                    stepGuid={""}
                    linkComponent={clLink}
                />
            </>
        );
    }

    const renderOwningPlants = () => {
        return (
            <MasterDataInput
                label="Owning Plant"
                isMandatory={SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGCL || SW.type === SWTypes.MFGRC}
                disabled={isDisabled}
                selectedItems={SW.owningPlant ? [SW.owningPlant] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setOwningPlant({ owningPlant: item, swType: SW.type }))}
                onRemoveItem={() => onRemovePlant()}
                loadItems={(searchTerm: string) => dispatch(searchOwningPlants({
                    searchTerm: searchTerm,
                    onlyActive: true
                }))}
                masterDataSection={owningPlants}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.code}{"-"}{item.value}
                    </span>
                )}
                isAsyncSearch={true}
            />
        );
    }

    const renderPerformingPlants = () => {
        return (
            <MasterDataInput
                label="Performing Plants"
                isMandatory={false}
                disabled={isDisabled}
                selectedItems={SW.performingPlants}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "performingPlants",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "performingPlants",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchPerformingPlants({
                    searchTerm: searchTerm,
                    onlyActive: true,
                    plantSource: SW.plantSource,
                }))}
                masterDataSection={performingPlants}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.code}{"-"}{item.value}
                    </span>
                )}
                isAsyncSearch={true}
            />
        );
    }

    const renderWorkCenter = () => {
        return (
            <MasterDataInput
                label="Work Centers"
                isMandatory={false}
                isAsyncSearch={true}
                disabled={isDisabled}
                selectedItems={SW.workCenters}
                allowMultiple={true}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "workCenters",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "workCenters",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchWorkCenters({
                    searchTerm: searchTerm,
                    plantCode: SW.owningPlant?.code ? SW.owningPlant?.code : "",
                    plantSource: SW.owningPlant?.source ? SW.owningPlant?.source : undefined,
                    onlyActive: true
                }))}
                masterDataSection={workCenters}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.code}{"-"}{item.value}
                    </span>
                )}
            />
        );
    }

    const renderMaterials = () => {
        return (
            <MasterDataInput
                label="Material"
                isMandatory={SW.type === SWTypes.MFGCL ? "submitOnly" : SW.type === SWTypes.MFGSWI}
                isAsyncSearch={true}
                disabled={isDisabled}
                selectedItems={SW.materials}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "materials",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => onRemoveMaterial(item)}
                loadItems={(searchTerm: string) => dispatch(searchMaterials({
                    searchTerm: searchTerm,
                    plantCode: SW.owningPlant?.code ? SW.owningPlant?.code : "",
                    plantSource: SW.owningPlant?.source ? SW.owningPlant?.source : undefined,
                    onlyActive: true
                }))}
                masterDataSection={materials}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.code}{"-"}{item.value}
                    </span>
                )}
            />
        );
    }

    const renderGemsRevisionNumber = () => {
        return (
            <MasterDataInput
                label="Revision Number"
                isMandatory={
                    SW.type === SWTypes.MFGCL ? "submitOnly" :
                    (
                        SW.type === SWTypes.MFGSWI ||
                        (SW.type === SWTypes.MFGRC && SW.materials.length > 0)
                    )
                }
                isAsyncSearch={false}
                disabled={isDisabled}
                selectedItems={SW.gemsRevisionNumber ? [SW.gemsRevisionNumber] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "gemsRevisionNumber",
                    item,
                }))
                }
                onRemoveItem={(item) => dispatch(removeMetaDataItem({
                    metaDataName: "gemsRevisionNumber",
                    item,
                }))}
                loadItems={() => dispatch(loadGemsRevisionNumbers({
                    plantCode: SW.owningPlant?.guid,
                    materialCode: SW.materials[0]?.code,
                    plantSource: SW.owningPlant?.source,
                    onlyActive: true
                }))}
                masterDataSection={gemsRevisionNumbers}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.value}
                    </span>
                )}
                closeOnEmptySection={true}
            />
        );
    }

    const renderGroups = () => {
        return (
            <MasterDataInput
                label="Group"
                isMandatory={SW.type === SWTypes.MFGCL ? "submitOnly" : SW.type === SWTypes.MFGSWI}
                disabled={isDisabled}
                selectedItems={SW.group ? [SW.group] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setGroup(item))}
                onRemoveItem={() => onRemoveGroup()}
                loadItems={() => dispatch(loadGroups({ plantCode: SW.owningPlant?.guid, materialCode: SW.materials[0]?.code, plantSource: SW.owningPlant?.source }))}
                masterDataSection={groups}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.value}
                    </span>
                )}
                isAsyncSearch={false}
                showNoDataMessage={true}
                showNoRoutingDataMsg={true}
            />
        );
    }

    const renderGroupCounter = () => {
        return (
            <MasterDataInput
                label="Group Counter"
                isMandatory={SW.type === SWTypes.MFGCL ? "submitOnly" : SW.type === SWTypes.MFGSWI}
                disabled={isDisabled}
                selectedItems={SW.groupCounter ? [SW.groupCounter] : []}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(setGroupCounter(item))}
                onRemoveItem={() => dispatch(setGroupCounter(undefined))}
                loadItems={() =>
                    dispatch(
                        loadGroupCounter({
                            plantCode: SW.owningPlant?.guid,
                            materialCode: SW.materials[0]?.code,
                            group: SW.group?.code,
                            plantSource: SW.owningPlant?.source,
                        })
                    )
                }
                masterDataSection={groupCounter}
                itemFormatter={(item: IMasterDataItem) => (
                    <span>
                        {item.value}
                    </span>
                )}
                isAsyncSearch={false}
                showNoDataMessage={true}
                showNoRoutingDataMsg={true}
            />
        );
    }

    const renderEvents = () => {
        return (
            <MasterDataInput
                label="Event"
                isMandatory={(SW.type === SWTypes.TLMSWI && SW.contentType === ContentTypes.Executable.toString()) ? "submitOnly" : false}
                disabled={isDisabledEdit()}
                selectedItems={SW.events}
                allowMultiple={false}
                onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                    metaDataName: "events",
                    item,
                }))}
                onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                    metaDataName: "events",
                    item,
                }))}
                loadItems={(searchTerm: string) => dispatch(searchEvents({ searchTerm: searchTerm, onlyActive: true }))}
                masterDataSection={events}
                itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
                isAsyncSearch={true}
            />
        );
    }

    const renderLevels = () => {
        let levelDropdown: [React.JSX.Element] = [<option key={"level-" + undefined} value={undefined} />];

        for (let r = 0; r <= 9; r++) {
            levelDropdown.push(
                <option
                    key={"level-" + r}
                >
                    {r}
                </option>);
        };

        return (
            <>
                <label className={isLevelMandatory() ? "mandatory" : ""}>Level</label>
                <select
                    value={SW.level}
                    onChange={(e) => dispatch(setLevelStringAttribute({ attributeName: "level", value: e.target.value }))}
                    disabled={isDisabled}
                >
                    {levelDropdown}                  
                </select>
            </>
        );
    }

    if (metadataName === "activityWorkflows") {
        component = renderActivityWorkflow();
    } else if (metadataName === "competencyElements") {
        component = renderCompetencyElement();
    } else if (metadataName === "countries") {
        component = renderCountry();
    } else if (metadataName === "geoUnits") {
        component = renderGeoUnit();
    } else if (metadataName === "equipment") {
        component = renderEquipment();
    } else if (metadataName === "epicEquipment") {
        component = renderEpicEquipment();
    } else if (metadataName === "equivalentSWs") {
        component = renderEquivalentSW();
    } else if (metadataName === "languages") {
        component = renderLanguage();
    } else if (metadataName === "operatingEnvironments") {
        component = renderOperatingEnvironment();
    } else if (metadataName === "organizations") {
        component = renderOwningOrganization();
    } else if (metadataName === "subBusinessLines") {
        component = renderSubBusinessLine();
    } else if (metadataName === "customers") {
        component = renderCustomer();
    } else if (metadataName === "safetyCategories") {
        component = renderSafetyCategory();
    } else if (metadataName === "businessRoles") {
        component = renderBusinessRole();
    } else if (metadataName === "productCenters") {
        component = renderProductCenters();
    } else if (metadataName === "serviceTypes") {
        component = renderServiceType();
    } else if (metadataName === "tasks") {
        component = renderTask();
    } else if (metadataName === "performingOrganization") {
        component = renderPerformingOrganization();
    } else if (metadataName === "title") {
        component = renderTitle();
    } else if (metadataName === "description") {
        component = renderDescription();
    } else if (metadataName === "taskAlignment") {
        component = renderTaskAlignment();
    } else if (metadataName === "versionChanges") {
        component = renderVersionChanges();
    } else if (metadataName === "globalOrLocal") {
        component = renderGlobalOrLocal();
    } else if (metadataName === "introduction") {
        component = renderIntroduction();
    } else if (metadataName === "maintenanceTask") {
        component = rendermaintenanceTask();
    } else if (metadataName === "CEMarking") {
        component = renderCEMarking();
    } else if (metadataName === "foreword") {
        component = renderForeword();
    } else if (metadataName === "harc") {
        component = renderHarc();
    } else if (metadataName === "parentChecklist") {
        component = renderParentChecklist();
    } else if (metadataName === "owingPlants") {
        component = renderOwningPlants();
    } else if (metadataName === "performingPlants") {
        component = renderPerformingPlants();
    } else if (metadataName === "workCenters") {
        component = renderWorkCenter();
    } else if (metadataName === "materials") {
        component = renderMaterials();
    } else if (metadataName === "gemsRevisionNumber") {
        component = renderGemsRevisionNumber();
    } else if (metadataName === "showclsignatureblock") {
        component = renderShowCLSignatureBlock();
    } else if (metadataName === "dataClassification") {
        component = renderDataClassificationBlock();
    } else if (metadataName === "tcc") {
        component = renderTCCBlock();
    } else if (metadataName === "templateType") {
        component = renderTemplateType();
    } else if (metadataName === "contentType") {
        component = renderContentTypeBlock();
    } else if (metadataName === "groups") {
        component = renderGroups();
    } else if (metadataName === "groupCounter") {
        component = renderGroupCounter();
    } else if (metadataName === "events") {
        component = renderEvents();
    } else if (metadataName === "levels") {
        component = renderLevels();
    }

    return (
        <div className="input-container">
            {component}
        </div>
    );
}

export default SWMetadata;